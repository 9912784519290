import { useEffect, useState } from "react";
import useApi from "../../hooks/useApi";
import useConst from "../../hooks/useConst";

// Layout
import Header from "../../Layouts/Header/Header";
import { Sidebar } from "../../Layouts/Sidebar/Sidebar";
import { Outlet } from "react-router-dom";
import Footer from "../../Layouts/Footer/Footer";

// MSAL
import { useMsal } from "@azure/msal-react";
import { loginRequestGraphAPI } from "./authConfig";
import { callMsGroups } from "./groups";

// Toast
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Global
import GlobalProvider from "../../contexts/GlobalContext";

export default function AuthenticatedPageLayout() {
  const [getIsAdmin, setIsAdmin] = useState(false);
  const { instance, accounts } = useMsal();

  // CONST
  const { userRole } = useConst();

  // API
  const { apiGetUserRole } = useApi();

  useEffect(() => {
    const request = {
      ...loginRequestGraphAPI,
      account: accounts[0],
    };

    instance.acquireTokenSilent(request).then((response) => {
      callMsGroups(response.accessToken).then((response) => {
        response.value.forEach((group) => {
          apiGetUserRole().then((role) => {
            if (role !== null && role === userRole.Administrateur) {
              setIsAdmin(true);
            }
          });
        });
      });
    });
  }, []);

  return (
    <GlobalProvider>
      <div className="horizontalMenucontainer">
        <div className="page">
          <div className="page-main">
            <Header />
            {getIsAdmin && (
              <div className="sticky" style={{ paddingTop: "-74px" }}>
                <Sidebar />
              </div>
            )}

            <div className="jumps-prevent" style={{ paddingTop: "74px" }}></div>

            {getIsAdmin ? (
              <div className="main-content app-content mt-0">
                <div className="side-app">
                  <div className="main-container container-fluid">
                    <Outlet />
                  </div>
                </div>
              </div>
            ) : (
              <div className="side-app">
                <div className="main-container container-fluid">
                  <Outlet />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
      <ToastContainer />
    </GlobalProvider>
  );
}
