import React, { useState, useEffect } from "react";
import axios from 'axios';
import SiteMatiereVolumeCheckbox from './SiteMatiereVolumeCheckbox';

export default function SiteMatiereComponent({ siteUID, matiere, volumes }) {
    const [getSiteMatiere, setSiteMatiere] = useState();

    useEffect(() => {
        if (siteUID != null) {
            axios.get(`GetSiteMatieres?siteUID=${siteUID}&matiereUID=${matiere.UID}`)
                .then((response) => {
                    //console.log(response.data);
                    setSiteMatiere(response.data);
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }, []);

    return (
        <>
            <tr>
                <td>
                    <label>{matiere.Nom}</label>
                </td>

                <td></td>

                {volumes.map((volume) => (
                    <td key={volume.UID}>
                        <SiteMatiereVolumeCheckbox siteUID={siteUID} siteMatiere={getSiteMatiere} matiere={matiere} volume={volume} />
                    </td>
                ))}
            </tr>
        </>
    );
}