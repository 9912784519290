import Select from "react-select";

export default function BaseSelect(props) {
  return (
    <Select
      placeholder="Sélectionner..."
      loadingMessage={() => "En chargement..."}
      noOptionsMessage={() => "Pas d'option disponible"}
      {...props}
      isReadOnly={true}
    />
  );
}
