import React from "react";
import { ReactComponent as LavalLogoSmall } from "../../assets/images/brand/laval-logo-small.svg";
import { SignInButton } from "./SignInButton";
import { spaVersion } from "../../Layouts/Footer/Footer";

/**
 * Renders the navbar component with a sign-in button if a user is not authenticated
 */
export default function UnauthenticatedPageLayout(props) {
  return (
    <center>
      <br />
      <LavalLogoSmall />
      <br />
      <br />
      <h1>Gestion des demandes de conteneurs</h1>
      <br />
      <SignInButton />
      <br />
      <br />
      <br />
      <br />
      <div style={{ fontSize: "x-small" }}>SPA {spaVersion}</div>
    </center>
  );
}
