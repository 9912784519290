import React, { useState, useEffect } from "react";
import useConst from "../../hooks/useConst";
import useApi from "../../hooks/useApi";
////////
import {
  Form,
  Stack,
  Row,
  Col,
  Card,
  Button,
  Spinner,
  Alert,
  Modal,
} from "react-bootstrap";
import { toast } from "react-toastify";
import axios from "axios";
import dayjs from "dayjs";

const exceptThisSymbols = ["e", "E", "+", "-"];

export default function EditContratTransporteur({
  isEdit,
  item,
  handleSaveItemParent,
}) {
  // useConst
  const { typeFournisseur } = useConst();

  // useApi
  const { apiGetFournisseurs, apiGetVolumesConteneurs } = useApi();

  const [getIsLoading, setIsLoading] = useState(true);
  const [getIsSaving, setIsSaving] = useState(false);
  const [getErrorMsg, setErrorMsg] = useState();

  const [getForceState, setForceState] = useState();
  const [showState, setShowState] = useState(false);
  const [itemState, setItemState] = useState(item);
  const [getMatieres, setMatieresState] = useState([]);
  const [fournisseursState, setFournisseursState] = useState([]);
  const [volumesConteneursState, setVolumesConteneursState] = useState([]);
  const initialItemState = { ...item };

  useEffect(() => {
    loadFournisseurs();

    axios
      .get("GetMatieres", {
        params: {
          showInactive: false,
        },
      }) //Returning pledges using a get-query
      .then((response) => {
        // Data retrieval and processing
        setMatieresState(response.data);
      })
      .catch((error) => {
        // If the query fails, an error will be displayed on the terminal.
        console.error(error);
      });

    apiGetVolumesConteneurs(false)
      .then((res) => {
        setVolumesConteneursState(res);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  async function loadFournisseurs() {
    try {
      const fournisseurs = await apiGetFournisseurs(
        typeFournisseur.Transporteur,
        false
      );

      setFournisseursState(fournisseurs);
      item.Fournisseur_UID = item.Fournisseur_UID;
    } catch (err) {
      console.error(err);
    }
  }

  const handleSaveItem = async () => {
    setErrorMsg();

    const errors = [];

    // Front-end validations
    if (!item.NumContrat) {
      errors.push("Veuillez entrer le numéro du contrat.");
    }

    if (item.Fournisseur_UID === 0) {
      errors.push("Veuillez sélectionner le nom du fournisseur.");
    }

    if (!item.Nom) {
      errors.push("Veuillez entrer le nom du contact.");
    }

    if (!item.Adresse) {
      errors.push("Veuillez entrer l'adresse.");
    }

    if (!item.Courriel) {
      errors.push("Veuillez entrer le courriel.");
    }

    if (!item.DateDebut) {
      errors.push("Veuillez sélectionner la date de début.");
    }

    if (!item.DateFin) {
      errors.push("Veuillez sélectionner la date de fin.");
    }

    if (item.DateFin <= item.DateDebut) {
      errors.push(
        "La date de fin ne peut pas être inférieure ou égale à la date de début."
      );
    }

    if (!item.MinuteMaxConfirmation) {
      errors.push("Veuillez entrer le temps maximum de confirmation.");
    }

    if (!item.MinuteMaxExecution) {
      errors.push("Veuillez entrer le temps maximum d'exécution.");
    }

    if (item.Matiere_UID === 0) {
      errors.push("Veuillez sélectionner la matière.");
    }

    if (item.Actif === -1) {
      errors.push(
        "Veuillez sélectionner si le contrat est actif ou non-actif."
      );
    }

    if (errors.length > 0) {
      setErrorMsg(errors);
      return;
    }

    if (isEdit) {
      //Edition
      try {
        await axios.put("EditContratTransporteur", item);
        //  const itemInArray = dataState.find((q) => q.UID === item.UID);
        //  dataState[dataState.indexOf(itemInArray)] = item;
        //  setDataState([...dataState]);
        toast.success("Sauvegardé!");
        handleSaveItemParent(item, isEdit);
        handleCloseModal();
      } catch (err) {
        setErrorMsg([err.response.data]);
      } finally {
        setIsSaving(false);
      }
    } else {
      //Add
      try {
        console.log(item);
        await axios.post("AddContratTransporteur", item);
        //setDataState(dataState.concat(item));
        toast.success("Sauvegardé!");
        handleSaveItemParent(item, isEdit);
        handleCloseModal();
      } catch (err) {
        setErrorMsg([err.response.data]);
      } finally {
        setIsSaving(false);
      }
    }
  };

  const handleCloseModal = () => {
    setShowState(false);
    setErrorMsg();
    resetValues(initialItemState);
  };

  const resetValues = (initialItemState) => {
    item.NumContrat = initialItemState.NumContrat;
    item.Fournisseur_UID = initialItemState.Fournisseur_UID;
    item.Nom = initialItemState.Nom;
    item.Adresse = initialItemState.Adresse;
    item.Courriel = initialItemState.Courriel;
    item.Tel = initialItemState.Tel;
    item.Cell = initialItemState.Cell;
    item.Note = initialItemState.Note;
    item.Matiere_UID = initialItemState.Matiere_UID;
    item.MinuteMaxConfirmation = initialItemState.MinuteMaxConfirmation;
    item.MinuteMaxExecution = initialItemState.MinuteMaxExecution;
    item.DemandeParUrl = initialItemState.DemandeParUrl;
    item.Url = initialItemState.Url;
    item.Actif = initialItemState.Actif;
    item.DateDebut = initialItemState.DateDebut;
    item.DateFin = initialItemState.DateFin;
  };

  function handleClickDemandeParUrl(e) {
    item.DemandeParUrl = e.target.checked ? 1 : 0;
  }

  const handleOnChangeFournisseur = (e) => {
    if (e.target.value !== -1) {
      item.Fournisseur_UID = e.target.value;

      axios
        .get("GetFournisseurByUID", {
          params: { UID: item.Fournisseur_UID },
        })
        .then((response) => {
          if (!isEdit) {
            item.Nom = response.data.Nom;
            item.Adresse = response.data.Adresse;
            item.Courriel = response.data.Courriel;
            item.Tel = response.data.Tel;
            item.Cell = response.data.Cell;
          }

          setForceState(response.data.UID);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  return (
    <>
      <div align="right">
        {isEdit ? (
          <Button
            variant="primary"
            size="sm"
            onClick={() => {
              setShowState(true);
            }}
          >
            Modifier
          </Button>
        ) : (
          <Button
            variant="primary"
            onClick={() => {
              resetValues({});
              item.Matiere_UID = 0;
              item.Fournisseur_UID = 0;
              item.Actif = -1;
              setShowState(true);
            }}
          >
            Ajouter
          </Button>
        )}
      </div>

      <Modal
        show={showState}
        onHide={handleCloseModal}
        size="xl"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Contrat de transporteur</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="noContrat">
                <Form.Label>Numéro du contrat *</Form.Label>
                <Form.Control
                  defaultValue={item?.NumContrat}
                  onChange={(e) => (item.NumContrat = e.target.value)}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="fournisseur">
                <Form.Label>Nom du fournisseur *</Form.Label>
                <Form.Select
                  autoFocus
                  defaultValue={item?.Fournisseur_UID}
                  onChange={handleOnChangeFournisseur}
                  readOnly={isEdit}
                  disabled={isEdit}
                  as="select"
                >
                  <option value="0" disabled>
                    Veuillez faire une sélection
                  </option>
                  {fournisseursState.map((item) => (
                    <option
                      key={item.UID}
                      value={item.UID}
                    >{`${item.Nom} (${item.Code})`}</option>
                  ))}
                </Form.Select>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Nom du contact *</Form.Label>
                <Form.Control
                  defaultValue={item?.Nom}
                  onChange={(e) => (item.Nom = e.target.value)}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Adresse *</Form.Label>
                <Form.Control
                  defaultValue={item?.Adresse}
                  onChange={(e) => (item.Adresse = e.target.value)}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Courriel *</Form.Label>
                <Form.Control
                  defaultValue={item?.Courriel}
                  onChange={(e) => (item.Courriel = e.target.value)}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Téléphone Travail</Form.Label>
                <Form.Control
                  defaultValue={item?.Tel}
                  onChange={(e) => (item.Tel = e.target.value)}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Cell</Form.Label>
                <Form.Control
                  defaultValue={item?.Cell}
                  onChange={(e) => (item.Cell = e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3" controlId="dateDebut">
                <Form.Label>Date Début *</Form.Label>
                <Form.Control
                  type="date"
                  defaultValue={
                    item?.DateDebut
                      ? dayjs(item?.DateDebut).format("YYYY-MM-DD")
                      : item?.DateDebut
                  }
                  min={"2000-01-01"}
                  max="3000-01-01"
                  onKeyDown={(evt) => evt.preventDefault()}
                  onChange={(e) => (item.DateDebut = e.target.value)}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="dateFin">
                <Form.Label>Date Fin *</Form.Label>
                <Form.Control
                  type="date"
                  defaultValue={
                    item?.DateFin
                      ? dayjs(item?.DateFin).format("YYYY-MM-DD")
                      : item?.DateFin
                  }
                  min="2000-01-01"
                  max="3000-01-01"
                  // onKeyDown={(evt) => evt.preventDefault()}
                  onChange={(e) => (item.DateFin = e.target.value)}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>
                  Temps maximum de confirmation (heures) *
                </Form.Label>
                <Form.Control
                  type="number"
                  step="0.25"
                  defaultValue={
                    item?.MinuteMaxConfirmation
                      ? item.MinuteMaxConfirmation / 60
                      : 0
                  }
                  onChange={(e) =>
                    (item.MinuteMaxConfirmation = e.target.value * 60)
                  }
                  onKeyDown={(e) =>
                    exceptThisSymbols.includes(e.key) && e.preventDefault()
                  }
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Temps maximum d'exécution (heures) *</Form.Label>
                <Form.Control
                  type="number"
                  step="0.25"
                  defaultValue={
                    item?.MinuteMaxExecution ? item.MinuteMaxExecution / 60 : 0
                  }
                  onChange={(e) =>
                    (item.MinuteMaxExecution = e.target.value * 60)
                  }
                  onKeyDown={(e) =>
                    exceptThisSymbols.includes(e.key) && e.preventDefault()
                  }
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="matieres">
                <Form.Label>Matière *</Form.Label>
                <Form.Select
                  autoFocus
                  defaultValue={item?.Matiere_UID}
                  onChange={(e) => (item.Matiere_UID = e.target.value)}
                  // readOnly={isEdit}
                  // disabled={isEdit}
                  as="select"
                >
                  <option value="0" disabled>
                    Faites une sélection
                  </option>
                  {getMatieres.map((item) => (
                    <option
                      key={item.UID}
                      value={item.UID}
                    >{`${item.Nom}`}</option>
                  ))}
                </Form.Select>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Lien web du fournisseur</Form.Label>
                <Form.Control
                  defaultValue={item?.Url}
                  onChange={(e) => (item.Url = e.target.value)}
                />
                <Form.Check
                  type="checkbox"
                  label="Demande par lien"
                  defaultChecked={item?.DemandeParUrl}
                  onChange={handleClickDemandeParUrl}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Note</Form.Label>
                <Form.Control
                  defaultValue={item?.Note}
                  onChange={(e) => (item.Note = e.target.value)}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="actif">
                <Form.Label>Actif *</Form.Label>
                <Form.Select
                  defaultValue={item?.Actif}
                  onChange={(e) => (item.Actif = Number(e.target.value))}
                  as="select"
                >
                  <option value={-1} disabled>
                    Veuillez sélectionner une option
                  </option>
                  <option value={0}>Non-actif</option>
                  <option value={1}>Actif</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>

          {getErrorMsg && (
            <Alert className="mt-4" variant="danger">
              {getErrorMsg.map((line) => (
                <p>{line}</p>
              ))}
            </Alert>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Retour
          </Button>
          {getIsSaving ? (
            <Spinner />
          ) : (
            <Button variant="primary" onClick={handleSaveItem}>
              Sauvegarder
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}
