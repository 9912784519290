import React, { useState, useEffect } from "react";
import styles from "./Matieres.module.scss";
import useApi from "../../hooks/useApi";
import DataTable from "../base-components/DataTableBase";
import EditMatiere from "./EditMatiere";
import { toast } from "react-toastify";

/********************
 * DEFAULT FUNCTION *
 ********************/
export default function Matieres() {
  // useApi
  const { apiGetMatieres } = useApi();

  // useState
  const [getIsDataLoading, setIsDataLoading] = useState(false);
  const [dataState, setDataState] = useState([]);

  // useEffect
  useEffect(() => {
    loadMatieres();
  }, []);

  async function loadMatieres() {
    try {
      setIsDataLoading(true);

      const matieres = await apiGetMatieres(true);

      setDataState(matieres);
    } catch (err) {
      console.error(err);
      toast.error(
        "Un problème est survenu lors du chargement de la liste des matières."
      );
    } finally {
      setIsDataLoading(false);
    }
  }

  async function handleRefreshData() {
    loadMatieres();
  }

  const columns = [
    {
      name: "ID",
      selector: (row) => row.ID,
      sortable: true,
    },
    {
      name: "Nom",
      selector: (row) => row.Nom,
      sortable: true,
    },
    {
      name: "Actif",
      selector: (row) => (row.Actif === 1 ? "Actif" : "Non-actif"),
    },
    {
      name: "Système",
      selector: (row) => (row.IsSystem === 1 ? "Oui" : "Non"),
    },
    {
      button: true,
      cell: (record) => (
        <EditMatiere
          isEdit={true}
          matiere={Object.assign({}, record)}
          handleRefreshData={handleRefreshData}
        />
      ),
    },
  ];

  return (
    <div className={styles.Matieres}>
      <h1 align="center">Matières</h1>

      <div align="right" className="mb-4">
        <EditMatiere
          isEdit={false}
          matiere={{ Actif: -1 }}
          handleRefreshData={handleRefreshData}
        />
      </div>

      <div>
        <DataTable
          title=""
          columns={columns}
          data={dataState}
          progressPending={getIsDataLoading}
        />
      </div>
    </div>
  );
}
