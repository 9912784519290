import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useConst from "../../hooks/useConst";
import useApi from "../../hooks/useApi";
import dayjs from "dayjs";
import { toast } from "react-toastify";

/********************
 * Default Function *
 ********************/
export default function useNouvelleDemande() {
  // Location
  const location = useLocation();

  // useNavigate
  const navigate = useNavigate();

  // useConst
  const { typeDemande, typesSites, getTypesSitesAll, timeToGMT, openInNewTab } =
    useConst();

  // useApi
  const {
    apiGetUserRole,
    apiGetSitesBySiteType,
    apiGetPlacesBySite,
    apiGetMatieresBySite,
    apiGetVolumesBySiteMatiere,
    apiGetContratTransportForDemande,
    apiValidateDemandeLivraison,
    apiAddDemandeLivraison,
    apiAddDemandeCollecte,
    apiGetProchaineHeureOuvertureSite,
    apiGetTest2,
  } = useApi();

  // useState
  const [getErrorMsg, setErrorMsg] = useState();
  const [getSuccessMsg, setSuccessMsg] = useState();
  const [getInfoMsg, setInfoMsg] = useState();
  const [getShowModalNouveauLieuSpecial, setShowModalNouveauLieuSpecial] =
    useState(false);

  const [getUserRole, setUserRole] = useState();
  const [getTitle, setTitle] = useState();
  const [getTypeDemande, setTypeDemande] = useState();
  const [getIsLoadingSitesTypes, setIsLoadingSitesTypes] = useState(true);
  const [getIsLoadingSites, setIsLoadingSites] = useState(false);
  const [getIsLoadingPlaces, setIsLoadingPlaces] = useState(false);
  const [getIsLoadingMatieres, setIsLoadingMatieres] = useState(false);
  const [getIsLoadingVolumes, setIsLoadingVolumes] = useState(false);
  const [getIsSaving, setIsSaving] = useState(false);

  const [getSites, setSites] = useState();
  const [getPlaces, setPlaces] = useState();
  const [getMatieres, setMatieres] = useState();
  const [getVolumes, setVolumes] = useState();

  const [getSelectedSite, setSelectedSite] = useState();
  const [getSelectedPlace, setSelectedPlace] = useState();
  const [getSelectedMatiere, setSelectedMatiere] = useState();
  const [getSelectedVolume, setSelectedVolume] = useState();

  const [getIsMaintenant, setIsMaintenant] = useState(true);
  const [getDateHeurePlanifiee, setDateHeurePlanifiee] = useState(dayjs());
  const [getInfoComp, setInfoComp] = useState();
  const [getFiles, setFiles] = useState([]);

  const [getSiteAdresse, setSiteAdresse] = useState();
  const [getSiteNote, setSiteNote] = useState("");
  const [getPlaceNote, setPlaceNote] = useState();
  const [getContratTransport, setContratTransport] = useState(); // Pour le champs sur le contrat
  const [getContratTransportUID, setContratTransportUID] = useState(); // Pour le champs sur le contrat
  const [getContratTransportLienWeb, setContratTransportLienWeb] = useState();

  // useEffect
  useEffect(() => {
    loadUserRole();
    loadDefaultSite();
  }, []);

  useEffect(() => {
    loadContratTransport();
  }, [getSelectedMatiere, getDateHeurePlanifiee]);

  useEffect(() => {
    loadTitle();
  }, [getTypeDemande]);

  /*************
   * FUNCTIONS *
   *************/
  async function loadUserRole() {
    try {
      setIsLoadingSitesTypes(true);
      const role = await apiGetUserRole();
      setUserRole(role);
    } catch (err) {
      console.error(err.response.data);
      toast.error("Le système ne peut identifier votre rôle.");
    } finally {
      setIsLoadingSitesTypes(false);
    }
  }

  async function loadDefaultSite() {
    if (location.state.params.site?.value?.TypeID) {
      const sites = await loadSites(
        getTypesSitesAll[location.state.params.site?.value?.TypeID - 1]
      );

      if (sites) {
        const site = sites.find(
          (q) => q.value?.UID == location.state.params.site?.value?.UID
        );
        if (site) {
          setSelectedSite(site);
          handleSiteChange(site);
        }
      }
    }
  }

  async function loadContratTransport() {
    setContratTransportUID(null);

    if (!getSelectedMatiere) {
      setContratTransport("Veuillez sélectionner une matière.");
      return;
    }

    if (!getDateHeurePlanifiee) {
      setContratTransport(
        "Veuillez sélectionner la date et l'heure de livraison."
      );
      return;
    }

    try {
      const contratTransport = await apiGetContratTransportForDemande(
        getSelectedMatiere,
        getDateHeurePlanifiee
      );

      if (contratTransport != null) {
        setContratTransport(
          `Contrat : ${contratTransport.NumContrat}\n Transporteur : ${contratTransport.Fournisseur?.Nom}\n Contact : ${contratTransport.Nom}`
        );
        setContratTransportUID(contratTransport.UID);

        // Checker le type de demande
        if (contratTransport.DemandeParUrl === 1) {
          if (contratTransport.Url !== "") {
            setContratTransportLienWeb(contratTransport.Url);
            setTypeDemande(typeDemande.CollecteParLienWeb);
            setInfoMsg(
              "Ceci est une demande de collecte avec un formulaire en-ligne. Veuillez remplir le formulaire web du fournisseur à la prochaine étape."
            );
          } else {
            setTypeDemande(typeDemande.CollecteParLienCourriel);
            setInfoMsg(
              "Ceci est une demande de collecte par courriel. Un courriel sera acheminé au fournisseur pour la collecte."
            );
          }
        } else {
          setTypeDemande(typeDemande.Livraison);
        }
      } else {
        setTypeDemande(typeDemande.Livraison);
        setContratTransport(
          "Il n'y a pas de contrat de transport pour cette sélection."
        );
      }
    } catch (err) {
      console.error(err);
      setErrorMsg(
        "Un problème est survenu lors du chargement du contrat de transport."
      );
    }
  }

  function loadTitle() {
    switch (getTypeDemande) {
      case typeDemande.Echange:
        setTitle("Nouvelle demande d'échange");
        break;
      case typeDemande.Deplacement:
        setTitle("Nouvelle demande de déplacement");
        break;
      case typeDemande.Levee:
        setTitle("Nouvelle demande de levée finale");
        break;
      case typeDemande.CollecteParLienWeb:
        setTitle("Collecte par lien web");
        break;
      case typeDemande.CollecteParLienCourriel:
        setTitle("Collecte par courriel");
        break;
      default:
        setTitle("Nouvelle demande de livraison");
    }
  }

  function handleOnChangeSiteType(newValue) {
    setSelectedSite(null);
    updateSiteInfo(null);
    setSelectedPlace(null);
    setSelectedMatiere(null);
    setSelectedVolume(null);
    setContratTransport("");

    loadSites(newValue);
    setPlaces();
    setMatieres();
    setVolumes();
  }

  function updateSiteInfo(site) {
    if (site != null) {
      setSiteAdresse(site.Adresse ?? "");
      setSiteNote(site.Note ?? "");
    } else {
      setSiteAdresse("");
      setSiteNote("");
      updatePlaceNote(null);
    }
  }

  async function loadSites(typeSite) {
    try {
      setIsLoadingSites(true);

      const sites = await apiGetSitesBySiteType(typeSite.value);

      let listSites = [];

      if (sites !== null) {
        sites.forEach((i) => {
          listSites.push({ label: i.Nom, value: i });
        });
      }

      // Note : Ne pas utiliser triple === dans la comparaison car ce n'est pas le même objet
      if (typeSite.value == Number(typesSites.LieuSpecial)) {
        listSites.push({
          label: "Demander l'ajout d'un nouveau lieu de service spécial",
          value: -1,
        });
      }

      setSites(listSites);

      return listSites;
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoadingSites(false);
    }
  }

  async function handleSiteChange(newSite) {
    setSelectedPlace(null);
    setSelectedMatiere(null);
    setSelectedVolume(null);
    setPlaces();
    setMatieres();
    setVolumes();
    updatePlaceNote(null);
    setContratTransport("");

    setSelectedSite(newSite);
    updateSiteInfo(newSite.value);

    if (newSite.value === -1) {
      setShowModalNouveauLieuSpecial(true);
    } else {
      loadPlaces(newSite.value);
      loadMatieres(newSite.value);
    }
  }

  async function loadPlaces(site) {
    try {
      setIsLoadingSites(true);

      const places = await apiGetPlacesBySite(site.UID);

      places.forEach((i) => {
        i.label = i.Code;
        i.value = i;
      });

      setPlaces(places);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoadingSites(false);
    }
  }

  async function loadMatieres(site) {
    try {
      setIsLoadingMatieres(true);

      const matieres = await apiGetMatieresBySite(site);

      matieres.forEach((i) => {
        i.label = i.Nom;
        i.value = i;
      });

      setMatieres(matieres);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoadingMatieres(false);
    }
  }

  const handlePlaceChange = (newPlace) => {
    setSelectedPlace(newPlace);
    updatePlaceNote(newPlace);
  };

  function updatePlaceNote(place) {
    if (place != null) {
      setPlaceNote(place.Note ?? "");
    } else {
      setPlaceNote("");
    }
  }

  const handleMatiereChange = async (newMatiere) => {
    setErrorMsg("");
    setSuccessMsg("");
    setInfoMsg();

    setSelectedVolume(null);
    setSelectedMatiere(newMatiere);

    await loadVolumes(newMatiere);
  };

  async function loadVolumes(matiere) {
    try {
      setIsLoadingVolumes(true);

      const volumes = await apiGetVolumesBySiteMatiere(
        getSelectedSite.value.UID,
        matiere.UID
      );

      volumes.forEach((eachVolume) => {
        eachVolume.label = eachVolume.Nom;
        eachVolume.value = eachVolume.UID;
      });

      setVolumes(volumes);
    } catch (err) {
      console.error(err);
      setErrorMsg(
        "Un problème est survenu lors du chargement de la liste des volumes de conteneurs."
      );
    } finally {
      setIsLoadingVolumes(false);
    }
  }

  const handleVolumeChange = async (newVolume) => {
    setSelectedVolume(newVolume);
  };

  const handleInfoCompChange = (e) => {
    setInfoComp(e.target.value);
  };

  async function handleMaintenantClick(e) {
    if (e.target.checked === true) {
      setIsMaintenant(true);
      setDateHeurePlanifiee(dayjs());
    } else {
      setIsMaintenant(false);
      setDateHeurePlanifiee();
    }
  }

  function handleDateHeurePlanifieeChange(e) {
    setDateHeurePlanifiee(e);
  }

  function handleRetourClick() {
    navigate(-1);
  }

  async function handleSaveClick(e) {
    setErrorMsg("");
    setSuccessMsg("");

    const newDemande = {};

    try {
      newDemande.TypeDemande = getTypeDemande;
      newDemande.DemandeParenteUID = null;
      newDemande.SiteDestination_UID = getSelectedSite.value.UID;
      newDemande.PlaceDestination_UID = getSelectedPlace.UID;
      newDemande.Matiere_UID = getSelectedMatiere.UID;
      newDemande.ConteneurVolume_UID = getSelectedVolume.UID;
      newDemande.IsMaintenant = getIsMaintenant;
      newDemande.HeurePlannifiee =
        getDateHeurePlanifiee !== undefined
          ? timeToGMT(getDateHeurePlanifiee)
          : null;
      newDemande.InfoComplementaire = getInfoComp;
      newDemande.NotePlace = getPlaceNote;
      newDemande.ContactPlace = getSiteNote;
      newDemande.ContratTransportUID = getContratTransportUID;
    } catch (err) {
      console.error(err);
      setErrorMsg(
        "Un problème est survenu lors de l'envoi de la demande. Veuillez vérifier que vous avez bien rempli la demande."
      );
    }

    // Selon le type de la demande
    try {
      setIsSaving(true);
      await apiValidateDemandeLivraison(newDemande);

      let res;
      if (getTypeDemande === typeDemande.CollecteParLienWeb) {
        // Demande de collecte par lien
        newDemande.TypeDemande = typeDemande.CollecteParLienWeb;
        res = await apiAddDemandeCollecte(newDemande, getFiles);
        openInNewTab(getContratTransportLienWeb);
      } else if (getTypeDemande === typeDemande.CollecteParLienCourriel) {
        // Demande de collecte par lien courriel
        newDemande.TypeDemande = typeDemande.CollecteParLienCourriel;
        res = await apiAddDemandeCollecte(newDemande, getFiles);
      } else {
        // Demande de livraison de conteneur
        res = await apiAddDemandeLivraison(newDemande, getFiles);
      }

      toast.success(`La demande #${res.data.NoDemande} a été créée.`);
      navigate("/");
    } catch (err) {
      console.error(err);
      setErrorMsg(err.response.data);
    } finally {
      setIsSaving(false);
    }
  }

  async function handleSetCorrectDateTime() {
    const oldDateHeure = timeToGMT(getDateHeurePlanifiee);

    await handleMaintenantClick({ target: { checked: false } });

    const params = {
      params: {
        p: "getProchaineHeureOuvertureSite",
        siteUID: getSelectedSite?.value?.UID,
        dateHeurePlanifiee: oldDateHeure,
      },
    };

    const prochaineHeureOuvertureSite = await apiGetProchaineHeureOuvertureSite(
      params
    );

    setDateHeurePlanifiee(dayjs(prochaineHeureOuvertureSite.ProchaineHeure));

    setErrorMsg();
  }

  return {
    getErrorMsg,
    setErrorMsg,
    getSuccessMsg,
    setSuccessMsg,
    getInfoMsg,
    setInfoMsg,
    getUserRole,
    getTitle,
    getIsLoadingSitesTypes,
    handleOnChangeSiteType,
    getIsLoadingSites,
    setIsLoadingSites,
    getSites,
    setSites,
    handleSiteChange,
    getIsLoadingPlaces,
    setIsLoadingPlaces,
    getPlaces,
    setPlaces,
    getSelectedPlace,
    getPlaceNote,
    setPlaceNote,
    getSelectedSite,
    getSiteAdresse,
    setSiteAdresse,
    getSiteNote,
    setSiteNote,
    getIsLoadingMatieres,
    setIsLoadingMatieres,
    getMatieres,
    setMatieres,
    getSelectedMatiere,
    handlePlaceChange,
    handleMatiereChange,
    handleVolumeChange,
    getIsLoadingVolumes,
    setIsLoadingVolumes,
    getVolumes,
    setVolumes,
    getSelectedVolume,
    setSelectedVolume,
    getContratTransport,
    setContratTransport,
    getInfoComp,
    setInfoComp,
    handleInfoCompChange,
    getIsMaintenant,
    setIsMaintenant,
    handleMaintenantClick,
    getDateHeurePlanifiee,
    setDateHeurePlanifiee,
    handleDateHeurePlanifieeChange,
    getFiles,
    setFiles,
    handleRetourClick,
    handleSaveClick,
    getIsSaving,
    setIsSaving,
    getShowModalNouveauLieuSpecial,
    setShowModalNouveauLieuSpecial,
    handleSetCorrectDateTime,
  };
}
