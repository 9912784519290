import React, { useState, useEffect } from "react";
import useConst from "../../hooks/useConst";
import useApi from "../../hooks/useApi";

/////////////
import { Link } from "react-router-dom";
import styles from "./Fournisseurs.module.scss";
import axios from "axios";
import DataTable from "../base-components/DataTableBase";
import EditFournisseur from "./EditFournisseur";

export default function Fournisseurs() {
  // useConst
  const { typeFournisseur } = useConst();

  // useApi
  const { apiGetFournisseurs } = useApi();

  const [dataState, setDataState] = useState();
  const [getIsDataLoading, setIsDataLoading] = useState(true);

  useEffect(() => {
    loadFournisseurs();
  }, []);

  async function loadFournisseurs() {
    try {
      setIsDataLoading(true);
      const fournisseurs = await apiGetFournisseurs(typeFournisseur.Tous, true);
      setDataState(fournisseurs);
    } catch (err) {
      console.error(err);
    } finally {
      setIsDataLoading(false);
    }
  }

  const handleSaveItem = async (item, isEdit) => {
    if (isEdit) {
      const res = await axios.put("EditFournisseur", item);
      const itemInArray = dataState.find((q) => q.UID === res.data.UID);
      dataState[dataState.indexOf(itemInArray)] = res.data;
      setDataState([...dataState]);
      return true;
    } else {
      const res = await axios.post("AddFournisseur", item);
      setDataState(dataState.concat(res.data));
      return true;
    }
  };

  const columns = [
    {
      name: "Code",
      wrap: true,
      wrap: true,
      selector: (row) => row.Code,
      sortable: true,
    },
    {
      name: "Type",
      wrap: true,
      selector: (row) => {
        switch (Number(row.Type)) {
          case 1:
            return "Transporteur";
          case 2:
            return "Centre de traitement";
          default:
            return "s/o";
        }
      },
    },
    {
      name: "Nom",
      wrap: true,
      selector: (row) => row.Nom,
      sortable: true,
    },
    {
      name: "Adresse",
      wrap: true,
      selector: (row) => row.Adresse,
      sortable: true,
    },
    {
      name: "Courriel",
      wrap: true,
      selector: (row) => row.Courriel,
      sortable: true,
    },
    {
      name: "Tel",
      wrap: true,
      selector: (row) => row.Tel,
      sortable: true,
    },
    {
      name: "Cell",
      wrap: true,
      selector: (row) => row.Cell,
      sortable: true,
    },
    {
      name: "Note",
      wrap: true,
      selector: (row) => row.Note,
      sortable: true,
    },
    {
      name: "Actif",
      wrap: true,
      selector: (row) => (Number(row.Actif) === 1 ? "Actif" : "Non-actif"),
    },
    {
      button: true,
      cell: (record) => (
        <Link to={"/admin/fournisseurs-utilisateurs"} state={record}>
          Utilisateurs
        </Link>
      ),
    },
    {
      button: true,
      cell: (record) => (
        <EditFournisseur
          isEdit={true}
          item={Object.assign({}, record)}
          handleSaveItemParent={handleSaveItem}
        />
      ),
    },
  ];

  return (
    <div className={styles.Fournisseurs}>
      <h1 align="center">Fournisseurs</h1>

      <div align="right">
        <EditFournisseur
          isEdit={false}
          item={{}}
          handleSaveItemParent={handleSaveItem}
        />
      </div>

      <br />

      <div>
        <DataTable
          title=""
          columns={columns}
          data={dataState}
          progressPending={getIsDataLoading}
        />
      </div>
    </div>
  );
}
