import React, { Fragment } from "react";
import AuthenticatedPageLayout from "./components/msal/AuthenticatedPageLayout";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

export default function App() {
  return (
    <Fragment>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <AuthenticatedPageLayout />
      </LocalizationProvider>
    </Fragment>
  );
}
