import React from "react";
import { useMsal } from "@azure/msal-react";
import { Button } from "react-bootstrap";

/**
 * Renders a button which, when selected, will redirect the page to the logout prompt
 */
export default function SignOutButton() {
    const { instance } = useMsal();
    
    const handleLogout = (logoutType) => {
        if (logoutType === "redirect") {
           instance.logoutRedirect({
                postLogoutRedirectUri: "/",
            });
        }
    }

    return (
        <Button variant="primary" className="ml-auto" size="sm" onClick={() => handleLogout("redirect")}>Se déconnecter</Button>
    );
}