import React, { useState, useEffect } from "react";
//import axios from 'axios';
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";

//mui plugins
import dayjs from "dayjs";
import "dayjs/locale/fr-ca";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { TimeField } from "@mui/x-date-pickers/TimeField";

export default function EditHoraire({ isEdit, item, handleSaveItemParent }) {
  const [showState, setShowState] = useState(false);
  const [itemState, setItemState] = useState(item);
  const [heureDebut, setHeureDebut] = useState(
    dayjs(item.HeureDebut).locale("fr-ca")
  );
  const [heureFin, setHeureFin] = useState(
    dayjs(item.HeureFin).locale("fr-ca")
  );

  // const [matieresState, setMatieresState] = useState([]);
  // itemState.SelectedMatieres = [];

  useEffect(() => {
    //console.log(dayjs(item.HeureFin).locale('fr-ca'))
    // axios.get("GetMatieres") //Returning pledges using a get-query
    //   .then((response) => { // Data retrieval and processing
    //     setMatieresState(response.data);
    //   })
    //   .catch((error) => { // If the query fails, an error will be displayed on the terminal.
    //     console.error(error);
    //   });
  }, []);

  const handleSaveItem = async () => {
    itemState.dayJsHeureDebut = heureDebut;
    itemState.dayJsHeureFin = heureFin;
    itemState.HeureDebut = heureDebut.toDate();
    itemState.HeureFin = heureFin.toDate();

    if (await handleSaveItemParent(itemState)) {
      setShowState(false);
      if (!isEdit) {
        setItemState({});
      }
    }
  };

  const handleCloseModal = () => {
    setShowState(false);
    console.log(heureFin);
    if (isEdit) {
      setItemState(item);
    } else {
      setItemState({});
    }
  };

  function getDay(day) {
    switch (itemState.Jour) {
      case 1:
        return "Lundi";
      case 2:
        return "Mardi";
      case 3:
        return "Mercredi";
      case 4:
        return "Jeudi";
      case 5:
        return "Vendredi";
      case 6:
        return "Samedi";
      case 7:
        return "Dimanche";
      default:
        return "s/o";
    }
  }

  return (
    <>
      <div align="right">
        {isEdit ? (
          <Button
            variant="primary"
            size="sm"
            onClick={() => {
              setShowState(true);
            }}
          >
            Modifier
          </Button>
        ) : (
          <Button
            variant="primary"
            onClick={() => {
              setShowState(true);
            }}
          >
            Ajouter
          </Button>
        )}
      </div>

      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr-ca">
        <Modal show={showState} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>{getDay(itemState.Jour)}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleSaveItem}>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Ouverture</Form.Label>
                <TimeField
                  defaultValue={heureDebut}
                  onChange={(newValue) => setHeureDebut(newValue)}
                />
              </Form.Group>

              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Fermeture</Form.Label>
                <TimeField
                  defaultValue={heureFin}
                  onChange={(newValue) => setHeureFin(newValue)}
                />
              </Form.Group>
            </Form>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Retour
            </Button>
            <Button variant="primary" onClick={handleSaveItem}>
              Sauvegarder
            </Button>
          </Modal.Footer>
        </Modal>
      </LocalizationProvider>
    </>
  );
}
