import React, { useState, useEffect } from "react";
import useConst from "../../hooks/useConst";
import useApi from "../../hooks/useApi";
////////
import {
  Form,
  Stack,
  Row,
  Col,
  Card,
  Button,
  Spinner,
  Alert,
  Modal,
} from "react-bootstrap";
import { toast } from "react-toastify";
import axios from "axios";
import dayjs from "dayjs";
const exceptThisSymbols = ["e", "E", "+", "-"];
export default function EditContratCentreTraitement({
  isEdit,
  item,
  handleSaveItemParent,
}) {
  // useConst
  const { typeFournisseur } = useConst();

  // useApi
  const { apiGetFournisseurs } = useApi();

  const [getIsLoading, setIsLoading] = useState(false);
  const [getIsSaving, setIsSaving] = useState(true);
  const [getErrorMsg, setErrorMsg] = useState();
  const [formError, setFormError] = useState({});
  const [showState, setShowState] = useState(false);
  const [getItem, setItem] = useState(item);
  const [getFournisseurs, setFournisseurs] = useState([]);
  const [getFournisseur, setFournisseur] = useState();
  const [getMatieres, setMatieres] = useState([]);
  const [getUnites, setUnites] = useState([]);
  const [initialItemState, setinitialItemState] = useState();
  // const [volumesConteneursState, setVolumesConteneursState] = useState([]);

  useEffect(() => {
    setinitialItemState(item);
    loadFournisseurs();

    axios
      .get("GetMatieres", {
        params: {
          showInactive: false,
        },
      }) //Returning pledges using a get-query
      .then((response) => {
        // Data retrieval and processing
        setMatieres(response.data);
      })
      .catch((error) => {
        // If the query fails, an error will be displayed on the terminal.
        console.error(error);
      });

    axios
      .get("GetUnites") //Returning pledges using a get-query
      .then((response) => {
        // Data retrieval and processing
        setUnites(response.data);
      })
      .catch((error) => {
        // If the query fails, an error will be displayed on the terminal.
        console.error(error);
      });
  }, []);

  async function loadFournisseurs() {
    try {
      const fournisseurs = await apiGetFournisseurs(
        typeFournisseur.CentreTraitement,
        false
      );

      setFournisseurs(fournisseurs);
      item.Fournisseur_UID = item.Fournisseur_UID;
    } catch (err) {
      console.error(err);
    }
  }

  const handleFournisseurChange = (e) => {
    if (e.target.value !== -1) {
      item.Fournisseur_UID = e.target.value;

      axios
        .get("GetFournisseurByUID", {
          params: { UID: item.Fournisseur_UID },
        })
        .then((response) => {
          //console.log(response.data);
          setFournisseur(response.data);

          if (!isEdit) {
            item.Nom = response.data.Nom;
            item.Adresse = response.data.Adresse;
            item.Courriel = response.data.Courriel;
            item.Tel = response.data.Tel;
            item.Cell = response.data.Cell;
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const submitThis = async () => {
    setFormError(validateSubmit(item));
    console.log(formError);
  };

  const validateSubmit = (getItem) => {
    let errors = {};
    let codeValid = false;
    let dateFinValid = false;
    let uniteUidValid = false;
    let courrielValid = false;
    let dateDebutValid = false;
    let matiereValid = false;
    let prixUnitaireValid = false;
    let typeValid = false;
    let fournisseurValid = false;
    let nomValid = false;
    let adresseValid = false;
    let actifValid = false;

    const regex = /^[0-9]*$/;
    const endTime = "3000-01-01";
    const regexmail =
      /^(([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)(\s*;\s*|\s*$))+/i;
    //Type Id validation

    if (getItem.Fournisseur_UID == null) {
      console.log(getItem.Fournisseur_UID);
      errors.Fournisseur_UID = "La sélection d'un type est obligatoire.";
      fournisseurValid = false;
    } else {
      fournisseurValid = true;
    }

    //Code validation

    if (getItem.NumContrat == "" || getItem.NumContrat == null) {
      errors.NumContrat = "Le numéro du contrat est obligatoire";
      codeValid = false;
    } else {
      codeValid = true;
    }
    // Nom validation

    if (getItem.Nom == "" || getItem.Nom == null) {
      errors.Nom = "Le nom est obligatoire.";
      nomValid = false;
    } else {
      nomValid = true;
    }

    if (getItem.Matiere_UID == null || getItem.Matiere_UID == -1) {
      errors.Matiere = "La matière est obligatoire.";
      matiereValid = false;
    } else {
      matiereValid = true;
    }

    //Adresse validation

    if (getItem.Adresse == "" || getItem.Adresse == null) {
      errors.Adresse = "L'adresse est obligatoire.";
      adresseValid = false;
    } else {
      adresseValid = true;
    }
    //Courriel validation

    if (getItem.Courriel == "" || getItem.Courriel == null) {
      errors.Courriel = "Le courriel est obligatoire.";
      courrielValid = false;
    }
    // if(!regexmail.test(getItem.Courriel)){
    //   // errors.Courriel = "Le courriel est invalide."   regexmail.test(getItem.Courriel)
    //    courrielValid = false;
    // }
    if (getItem.Courriel != "" && getItem.Courriel != null) {
      courrielValid = true;
    }

    if (getItem.PrixUnitaire > 1000) {
      errors.PrixUnitaire = "Le prix ne doit pas dépasser 1000$.";
      prixUnitaireValid = false;
    }
    if (
      getItem.PrixUnitaire == null ||
      getItem.PrixUnitaire == 0 ||
      getItem.PrixUnitaire == ""
    ) {
      errors.PrixUnitaire = "Le prix est obligatoire.";
      prixUnitaireValid = false;
    }
    if (
      getItem.PrixUnitaire < 1000 ||
      getItem.PrixUnitaire != null ||
      getItem.PrixUnitaire != 0 ||
      getItem.PrixUnitaire != ""
    ) {
      prixUnitaireValid = true;
    }

    if (
      getItem.Unite_UID == -1 ||
      getItem.Unite_UID == "" ||
      getItem.Unite_UID == null
    ) {
      errors.Unite_UID = "L'unité est obligatoire.";
      uniteUidValid = false;
    } else {
      uniteUidValid = true;
    }
    //Actif validation

    if (getItem.Actif == -1 || getItem.Actif == null) {
      errors.Actif = "La sélection d'un actif est obligatoire.";
      actifValid = false;
    } else {
      actifValid = true;
    }

    if (getItem.DateDebut == null) {
      errors.DateDebut = "La sélection d'une date de début est obligatoire.";
      dateDebutValid = false;
    }
    if (getItem.DateDebut >= endTime) {
      errors.DateDebut = "La date du début est invalide.";
      dateDebutValid = false;
    } else if (getItem.DateDebut != null) {
      dateDebutValid = true;
    }

    if (getItem.DateFin == null) {
      errors.DateFin = "La date de fin est obligatoire.";
      dateFinValid = false;
    }
    if (getItem.DateFin <= getItem.DateDebut) {
      errors.DateFin = "La date de fin doit être supérieur à la date du début.";
      dateFinValid = false;
    }
    if (getItem.DateFin >= endTime) {
      dateFinValid = false;
    } else if (getItem.DateFin != null) {
      dateFinValid = true;
    }

    console.debug(dateFinValid);

    console.debug(uniteUidValid);

    console.debug(courrielValid);

    console.debug(dateDebutValid);

    console.debug(matiereValid);

    console.debug(prixUnitaireValid);

    console.debug(fournisseurValid);

    console.debug(nomValid);

    console.debug(adresseValid);

    console.debug(actifValid);

    if (
      dateFinValid &&
      uniteUidValid &&
      courrielValid &&
      dateDebutValid &&
      matiereValid &&
      prixUnitaireValid &&
      fournisseurValid &&
      nomValid &&
      adresseValid &&
      actifValid
    ) {
      // console.log("holle");
      handleSaveItem();
      return errors;
    }
    return errors;
  };

  const handleSaveItem = async () => {
    setErrorMsg();
    console.log(item);
    if (isEdit) {
      //Edition
      setIsSaving(false);
      setIsLoading(true);
      try {
        await axios.put("EditContratCentreTraitement", item);
        // const itemInArray = dataState.find((q) => q.UID === res.data.UID);
        // dataState[dataState.indexOf(itemInArray)] = res.data;
        // setDataState([...dataState]);
        toast.success("Sauvegardé!");
        handleSaveItemParent(item, isEdit);
        handleCloseModal();
      } catch (err) {
        setErrorMsg(err.response.data);
      } finally {
        setIsSaving(true);

        setIsLoading(false);
      }
    } else {
      //Add
      setIsSaving(false);
      setIsLoading(true);
      try {
        await axios.post("AddContratCentreTraitement", item);
        //setDataState(dataState.concat(item));
        toast.success("Sauvegardé!");
        handleSaveItemParent(item, isEdit);
        handleCloseModal();
      } catch (err) {
        setErrorMsg(err.response.data);
      } finally {
        setIsSaving(true);
        setIsLoading(false);
      }
    }

    handleSaveItemParent(item, isEdit);

    const res = await handleSaveItemParent(item, isEdit);

    if (res === true) {
      setShowState(false);
    }
  };

  const handleCloseModal = () => {
    setShowState(false);
    setErrorMsg("");
    setFormError({});
    resetValues(initialItemState);
  };

  const resetValues = (initialItemState) => {
    item.NumContrat = initialItemState.NumContrat;
    item.Fournisseur_UID = initialItemState.Fournisseur_UID;
    item.Nom = initialItemState.Nom;
    item.Adresse = initialItemState.Adresse;
    item.Courriel = initialItemState.Courriel;
    item.Tel = initialItemState.Tel;
    item.Cell = initialItemState.Cell;
    item.Note = initialItemState.Note;
    item.Matiere_UID = initialItemState.Matiere_UID;
    item.PrixUnitaire = initialItemState.PrixUnitaire;
    item.Unite_UID = initialItemState.Unite_UID;
    item.Actif = initialItemState.Actif;
    item.DateDebut = initialItemState.DateDebut;
    item.DateFin = initialItemState.DateFin;
  };

  return (
    <>
      <div align="right">
        {isEdit ? (
          <Button
            variant="primary"
            size="sm"
            onClick={() => {
              setShowState(true);
            }}
          >
            Modifier
          </Button>
        ) : (
          <Button
            variant="primary"
            onClick={() => {
              item.Matiere_UID = 0;
              item.Actif = -1;
              setShowState(true);
            }}
          >
            Ajouter
          </Button>
        )}
      </div>

      <Modal
        show={showState}
        onHide={handleCloseModal}
        backdrop="static"
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>Contrat de centres de traitement</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Numéro du contrat *</Form.Label>
                <Form.Control
                  defaultValue={item?.NumContrat}
                  onChange={(e) => (item.NumContrat = e.target.value)}
                  // readOnly={isEdit}
                />
                {formError.NumContrat && (
                  <span className="error">{formError.NumContrat}</span>
                )}
              </Form.Group>

              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Nom du fournisseur *</Form.Label>
                <Form.Select
                  autoFocus
                  defaultValue={item?.Fournisseur_UID}
                  onChange={handleFournisseurChange}
                  readOnly={isEdit}
                  disabled={isEdit}
                  as="select"
                >
                  <option value="-1">Veuillez faire une sélection</option>
                  {getFournisseurs.map((item) => (
                    <option key={item.UID} value={item.UID}>
                      {`${item.Nom} (${item.Code})`}
                    </option>
                  ))}
                </Form.Select>
                {formError.Fournisseur_UID && (
                  <span className="error">{formError.Fournisseur_UID}</span>
                )}
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Nom du contact *</Form.Label>
                <Form.Control
                  defaultValue={item?.Nom}
                  onChange={(e) => (item.Nom = e.target.value)}
                />
                {formError.Nom && (
                  <span className="error">{formError.Nom}</span>
                )}
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Adresse *</Form.Label>
                <Form.Control
                  defaultValue={item?.Adresse}
                  onChange={(e) => (item.Adresse = e.target.value)}
                />
                {formError.Adresse && (
                  <span className="error">{formError.Adresse}</span>
                )}
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Courriel *</Form.Label>
                <Form.Control
                  defaultValue={item?.Courriel}
                  onChange={(e) => (item.Courriel = e.target.value)}
                />
                {formError.Courriel && (
                  <span className="error">{formError.Courriel}</span>
                )}
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Tel</Form.Label>
                <Form.Control
                  defaultValue={item?.Tel}
                  onChange={(e) => (item.Tel = e.target.value)}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Cell</Form.Label>
                <Form.Control
                  defaultValue={item?.Cell}
                  onChange={(e) => (item.Cell = e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Date Début *</Form.Label>
                <Form.Control
                  type="date"
                  min={"2000-01-01"}
                  max="3000-01-01"
                  defaultValue={
                    item?.DateDebut
                      ? dayjs(item?.DateDebut).format("YYYY-MM-DD")
                      : item?.DateDebut
                  }
                  onChange={(e) => (item.DateDebut = e.target.value)}
                  onKeyDown={(evt) => evt.preventDefault()}
                />
                {formError.DateDebut && (
                  <span className="error">{formError.DateDebut}</span>
                )}
              </Form.Group>

              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Date Fin *</Form.Label>
                <Form.Control
                  type="date"
                  defaultValue={
                    item?.DateFin
                      ? dayjs(item?.DateFin).format("YYYY-MM-DD")
                      : item?.DateFin
                  }
                  onChange={(e) => (item.DateFin = e.target.value)}
                  min={"2000-01-01"}
                  max="3000-01-01"
                  onKeyDown={(evt) => evt.preventDefault()}
                />
                {formError.DateFin && (
                  <span className="error">{formError.DateFin}</span>
                )}
              </Form.Group>

              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Matières *</Form.Label>
                <Form.Select
                  autoFocus
                  defaultValue={item?.Matiere_UID}
                  onChange={(e) => (item.Matiere_UID = e.target.value)}
                  // readOnly={isEdit}
                  // disabled={isEdit}
                  as="select"
                >
                  <option value="0" disabled>
                    Veuillez faire une sélection
                  </option>
                  {getMatieres.map((item) => (
                    <option
                      key={item.UID}
                      value={item.UID}
                    >{`${item.Nom}`}</option>
                  ))}
                </Form.Select>
                {formError.Matiere && (
                  <span className="error">{formError.Matiere}</span>
                )}
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Prix par unité *</Form.Label>
                <Form.Control
                  type="number"
                  defaultValue={item?.PrixUnitaire?.toFixed(2)}
                  onChange={(e) => (item.PrixUnitaire = Number(e.target.value))}
                  onKeyDown={(e) =>
                    exceptThisSymbols.includes(e.key) && e.preventDefault()
                  }
                />
                {formError.PrixUnitaire && (
                  <span className="error">{formError.PrixUnitaire}</span>
                )}
              </Form.Group>

              {/* UNITÉ */}
              <Form.Group className="mb-3">
                <Form.Label>Unité *</Form.Label>
                <Form.Select
                  defaultValue={item?.Unite_UID ?? -1}
                  onChange={(e) => (item.Unite_UID = e.target.value)}
                  as="select"
                >
                  <option value={-1} disabled>
                    Veuillez sélectionner une option
                  </option>
                  {getUnites.map((item) => (
                    <option
                      key={item.UID}
                      value={item.UID}
                    >{`${item.Nom}`}</option>
                  ))}
                </Form.Select>
                {formError.Unite_UID && (
                  <span className="error">{formError.Unite_UID}</span>
                )}
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Note</Form.Label>
                <Form.Control
                  defaultValue={item?.Note}
                  onChange={(e) => (item.Note = e.target.value)}
                />
              </Form.Group>

              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Actif *</Form.Label>
                <Form.Select
                  defaultValue={item?.Actif}
                  onChange={(e) => (item.Actif = Number(e.target.value))}
                  as="select"
                >
                  <option value={-1} disabled>
                    Veuillez sélectionner une option
                  </option>
                  <option value={0}>Non-actif</option>
                  <option value={1}>Actif</option>
                </Form.Select>
                {formError.Actif && (
                  <span className="error">{formError.Actif}</span>
                )}
              </Form.Group>
            </Col>
          </Row>

          {getErrorMsg && (
            <Alert className="mt-4" variant="danger">
              {getErrorMsg}
            </Alert>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Retour
          </Button>
          {getIsSaving && (
            <Button variant="primary" onClick={submitThis}>
              Sauvegarder
            </Button>
          )}
          {getIsLoading && (
            <Button variant="primary" disabled>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              Chargement...
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}
