import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import { toast } from "react-toastify";
import Spinner from "react-bootstrap/Spinner";
import axios from "axios";

export default function EditFournisseurUtilisateur({
  isEdit,
  item,
  fournisseurUID,
  handleRefreshData,
}) {
  const [showDelete, setShowDelete] = useState(false);
  const [showState, setShowState] = useState(false);
  const [itemState, setItemState] = useState(item);
  const [isEditing, setEdit] = useState(false);
  const [errorMsg, setErrorMsg] = useState();
  const [showStateSave, setShowStateSave] = useState(true);
  const [showStateLoading, setShowStateLoading] = useState(false);
  const [showStateDelete, setShowStateDelete] = useState(true);
  const [showErrorMsgFront, setShowEMF] = useState();
  const [initialItemState, setinitialItemState] = useState();

  useEffect(() => {
    setinitialItemState(item);
  }, []);

  // Save
  const handleSaveItem = async () => {
    setShowEMF("");
    setShowStateDelete(false);
    setShowStateSave(false);
    setShowStateLoading(true);
    if (item.ADID == "" || item.ADID == null) {
      setShowEMF("L'ID dans AD est obligatoire");
      setShowStateLoading(false);
      setShowStateSave(true);
    } else {
      try {
        if (isEdit) {
          // Edit
          await axios.put("EditFournisseurUtilisateur", item);
        } else {
          // Add
          item.Fournisseur_UID = fournisseurUID;
          //console.debug(itemState);
          await axios.post("AddFournisseurUtilisateur", item);
        }
        await handleRefreshData();

        setShowState(false);
        toast.success("Sauvegardé");
      } catch (err) {
        setErrorMsg(err.response.data);
      } finally {
        setShowStateDelete(true);
        setShowStateSave(true);
        setShowStateLoading(false);
      }
    }
  };

  const handleCloseModal = () => {
    setShowState(false);
    setShowDelete(false);
    resetValues(initialItemState);
  };

  const resetValues = (initialItemState) => {
    item.ADID = initialItemState.ADID;
    item.Note = initialItemState.Note;
  };

  const handleDeleteItem = async () => {
    setShowStateDelete(false);
    setShowStateLoading(true);

    try {
      // console.debug(itemState);
      // console.debug(item);
      const json = JSON.stringify(item);
      console.debug(json);

      await axios.delete("DeleteFournisseurUtilisateur", { data: json });

      await handleRefreshData(item);

      setShowDelete(false);
      toast.success("Suprimé");
    } catch (err) {
      console.log(err.response.data);
    } finally {
      setShowStateLoading(false);
      setShowDelete(true);
    }
  };
  return (
    <>
      <div align="right">
        {isEdit ? (
          <Button
            variant="primary"
            size="sm"
            onClick={() => {
              setShowState(true);
              setErrorMsg("");
              setEdit(true);
            }}
          >
            Modifier
          </Button>
        ) : (
          <Button
            variant="primary"
            onClick={() => {
              setShowState(true);
              setErrorMsg("");
              setEdit(false);
            }}
          >
            Ajouter
          </Button>
        )}
      </div>

      <Modal show={showState} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Utilisateur</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>ID dans AD</Form.Label>
              <Form.Control
                defaultValue={item?.ADID}
                onChange={(e) => (item.ADID = e.target.value)}
                autoFocus
              />
              {showErrorMsgFront && (
                <span className="error">{showErrorMsgFront}</span>
              )}
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Note</Form.Label>
              <Form.Control
                defaultValue={item?.Note}
                onChange={(e) => (item.Note = e.target.value)}
              />
              {errorMsg && (
                <Alert className="mt-4" variant="danger">
                  {errorMsg}
                </Alert>
              )}
            </Form.Group>
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Retour
          </Button>
          {showStateSave && (
            <Button variant="primary" onClick={handleSaveItem}>
              Sauvegarder
            </Button>
          )}
          {showStateDelete && isEditing && (
            <Button
              variant="primary"
              onClick={() => {
                setShowState(false);
                setShowDelete(true);
                setEdit(false);
              }}
            >
              Suprimer
            </Button>
          )}
          {showStateLoading && (
            <Button variant="primary" disabled>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              Chargement...
            </Button>
          )}
        </Modal.Footer>
      </Modal>

      <Modal show={showDelete} onHide={handleCloseModal}>
        <Modal.Body>
          Etes vous sur de vouloir suprimer l'Utilisateur?
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Retour
          </Button>
          {showStateDelete && (
            <Button variant="primary" onClick={handleDeleteItem}>
              Suprimer
            </Button>
          )}
          {showStateLoading && (
            <Button variant="primary" disabled>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              Chargement...
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}
