import React, { useState, useEffect } from "react";
import axios from "axios";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";

export default function SiteMatiereVolumeCheckbox({
  siteUID,
  siteMatiere,
  matiere,
  volume,
}) {
  const [getIsLoading, setIsLoading] = useState(true);
  const [getIsChecked, setIsChecked] = useState(false);

  useEffect(() => {
    //console.log(siteMatiere);
    if (siteMatiere?.UID != null && volume?.UID) {
      axios
        .get(
          `GetSiteMatiereVolumes?siteMatiereUID=${siteMatiere.UID}&volumeUID=${volume.UID}`
        )
        .then((response) => {
          //setSiteMatiere(response.data);
          if (response.data != null) {
            //console.log(response.data);
            setIsChecked(true);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
    setIsLoading(false);
  }, [siteMatiere]);

  const handleChange = async (e) => {
    const item = {
      SiteUID: siteUID,
      MatiereUID: matiere.UID,
      VolumeUID: volume.UID,
      IsChecked: e.target.checked,
    };
    try {
      setIsLoading(true);
      setIsChecked(e.target.checked);
      await axios.put("EditSiteMatiereVolume", item); // HACK try/catch
      setIsLoading(false);
      //
    } catch (error) {
      setIsChecked(!e.target.checked);
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {getIsLoading ? (
        <Spinner animation="border" variant="primary" size="sm" />
      ) : (
        <Form.Check
          checked={getIsChecked}
          type="checkbox"
          label={volume.Nom}
          onChange={handleChange}
        />
      )}
    </>
  );
}
