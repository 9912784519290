import styles from "./Sites.module.scss";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import dayjs from "dayjs";
import DataTable from "../base-components/DataTableBase";
import EditSite from "./EditSite";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
import useApi from "../../hooks/useApi";
import { toast } from "react-toastify";

export default function Sites() {
  // Hooks
  const { apiSearchDemandes, apiGetSites, apiGetMatieres } = useApi();

  const [dataState, setDataState] = useState();
  const [getIsDataLoading, setIsDataLoading] = useState(true);

  const [showToastState, setShowToastState] = useState(false);
  const [toastMsgState, setToastMsgState] = useState();

  const [responseData, setResponseData] = useState();

  useEffect(() => {
    loadSites();
  }, []);

  async function loadSites() {
    try {
      setIsDataLoading(true);
      const sites = await apiGetSites(null, true);
      setDataState(sites);
    } catch (err) {
      //console.debug(err);
      toast.error(
        "Une erreur est survenue lors du chargement des lieux de service."
      );
    } finally {
      setIsDataLoading(false);
    }
  }

  const handleSaveItem = async (item, isEdit) => {
    if (isEdit) {
      // Edit Site
      const res = await axios.put("EditSite", item);
      const itemInArray = dataState.find((q) => q.UID === res.data.UID);
      dataState[dataState.indexOf(itemInArray)] = res.data;

      setDataState([...dataState]);
    } else {
      // Add Site
      const res = await axios.post("AddSite", item);
      setDataState(dataState.concat(res.data));
    }
  };

  const columns = [
    {
      name: "ID",
      wrap: true,
      selector: (row) => row.ID,
      sortable: true,
    },
    {
      name: "Nom",
      wrap: true,
      selector: (row) => row.Nom,
      sortable: true,
    },
    {
      name: "Adresse",
      wrap: true,
      selector: (row) => row.Adresse,
      sortable: false,
    },
    {
      name: "Type",
      wrap: true,
      selector: (row) => {
        switch (row.TypeID) {
          case "1":
            return "Écocentre";
          case "2":
            return "Secteur";
          case "3":
            return "Lieu spécial";
          default:
            return "s/o";
        }
      },
      sortable: false,
    },
    {
      name: "Date Début",
      wrap: true,
      selector: (row) =>
        row.DateDebut
          ? dayjs(row.DateDebut).format("YYYY-MM-DD")
          : row.DateDebut,
      sortable: false,
    },
    {
      name: "Date Fin",
      wrap: true,
      selector: (row) =>
        row.DateFin ? dayjs(row.DateFin).format("YYYY-MM-DD") : row.DateFin,
      sortable: false,
    },
    {
      name: "Actif",
      wrap: true,
      selector: (row) => (row.Actif === 1 ? "Actif" : "Non-actif"),
    },
    {
      name: "Note",
      wrap: true,
      selector: (row) => row.Note,
      sortable: false,
    },
    {
      button: true,
      cell: (record) => (
        <Link to={"/admin/site-matieres"} state={record}>
          Matières
        </Link>
      ),
    },
    {
      button: true,
      cell: (record) => (
        <Link to={"/admin/site-horaire"} state={record}>
          Horaire
        </Link>
      ),
    },
    {
      button: true,
      cell: (record) => (
        <Link to={"/admin/places"} state={record}>
          Places
        </Link>
      ),
    },
    {
      button: true,
      cell: (record) => (
        <EditSite
          isEdit={true}
          item={Object.assign({}, record)}
          handleSaveItemParent={handleSaveItem}
        />
      ),
    },
  ];

  return (
    <div className={styles.Matieres}>
      <h1 align="center">Lieux de service</h1>

      <div align="right">
        <EditSite
          isEdit={false}
          item={{}}
          handleSaveItemParent={handleSaveItem}
        />
      </div>

      <br />

      <div>
        <DataTable
          title=""
          columns={columns}
          data={dataState}
          progressPending={getIsDataLoading}
        />
      </div>

      <ToastContainer
        className="p-3"
        position="middle-end"
        style={{ zIndex: 1000000 }}
      >
        <Toast
          bg="danger"
          onClose={() => setShowToastState(false)}
          show={showToastState}
          delay={4000}
          autohide
        >
          <Toast.Header>
            <strong className="me-auto">Erreur</strong>
          </Toast.Header>
          <Toast.Body className="text-white">{toastMsgState}</Toast.Body>
        </Toast>
      </ToastContainer>
    </div>
  );
}
