import { useState, useEffect } from "react";
import {
  Form,
  Row,
  Col,
  Card,
  Button,
  Spinner,
  FormGroup,
  InputGroup,
} from "react-bootstrap";
import axios from "axios";

export default function ContratTransporteurPrixEdit({ volumePrix }) {
  const exceptThisSymbols = ["e", "E", "+", "-"];
  const maxValue = 100000;

  return (
    <>
      <Row>
        <Col>
          <Form.Control type="text" defaultValue={volumePrix?.Nom} readOnly />
        </Col>
        <Col>
          <InputGroup>
            <Form.Control
              type="number"
              defaultValue={volumePrix?.PrixJourSemaine?.toFixed(2)}
              min="0"
              onChange={(e) => {
                volumePrix.PrixJourSemaine = Number(e.target.value);
                volumePrix.IsDirty = true;
              }}
              onKeyDown={(e) => exceptThisSymbols.includes(e.key) && e.preventDefault()}
            />
            <InputGroup.Text>$</InputGroup.Text>
          </InputGroup>
        </Col>
        <Col>
          <InputGroup>
            <Form.Control
              type="number"
              defaultValue={volumePrix?.PrixJourFinSemaine?.toFixed(2)}
              min="0"
              onChange={(e) => {
                volumePrix.PrixJourFinSemaine = Number(e.target.value);
                volumePrix.IsDirty = true;
              }}
              onKeyDown={(e) => exceptThisSymbols.includes(e.key) && e.preventDefault()}
            />
            <InputGroup.Text>$</InputGroup.Text>
          </InputGroup>
        </Col>
        <Col>
          <InputGroup>
            <Form.Control
              type="number"
              defaultValue={volumePrix?.PrixJourSemaineDeplacement?.toFixed(2)}
              min="0"
              onChange={(e) => {
                volumePrix.PrixJourSemaineDeplacement = Number(e.target.value);
                volumePrix.IsDirty = true;
              }}
              onKeyDown={(e) => exceptThisSymbols.includes(e.key) && e.preventDefault()}
            />
            <InputGroup.Text>$</InputGroup.Text>
          </InputGroup>
        </Col>
        <Col>
          <InputGroup>
            <Form.Control
              type="number"
              defaultValue={volumePrix?.PrixJourFinSemaineDeplacement?.toFixed(
                2
              )}
              min="0"
              onChange={(e) => {
                volumePrix.PrixJourFinSemaineDeplacement = Number(
                  e.target.value
                );
                volumePrix.IsDirty = true;
              }}
              onKeyDown={(e) => exceptThisSymbols.includes(e.key) && e.preventDefault()}
            />
            <InputGroup.Text>$</InputGroup.Text>
          </InputGroup>
        </Col>
      </Row>
    </>
  );
}
