import React, { useState, useEffect } from "react";
import {
  Form,
  Stack,
  Row,
  Col,
  Card,
  Button,
  Spinner,
  Alert,
} from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import axios from "axios";
import dayjs from "dayjs";
import Modal from "react-bootstrap/Modal";
import ContratTransporteurPrixEdit from "./ContratTransporteurPrixEdit";

export default function ContratTransporteurPrix() {
  const navigate = useNavigate();
  const location = useLocation();
  const contratTransportUID = location.state.UID;
  const [getVolumesPrix, setVolumesPrix] = useState([]);
  const [getIsLoading, setIsLoading] = useState(true);
  const [getIsSaving, setIsSaving] = useState(false);
  const [getErrorMsg, setErrorMsg] = useState();
  const [getSuccessMsg, setSuccessMsg] = useState();

  // TBD
  const maxValue = 10000;

  useEffect(() => {
    axios
      .get("GetContratTransportVolumesPrix", {
        params: { contratTransportUID },
      })
      .then((response) => {
        if (true) {
          response.data.forEach((x) => {
            x.Key = uuidv4();
            x.IsDirty = false;
          });

          setVolumesPrix(response.data);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const handleSaveClick = async (e) => {
    validatePrices(getVolumesPrix);
  };

  const validatePrices = async (prices) => {
    let valid = true;
    getVolumesPrix.forEach((element) => {
      if (element.PrixJourSemaine > maxValue) {
        setErrorMsg("Les prix ne peuvent pas être plus haut que " + maxValue);
        valid = false;
      }
      if (element.PrixJourFinSemaine > maxValue) {
        setErrorMsg("Les prix ne peuvent pas être plus haut que " + maxValue);
        valid = false;
      }
      if (element.PrixJourSemaineDeplacement > maxValue) {
        setErrorMsg("Les prix ne peuvent pas être plus haut que " + maxValue);
        valid = false;
      }
      if (element.PrixJourFinSemaineDeplacement > maxValue) {
        setErrorMsg("Les prix ne peuvent pas être plus haut que " + maxValue);
        valid = false;
      }

      if (element.PrixJourSemaine < 0) {
        setErrorMsg("Les prix ne peuvent pas être plus bas que " + 0);
        valid = false;
      }
      if (element.PrixJourFinSemaine < 0) {
        setErrorMsg("Les prix ne peuvent pas être plus bas que " + 0);
        valid = false;
      }
      if (element.PrixJourSemaineDeplacement < 0) {
        setErrorMsg("Les prix ne peuvent pas être plus bas que " + 0);
        valid = false;
      }
      if (element.PrixJourFinSemaineDeplacement < 0) {
        setErrorMsg("Les prix ne peuvent pas être plus bas que " + 0);
        valid = false;
      }
    });

    if (valid) {
      handleSaveItems();
    }
  };

  const handleSaveItems = async () => {
    try {
      setErrorMsg();
      setSuccessMsg();
      setIsSaving(true);
      await axios.post("EditContratTransportVolumePrix", getVolumesPrix);
      setSuccessMsg("Sauvegardé!");
    } catch (err) {
      setErrorMsg(err.response.data);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <>
      <h2>Prix de transports</h2>
      <Row>
        <Col>Volume (verges)</Col>
        <Col>Prix Jour Semaine</Col>
        <Col>Prix Jour Fin de semaine</Col>
        <Col>Prix Jour Semaine pour déplacement</Col>
        <Col>Prix Jour Fin de semaine pour déplacement</Col>
      </Row>

      {getIsLoading && (
        <Stack className="mt-4">
          <Spinner />
        </Stack>
      )}
      {getVolumesPrix.map((eachVolumePrix) => (
        <ContratTransporteurPrixEdit
          key={eachVolumePrix.Key}
          volumePrix={eachVolumePrix}
        />
      ))}

      {getSuccessMsg && (
        <Alert className="mt-4" variant="success">
          {getSuccessMsg}
        </Alert>
      )}

      {getErrorMsg && (
        <Alert className="mt-4" variant="danger">
          {getErrorMsg}
        </Alert>
      )}

      <Stack className="mt-4 center" direction="horizontal" gap={3}>
        <Button variant="secondary" onClick={() => navigate(-1)}>
          Retour
        </Button>

        {!getIsLoading && !getIsSaving && (
          <Button onClick={handleSaveClick}>Sauvegarder</Button>
        )}

        {getIsSaving && <Spinner />}
      </Stack>
    </>
  );
}
