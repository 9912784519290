import React, { useState, useEffect, lazy } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import "./index.scss";
import useApi from "./hooks/useApi";
import useConst from "./hooks/useConst";

// Import main components
import App from "./App";
import NoAccess from "./components/no-access/NoAccess";
import Dashboard from "./components/Dashboard/Dashboard";
import NouvelleDemande from "./components/demande/NouvelleDemande";
import DemandeNext from "./components/demande/DemandeNext";
import DemandeView from "./components/demande/DemandeView";
//import EnConstruction from "./components/en-construction/EnConstruction";

// Import admin components
import VolumesConteneurs from "./components/volumesconteneurs/VolumesConteneurs";
import Matieres from "./components/matieres/Matieres";
import Sites from "./components/sites/Sites";
import SiteHoraire from "./components/sites/SiteHoraire";
import SiteMatieres from "./components/sites/SiteMatieres";
import Places from "./components/places/Places";
import Fournisseurs from "./components/fournisseurs/Fournisseurs";
import FournisseursUtilisateurs from "./components/fournisseurs-utilisateurs/FournisseursUtilisateurs";
import ContratsCentresTraitement from "./components/contrats-centres-traitement/ContratsCentresTraitement";
import CentreTraitementHoraire from "./components/contrats-centres-traitement/CentreTraitementHoraire";
import ContratsTransporteurs from "./components/contrats-transporteurs/ContratsTransporteurs";
import ContratTransporteurPrix from "./components/contrats-transporteurs/ContratTransporteurPrix";

//const HACK Test = lazy(() => import("./components/test/Test"));

export default function AppRoutes() {
  const [routeAdmin, setRouteAdmin] = useState(false);

  // CONST
  const { userRole } = useConst();

  // API
  const { apiGetUserRole } = useApi();

  useEffect(() => {
    checkRole();
  }, []);

  async function checkRole() {
    const role = await apiGetUserRole();
    if (role !== null && role === userRole.Administrateur) {
      setRouteAdmin(true);
    }
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path={`${process.env.PUBLIC_URL}/noaccess`}
          element={<NoAccess />}
        />
        <Route path={`${process.env.PUBLIC_URL}/`} element={<App />}>
          <Route index element={<Dashboard />} />
          <Route
            path={`${process.env.PUBLIC_URL}/nouvelle-demande`}
            element={<NouvelleDemande />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/demande-view`}
            element={<DemandeView />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/demande-next`}
            element={<DemandeNext />}
          />
          {routeAdmin && (
            <Route
              path={`${process.env.PUBLIC_URL}/admin/matieres`}
              element={<Matieres />}
            />
          )}
          {routeAdmin && (
            <Route
              path={`${process.env.PUBLIC_URL}/admin/volumes-conteneurs`}
              element={<VolumesConteneurs />}
            />
          )}
          {routeAdmin && (
            <Route
              path={`${process.env.PUBLIC_URL}/admin/sites`}
              element={<Sites />}
            />
          )}
          {routeAdmin && (
            <Route
              path={`${process.env.PUBLIC_URL}/admin/site-matieres`}
              element={<SiteMatieres />}
            />
          )}
          {routeAdmin && (
            <Route
              path={`${process.env.PUBLIC_URL}/admin/site-horaire`}
              element={<SiteHoraire />}
            />
          )}
          {routeAdmin && (
            <Route
              path={`${process.env.PUBLIC_URL}/admin/places`}
              element={<Places />}
            />
          )}
          {routeAdmin && (
            <Route
              path={`${process.env.PUBLIC_URL}/admin/fournisseurs`}
              element={<Fournisseurs />}
            />
          )}
          {routeAdmin && (
            <Route
              path={`${process.env.PUBLIC_URL}/admin/fournisseurs-utilisateurs`}
              element={<FournisseursUtilisateurs />}
            />
          )}
          {routeAdmin && (
            <Route
              path={`${process.env.PUBLIC_URL}/admin/contrats-transporteurs`}
              element={<ContratsTransporteurs />}
            />
          )}
          {routeAdmin && (
            <Route
              path={`${process.env.PUBLIC_URL}/admin/contrat-transporteur-prix`}
              element={<ContratTransporteurPrix />}
            />
          )}
          {routeAdmin && (
            <Route
              path={`${process.env.PUBLIC_URL}/admin/contrats-centres-traitement`}
              element={<ContratsCentresTraitement />}
            />
          )}
          {routeAdmin && (
            <Route
              path={`${process.env.PUBLIC_URL}/admin/centre-traitement-horaire`}
              element={<CentreTraitementHoraire />}
            />
          )}
          {/* HACK {routeAdmin && (
            <Route
              path={`${process.env.PUBLIC_URL}/admin/test`}
              element={<Test />}
            />
          )} */}
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
