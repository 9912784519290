import { useState, useEffect } from "react";
import useConst from "../../hooks/useConst";
import { Form } from "react-bootstrap";
import BaseSelect from "./BaseSelect";

export default function SelectSiteType({
  role,
  isLoading,
  handleSiteTypeChange,
}) {
  const { userRole, getTypesSitesAll, getTypesSitesLieuSpecial } = useConst();

  return (
    <>
      <Form.Group>
        <Form.Label>Type de lieu de service</Form.Label>
        {isLoading && <BaseSelect isLoading={isLoading} />}
        {(role === userRole.Administrateur ||
          role === userRole.Superviseur ||
          role === userRole.Prepose) && (
          <BaseSelect
            options={getTypesSitesAll}
            onChange={handleSiteTypeChange}
          />
        )}
        {role === userRole.Demandeur && (
          <BaseSelect
            options={getTypesSitesLieuSpecial}
            onChange={handleSiteTypeChange}
          />
        )}
        {role === userRole.Fournisseur && (
          <BaseSelect readOnly={true} options={getTypesSitesAll} />
        )}
      </Form.Group>
    </>
  );
}
