import { createContext } from "react";

export const GlobalContext = createContext({});

export default function GlobalProvider({children}){
    return(
        <GlobalContext.Provider value={{ test: "aasdasdasaa"}}>
            {children}
        </GlobalContext.Provider>
    );
};