import { useState } from "react";
import dayjs from "dayjs";

export default function useConst() {
  const [getTypesSitesAll] = useState([
    { value: "1", label: "Écocentre" },
    { value: "2", label: "Secteur" },
    { value: "3", label: "Lieu Spécial" },
  ]);

  const [getTypesSitesLieuSpecial] = useState([
    { value: "2", label: "Secteur" },
    { value: "3", label: "Lieu Spécial" },
  ]);

  const [userRole] = useState({
    NoAccess: 0,
    Administrateur: 1,
    Superviseur: 2,
    Prepose: 3,
    Demandeur: 4, // ou Employé de la ville autre que préposé d'un écocentre
    Fournisseur: 5,
  });

  const [typesSites] = useState({
    Ecocentre: 1,
    Secteur: 2,
    LieuSpecial: 3,
  });

  const [typeDemande] = useState({
    Livraison: 0,
    Echange: 1,
    Levee: 2,
    Deplacement: 3,
    EchangeLevee: 4,
    CollecteParLienWeb: 5,
    CollecteParLienCourriel: 6,
  });

  const [etatDemande] = useState({
    EnAttenteApprobation: 0,
    Planifiee: 1,
    EnAttenteConfirmation: 2,
    EnAttenteTransportVersSite: 3,
    EnService: 4,
    EnTransitVersCentreTraitement: 5,
    Completee: 6,
    Annulee: 7,
  });

  const [typeFlag] = useState({
    EnRetardConfirmation: 1,
    EnRetardExecution: 2,
  });

  const [typeFournisseur] = useState({
    Tous: 0,
    Transporteur: 1,
    CentreTraitement: 2,
  });

  function getTypeDemande(type) {
    switch (type) {
      case 0:
        return "Livraison";
      case 1:
        return "Échange";
      case 2:
        return "Levée finale";
      case 3:
        return "Déplacement";
      case 4:
        return "Échange-Levée";
      case 5:
        return "Collecte par lien web";
      case 6:
        return "Collecte par courriel";
      default:
        return "Non défini";
    }
  }

  function getEtatDemande(etat) {
    switch (etat) {
      case 0:
        return "En attente d'approbation";
      case 1:
        return "Planifiée";
      case 2:
        return "En attente de confirmation";
      case 3:
        return "Transport camion vers le lieu de service";
      case 4:
        return "En service";
      case 5:
        return "Transit vers le centre de traitement";
      case 6:
        return "Complétée";
      case 7:
        return "Annulée";
      default:
        return "Non défini";
    }
  }

  function getFlagDemande(etat) {
    switch (etat) {
      case 1:
        return "Retard confirmation";
      case 2:
        return "Retard exécution";
      default:
        return "Non défini";
    }
  }

  function getTypeSite(type) {
    switch (Number(type)) {
      case 1:
        return "Écocentre";
      case 2:
        return "Secteur";
      case 3:
        return "Lieu spécial";
      default:
        return "Non défini";
    }
  }

  function timeToGMT(dayJsDateTime) {
    if (!dayJsDateTime) {
      dayJsDateTime = dayjs();
    }
    return new Date(
      Date.UTC(
        dayJsDateTime.year(),
        dayJsDateTime.month(),
        dayJsDateTime.date(),
        dayJsDateTime.hour(),
        dayJsDateTime.minute(),
        dayJsDateTime.second()
      )
    );
  }

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };

  return {
    userRole,
    getTypesSitesAll,
    getTypesSitesLieuSpecial,
    typesSites,
    typeDemande,
    etatDemande,
    typeFlag,
    getTypeDemande,
    getEtatDemande,
    getFlagDemande,
    getTypeSite,
    typeFournisseur,
    timeToGMT,
    openInNewTab,
  };
}
