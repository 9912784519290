export const MENUITEMS = [
  {
    menutitle: "MENU",
    Items: [
      {
        path: `${process.env.PUBLIC_URL}`,
        icon: "fe fe-home",
        type: "link",
        active: false,
        selected: false,
        title: "Tableau de bord",
      },
      {
        path: `${process.env.PUBLIC_URL}/admin/volumes-conteneurs`,
        icon: "",
        type: "link",
        active: false,
        selected: false,
        title: "Volumes des conteneurs",
      },
      {
        path: `${process.env.PUBLIC_URL}/admin/matieres`,
        icon: "",
        type: "link",
        active: false,
        selected: false,
        title: "Matières",
      },
      {
        path: `${process.env.PUBLIC_URL}/admin/sites`,
        icon: "",
        type: "link",
        active: false,
        selected: false,
        title: "Lieux de service",
      },
      {
        path: `${process.env.PUBLIC_URL}/admin/fournisseurs`,
        icon: "",
        type: "link",
        active: false,
        selected: false,
        title: "Fournisseurs",
      },
      {
        path: `${process.env.PUBLIC_URL}/admin/contrats-transporteurs`,
        icon: "",
        type: "link",
        active: false,
        selected: false,
        title: "Contrats de transport",
      },
      {
        path: `${process.env.PUBLIC_URL}/admin/contrats-centres-traitement`,
        icon: "",
        type: "link",
        active: false,
        selected: false,
        title: "Contrats de traitement",
      },
    ],
  },
];
