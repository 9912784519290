import React from "react";
import styles from "./Header.module.scss";
import { Navbar } from "react-bootstrap";
import { useIsAuthenticated } from "@azure/msal-react";
import SignOutButton from "../../components/msal/SignOutButton";
import ProfileContent from "../../components/msal/ProfileContent";
import { ReactComponent as LavalLogoSmall } from "../../assets/images/brand/laval-logo-small.svg";

export default function Header() {
  const isAuthenticated = useIsAuthenticated();

  return (
    <div className={styles.Header}>
      <div
        className="header sticky app-header header1"
        style={{
          backgroundColor: "#efefef",
          color: "#0054a6",
          border: "0px transparent solid",
          borderBottom: "1px #0054a6 solid",
        }}
      >
        <div className="container-fluid main-container">
          <div className="d-flex">
            <LavalLogoSmall />
            <div className="main-header-center ms-8 d-none d-lg-block">
              <span style={{ fontSize: 20, fontWeight: "bold" }}>
                Gestion des demandes de conteneurs
              </span>
            </div>
            <Navbar className="d-flex order-lg-2 ms-auto header-right-icons">
              {isAuthenticated ? <ProfileContent /> : ""}&nbsp;&nbsp;
              {isAuthenticated ? <SignOutButton /> : ""}
            </Navbar>
          </div>
        </div>
      </div>
    </div>
  );
}
