import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { toast } from "react-toastify";
import Alert from "react-bootstrap/Alert";
import Spinner from "react-bootstrap/Spinner";

export default function EditPlace({ isEdit, item, handleSaveItemParent }) {
  const [showState, setShowState] = useState(false);
  //const [itemState, setItemState] = useState(item);
  const [getLatitude, setLatitude] = useState(item.Latitude);
  const [getLongitude, setLongitude] = useState(item.Longitude);
  const [errorMsg, setErrorMsg] = useState("");
  const [showStateSave, setShowStateSave] = useState(true);
  const [showStateLoading, setShowStateLoading] = useState(false);
  const [initialItemState, setinitialItemState] = useState();

  useEffect(() => {
    setinitialItemState(item);
  }, []);

  const handleSaveItem = async () => {
    setShowStateSave(false);
    setShowStateLoading(true);

    try {
      await handleSaveItemParent(item, isEdit);
      setShowState(false);
      toast.success("Sauvegardé");
    } catch (err) {
      setErrorMsg(err.response.data);
      console.log(err.response.data);
    } finally {
      setShowStateSave(true);
      setShowStateLoading(false);
    }
  };

  const handleCloseModal = () => {
    setShowState(false);
    resetValues(initialItemState);
  };

  const resetValues = (initialItemState) => {
    item.Code = initialItemState.Code;
    item.Description = initialItemState.Description;
    item.DateDebut = initialItemState.DateDebut;
    item.DateFin = initialItemState.DateFin;
    item.Note = initialItemState.Note;
    item.Actif = initialItemState.Actif;
    item.Longitude = initialItemState.Longitude;
    item.Latitude = initialItemState.Latitude;
  };

  return (
    <>
      <div align="right">
        {isEdit ? (
          <Button
            variant="primary"
            size="sm"
            onClick={() => {
              setShowState(true);
              setErrorMsg("");
            }}
          >
            Modifier
          </Button>
        ) : (
          <Button
            variant="primary"
            onClick={() => {
              setShowState(true);
              setErrorMsg("");
            }}
          >
            Ajouter
          </Button>
        )}
      </div>

      <Modal show={showState} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Place</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form onSubmit={handleSaveItem}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>No. Place *</Form.Label>
              <Form.Control
                defaultValue={item?.Code}
                onChange={(e) => (item.Code = e.target.value)}
                autoFocus
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Description de la place *</Form.Label>
              <Form.Control
                defaultValue={item?.Description}
                onChange={(e) => (item.Description = e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Date Début *</Form.Label>
              <Form.Control
                type="date"
                defaultValue={
                  item?.DateDebut
                    ? dayjs(item?.DateDebut).format("YYYY-MM-DD")
                    : item?.DateDebut
                }
                onChange={(e) => (item.DateDebut = e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Date Fin</Form.Label>
              <Form.Control
                type="date"
                defaultValue={
                  item?.DateFin
                    ? dayjs(item?.DateFin).format("YYYY-MM-DD")
                    : item?.DateFin
                }
                onChange={(e) =>
                  (item.DateFin = e.target.value !== "" ? e.target.value : null)
                }
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Latitude</Form.Label>
              <Form.Control
                type="number"
                onKeyDown={(evt) =>
                  evt.key.toLowerCase() === "e" && evt.preventDefault()
                }
                defaultValue={item?.Latitude}
                onChange={(e) => {
                  item.Latitude =
                    e.target.value !== "" ? Number(e.target.value) : null;
                  setLatitude(
                    e.target.value !== "" ? Number(e.target.value) : null
                  );
                }}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Longitude</Form.Label>
              <Form.Control
                type="number"
                onKeyDown={(evt) =>
                  evt.key.toLowerCase() === "e" && evt.preventDefault()
                }
                defaultValue={item?.Longitude}
                onChange={(e) => {
                  console.log(e.target.value);
                  item.Longitude =
                    e.target.value !== "" ? Number(e.target.value) : null;
                  setLongitude(
                    e.target.value !== "" ? Number(e.target.value) : null
                  );
                }}
              />
            </Form.Group>

            <Link
              to={`https://www.google.com/maps/search/?api=1&query=${getLatitude},${getLongitude}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Voir la map
            </Link>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Note</Form.Label>
              <Form.Control
                defaultValue={item?.Note}
                onChange={(e) => (item.Note = e.target.value)}
              />
            </Form.Group>

            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Actif *</Form.Label>
              <Form.Select
                defaultValue={item?.Actif}
                onChange={(e) => (item.Actif = Number(e.target.value))}
                as="select"
              >
                <option value={0}>Non-actif</option>
                <option value={1}>Actif</option>
              </Form.Select>
              {errorMsg && (
                <Alert className="mt-4" variant="danger">
                  {errorMsg}
                </Alert>
              )}
            </Form.Group>
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Retour
          </Button>
          {showStateSave && (
            <Button variant="primary" onClick={handleSaveItem}>
              Sauvegarder
            </Button>
          )}
          {showStateLoading && (
            <Button variant="primary" disabled>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              Chargement...
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}
