import { ReactComponent as LavalLogoSmall } from "../../assets/images/brand/laval-logo-small.svg";
import SignOutButton from "../../components/msal/SignOutButton";
import { spaVersion } from "../../Layouts/Footer/Footer";

export default function NoAccess() {
  return (
    <>
      <center>
        <br />
        <br />
        <LavalLogoSmall />
        <br />
        <br />
        <h1>Vous n'avez aucun droit d'accès à cette application.</h1>
        <br />
        <h2>Veuillez communiquer avec le service informatique.</h2>
        <br />
        <h3>Gest Éco</h3>
        <br />
        <br />
        <SignOutButton />
        <br />
        <br />
        <br />
        <br />
        <div style={{ fontSize: "x-small" }}>SPA {spaVersion}</div>
      </center>
    </>
  );
}
