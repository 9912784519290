import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { loginRequestGraphAPI } from "./authConfig";
import { callMsGraph } from "./graph";
import axios from "axios";

export default function ProfileContent() {
  const { instance, accounts } = useMsal();
  const name = accounts[0] && accounts[0].name;
  const navigate = useNavigate();

  useEffect(() => {
    RequestProfileData();
  }, []);

  const RequestProfileData = () => {
    //console.debug("RequestProfileData()");
    const request = {
      ...loginRequestGraphAPI,
      account: accounts[0],
    };

    // Silently acquires an access token which is then attached to a request for Microsoft Graph data
    instance
      .acquireTokenSilent(request)
      .then((response) => {
        callMsGraph(response.accessToken).then((response) => {
          //console.log(response);
          axios
            .put("EditSessionUtilisateur", response.value)
            .then((res) => {
              //console.debug(res);
              if (res.data == null || res.data === "noaccess") {
                navigate("/noaccess");
              }
            })
            .catch(() => navigate("/noaccess"));
        });
      })
      .catch((e) => {
        instance.acquireTokenPopup(request).then((response) => {
          callMsGraph(response.accessToken).then((response) => {
            //console.log(response);
            axios
              .put("EditSessionUtilisateur", response.value)
              .then((res) => {
                //console.debug(res);
                if (res.data == null || res.data === "noaccess") {
                  navigate("/noaccess");
                }
              })
              .catch(() => navigate("/noaccess"));
          });
        });
      });
  };

  return <>{name}</>;
}
