import React, { useState, useEffect } from "react";
//import axios from 'axios';
import dayjs from "dayjs";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import { toast } from "react-toastify";
export default function EditSite({ isEdit, item, handleSaveItemParent }) {
  const [showState, setShowState] = useState(false);
  const [itemState, setItemState] = useState(item);
  const [errorMsg, setErrorMsg] = useState();
  const [formError, setFormError] = useState({});
  const [initialItemState, setinitialItemState] = useState();
  // const [matieresState, setMatieresState] = useState([]);
  // itemState.SelectedMatieres = [];

  useEffect(() => {
    // axios.get("GetMatieres") //Returning pledges using a get-query
    //   .then((response) => { // Data retrieval and processing
    //     setMatieresState(response.data);
    //   })
    //   .catch((error) => { // If the query fails, an error will be displayed on the terminal.
    //     console.error(error);
    //   });
    setinitialItemState(item);
  }, []);

  const submitThis = async () => {
    setFormError(validateSubmit(item));
    console.log(formError);
  };

  const validateSubmit = (itemState) => {
    let errors = {};
    let typeValid = false;
    let dateFinValid = false;
    let dateDebutValid = false;
    let idValid = false;
    let nomValid = false;
    let adresseValid = false;
    let actifValid = false;
    const regex = /^[0-9]*$/;
    const endTime = "3000-01-01";

    //Type Id validation

    if (itemState.TypeID == -1 || itemState.TypeID == null) {
      errors.TypeID = "La sélection d'un type est obligatoire.";
      typeValid = false;
    } else {
      typeValid = true;
    }

    //Id validation

    if (itemState.ID == "" || itemState.ID == null) {
      errors.ID = "L'ID est obligatoire";
      idValid = false;
    } else {
      idValid = true;
    }
    // Nom validation

    if (itemState.Nom == "" || itemState.Nom == null) {
      errors.Nom = "Le nom est obligatoire.";
      nomValid = false;
    } else {
      nomValid = true;
    }

    //Adresse validation

    if (itemState.Adresse == "" || itemState.Adresse == null) {
      errors.Adresse = "L'adresse est obligatoire";
      adresseValid = false;
    } else {
      adresseValid = true;
    }

    //Date debut validation
    console.log(itemState.DateDebut);
    if (itemState.DateDebut == null) {
      errors.DateDebut = "La sélection d'une date de début est obligatoire.";
      dateDebutValid = false;
    }
    if (itemState.DateDebut >= endTime) {
      errors.DateDebut = "La date du début est invalide.";
      dateDebutValid = false;
    } else if (itemState.DateDebut != null) {
      dateDebutValid = true;
    }

    //Date fin validation
    if (itemState.DateFin != null) {
      if (itemState.DateFin <= itemState.DateDebut) {
        errors.DateFin =
          "La date de fin doit être supérieur à la date du début.";
        dateFinValid = false;
      }
      if (itemState.DateFin >= endTime) {
        dateFinValid = false;
      }

      if (itemState.DateFin != null && item.DateFin < endTime) {
        dateFinValid = true;
      }
    } else {
      dateFinValid = true;
    }

    //Actif validation

    if (itemState.Actif == -1 || itemState.Actif == null) {
      errors.Actif = "La sélection d'un actif est obligatoire.";
      actifValid = false;
    } else {
      actifValid = true;
    }

    // console.debug(actifValid);
    // console.debug(typeValid);
    // console.debug(dateFinValid);
    // console.debug(idValid);
    // console.debug(dateDebutValid);
    // console.debug(nomValid);
    // console.debug(adresseValid);

    if (
      actifValid &&
      typeValid &&
      dateFinValid &&
      dateDebutValid &&
      idValid &&
      nomValid &&
      adresseValid
    ) {
      // console.log("holle");
      handleSaveItem();
      return errors;
    }
    return errors;
  };

  const handleSaveItem = async () => {
    try {
      await handleSaveItemParent(item, isEdit);
      setShowState(false);
      toast.success("Sauvegardé");
      if (!isEdit) {
        //setItemState({});
      }
    } catch (err) {
      setErrorMsg(err.response.data);
      console.log(err.response.data);
    }
  };

  const handleCloseModal = () => {
    setShowState(false);
    setErrorMsg("");
    setFormError({});
    resetValues(initialItemState);
  };

  const resetValues = (initialItemState) => {
    item.ID = initialItemState.ID;
    item.TypeID = initialItemState.TypeID;
    item.Nom = initialItemState.Nom;
    item.Adresse = initialItemState.Adresse;
    item.DateDebut = initialItemState.DateDebut;
    item.DateFin = initialItemState.DateFin;
    item.Actif = initialItemState.Actif;
    item.Note = initialItemState.Note;
    item.NecessiteConfirmation = initialItemState.NecessiteConfirmation;
  };

  // const handleClickMatiere = async (e) => {
  //   //console.log(e.target.id);
  //   //console.log(e.target.checked);
  //   if (e.target.checked) {
  //     itemState.SelectedMatieres.push(e.target.id);
  //   }
  //   else {
  //     const index = itemState.SelectedMatieres.indexOf(e.target.id);
  //     if (index > -1) { // only splice array when item is found
  //       itemState.SelectedMatieres.splice(index, 1); // 2nd parameter means remove one item only
  //     }
  //   }
  //   //console.log(itemState.SelectedMatieres);
  // };

  return (
    <>
      <div align="right">
        {isEdit ? (
          <Button
            variant="primary"
            size="sm"
            onClick={() => {
              setShowState(true);
              setErrorMsg("");
            }}
          >
            Modifier
          </Button>
        ) : (
          <Button
            variant="primary"
            onClick={() => {
              setShowState(true);
              setErrorMsg("");
            }}
          >
            Ajouter
          </Button>
        )}
      </div>

      <Modal show={showState} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Lieu de service</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={submitThis}>
            <Form.Group className="mb-3">
              <Form.Label>Type *</Form.Label>
              <Form.Select
                autoFocus
                defaultValue={item?.TypeID}
                onChange={(e) => (item.TypeID = e.target.value)}
                // readOnly={isEdit}
                // disabled={isEdit}
                as="select"
              >
                <option value="-1">Veuillez sélectionner une option</option>
                <option value="1">Écocentre</option>
                <option value="2">Secteur</option>
                <option value="3">Lieu spécial</option>
              </Form.Select>
              {formError.TypeID && (
                <span className="error">{formError.TypeID}</span>
              )}
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>ID *</Form.Label>
              <Form.Control
                defaultValue={item?.ID}
                onChange={(e) => (item.ID = e.target.value)}
                readOnly={isEdit}
              />
              {formError.ID && <span className="error">{formError.ID}</span>}
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Nom du lieu de service*</Form.Label>
              <Form.Control
                defaultValue={item?.Nom}
                onChange={(e) => (item.Nom = e.target.value)}
              />
              {formError.Nom && <span className="error">{formError.Nom}</span>}
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Adresse *</Form.Label>
              <Form.Control
                defaultValue={item?.Adresse}
                onChange={(e) => (item.Adresse = e.target.value)}
              />
              {formError.Adresse && (
                <span className="error">{formError.Adresse}</span>
              )}
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Date Début *</Form.Label>
              <Form.Control
                type="date"
                defaultValue={
                  item?.DateDebut
                    ? dayjs(item?.DateDebut).format("YYYY-MM-DD")
                    : item?.DateDebut
                }
                onChange={(e) => (item.DateDebut = e.target.value)}
              />
              {formError.DateDebut && (
                <span className="error">{formError.DateDebut}</span>
              )}
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Date Fin</Form.Label>
              <Form.Control
                type="date"
                defaultValue={
                  item?.DateFin
                    ? dayjs(item?.DateFin).format("YYYY-MM-DD")
                    : item?.DateFin
                }
                onChange={(e) =>
                  (item.DateFin = e.target.value !== "" ? e.target.value : null)
                }
              />
              {formError.DateFin && (
                <span className="error">{formError.DateFin}</span>
              )}
            </Form.Group>

            {/* <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
              <Form.Label>Matières</Form.Label>
              {matieresState.map((matiere) => (
                <div key={matiere.UID} className="mb-3">
                  <Form.Check
                    type='checkbox'
                    id={matiere.UID}
                    label={matiere.Nom}
                    onChange={handleClickMatiere}
                  />
                </div>
              ))}
            </Form.Group> */}

            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Actif *</Form.Label>
              <Form.Select
                defaultValue={item?.Actif}
                onChange={(e) => (item.Actif = Number(e.target.value))}
                as="select"
              >
                <option value={-1}>Veuillez sélectionner une option</option>
                <option value={0}>Non-actif</option>
                <option value={1}>Actif</option>
              </Form.Select>
              {formError.Actif && (
                <span className="error">{formError.Actif}</span>
              )}
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput33">
              <Form.Label>Confirmation</Form.Label>
              <Form.Check
                type="checkbox"
                label="Nécessite une confirmation pour une demande de levée"
                defaultChecked={item?.NecessiteConfirmation === 1}
                onChange={(e) =>
                  (item.NecessiteConfirmation = e.target.checked ? 1 : 0)
                }
              />

              {formError.NecessiteConfirmation && (
                <span className="error">{formError.NecessiteConfirmation}</span>
              )}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Note</Form.Label>
              <Form.Control
                //name="itemState.Volume"
                defaultValue={item?.Note}
                onChange={(e) => (item.Note = e.target.value)}
              />
              {formError.Note && (
                <span className="error">{formError.Note}</span>
              )}
              {errorMsg && (
                <Alert className="mt-4" variant="danger">
                  {errorMsg}
                </Alert>
              )}
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Retour
          </Button>
          <Button variant="primary" onClick={submitThis}>
            Sauvegarder
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
