import React, { useState, useEffect } from "react";
import styles from "./Demande.module.scss";
import { useNavigate, useSearchParams } from "react-router-dom";
import useApi from "../../hooks/useApi";
import useConst from "../../hooks/useConst";

import { Alert, Col, Form, Row, Card, Button, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import AnnulationModal from "../base-components/AnnulationModal";
import ConfirmationModal from "../base-components/ConfirmationModal";

// DateTimePicker
import dayjs from "dayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// DateTimePicker

/********************
 * Default Function *
 ********************/
export default function DemandeView() {
  // useNavigate
  const navigate = useNavigate();

  // useConst
  const { userRole, typeDemande, getTypeDemande, etatDemande, getEtatDemande } =
    useConst();

  // useApi
  const {
    apiGetUserRole,
    apiGetDemande,
    apiGetDemandeSuivante,
    apiAnnulerDemande,
    apiApprouverDemande,
    apiPlanifierDemande,
    apiRefuserDemande,
    apiConfirmerDemande,
    apiCompleterDemande,
    apiRefuserRamassage,
    apiRamasserDemande,
    apiCompleteDemandeLevee,
    apiGetBlobImage,
    apiModifierNumCamion,
    apiModifierDemandeCompletee,
  } = useApi();

  // useSearchParams
  const [searchParams] = useSearchParams();

  // useState
  const [isLoading, setIsLoading] = useState(false);
  const [getSuccesMsg, setSuccesMsg] = useState();
  const [getErrorMsg, setErrorMsg] = useState();
  const [getDemande, setDemande] = useState({});
  const [getDemandeSuivante, setDemandeSuivante] = useState();
  const [getIsSaved, setIsSaved] = useState(false);
  const [getUserRole, setUserRole] = useState(userRole.NoAccess);
  const [getNoCamion, setNoCamion] = useState("");
  const [getPoidCamion, setPoidCamion] = useState("");
  const [getNoBilletTraitement, setNoBilletTraitement] = useState("");
  const [getContratTransportInfo, setContratTransportInfo] = useState();
  const [getContratTraitementInfo, setContratTraitementInfo] = useState();
  const [getContratTraitement, setContratTraitement] = useState();

  // useEffect
  useEffect(() => {
    apiGetUserRole().then((role) => {
      setUserRole(role);
    });

    loadDemande();

    loadDemandeSuivante();
  }, []);

  // loadDemande
  async function loadDemande() {
    try {
      setIsLoading(true);
      const res = await apiGetDemande(searchParams.get("demande"));

      setDemande(res.data);

      if (res.data?.NumUniteVehicule !== "") {
        setNoCamion(res.data?.NumUniteVehicule);
      }

      if (res.data?.PoidsCentreTraitement !== "") {
        setPoidCamion(res.data?.PoidsCentreTraitement);
      }

      if (res.data?.NumBilletCentreTraitement !== "") {
        setNoBilletTraitement(res.data?.NumBilletCentreTraitement);
      }

      setContratTransportInfo(
        `Contrat : ${res.data?.ContratTransportFK?.NumContrat}\nTransporteur : ${res.data?.ContratTransportFK?.Fournisseur?.Nom}\nContact : ${res.data?.ContratTransportFK?.Nom}`
      );

      setContratTraitementInfo(
        `Contrat : ${res.data?.ContratTraitementFK?.NumContrat}\nTransporteur : ${res.data?.ContratTraitementFK?.Fournisseur?.Nom}\nContact : ${res.data?.ContratTraitementFK?.Nom}`
      );
    } catch (err) {
      console.error(err);
      toast.error(
        "Une erreur est survenue pendant le chargement de la demande."
      );
    } finally {
      setIsLoading(false);
    }
  }

  // loadDemandeSuivante
  async function loadDemandeSuivante() {
    try {
      setIsLoading(true);
      const res = await apiGetDemandeSuivante(searchParams.get("demande"));

      setDemandeSuivante(res.data);
    } catch (err) {
      toast.error(
        "Une erreur est survenue pendant le chargement de la demande."
      );
    } finally {
      setIsLoading(false);
    }
  }

  // Actions des boutons
  function handleRetourClick() {
    navigate("/");
  }

  async function handleAnnulerDemandeClick(raisonAnnulation) {
    try {
      clearMessage();
      setIsLoading(true);
      await apiAnnulerDemande(getDemande.UID, raisonAnnulation);
      setIsSaved(true);
      setSuccesMsg("La demande a été annulée.");
    } catch (err) {
      setErrorMsg(err.response.data);
    } finally {
      setIsLoading(false);
    }
  }

  async function handleRefuserDemandeClick(refusRaison) {
    try {
      clearMessage();
      setIsLoading(true);
      await apiRefuserDemande(getDemande.UID, refusRaison);
      setIsSaved(true);
      setSuccesMsg("La demande a été refusée.");
    } catch (err) {
      setErrorMsg(err.response.data);
    } finally {
      setIsLoading(false);
    }
  }

  async function handleApprouverDemandeClick() {
    try {
      clearMessage();
      setIsLoading(true);
      await apiApprouverDemande(getDemande.UID);
      setIsSaved(true);
      setSuccesMsg("La demande a été approuvée.");
    } catch (err) {
      setErrorMsg(err.response.data);
    } finally {
      setIsLoading(false);
    }
  }

  async function handlePlanifierClick() {
    try {
      clearMessage();
      setIsLoading(true);
      await apiPlanifierDemande(getDemande.UID);
      setIsSaved(true);
      setSuccesMsg("La demande a été envoyée.");
    } catch (err) {
      setErrorMsg(err.response.data);
    } finally {
      setIsLoading(false);
    }
  }

  async function handleConfirmerDemandeClick() {
    try {
      clearMessage();
      setIsLoading(true);
      await apiConfirmerDemande(getDemande.UID);
      setIsSaved(true);
      setSuccesMsg("La demande a été confirmée.");
    } catch (err) {
      setErrorMsg(err.response.data);
    } finally {
      setIsLoading(false);
    }
  }

  async function handleCompleterDemandeClick() {
    try {
      clearMessage();
      setIsLoading(true);
      await apiCompleterDemande(getDemande.UID);
      setIsSaved(true);
      setSuccesMsg("La demande a été complétée.");
    } catch (err) {
      setErrorMsg(err.response.data);
    } finally {
      setIsLoading(false);
    }
  }

  async function handleEchangerClick() {
    clearMessage();

    const params = {
      action: "echange",
      demande: getDemande,
    };

    navigate("/demande-next?t=echange", { state: { params } });
  }

  async function handleDeplacerClick() {
    clearMessage();

    const params = {
      action: "deplacement",
      demande: getDemande,
    };

    navigate("/demande-next?t=deplacement", { state: { params } });
  }

  async function handleDeplacerDemandeNonCompleteeClick() {
    clearMessage();

    const params = {
      demande: getDemande,
    };

    navigate("/demande-next?t=deplacementDemandeNonCompletee", {
      state: { params },
    });
  }

  async function handleLeverClick() {
    clearMessage();

    const params = {
      demande: getDemande,
    };

    navigate("/demande-next?t=levee", { state: { params } });
  }

  async function handleRefuserRamassageClick(raisonRefus) {
    try {
      clearMessage();
      setIsLoading(true);
      await apiRefuserRamassage(getDemande.UID, raisonRefus);
      setIsSaved(true);
      setSuccesMsg("La demande de levée a été annulée.");
    } catch (err) {
      setErrorMsg("Une erreur est survenue. Veuillez ré-essayez.");
    } finally {
      setIsLoading(false);
    }
  }

  async function handleRamasserClick() {
    try {
      clearMessage();
      setIsLoading(true);
      await apiRamasserDemande(getDemande.UID, getNoCamion);
      setIsSaved(true);
      setSuccesMsg("Le conteneur a été ramassé.");
    } catch (err) {
      setErrorMsg(err.response.data);
    } finally {
      setIsLoading(false);
    }
  }

  async function handleCompleterLeveeClick() {
    try {
      clearMessage();
      setIsLoading(true);
      await apiCompleteDemandeLevee(
        getDemande.UID,
        getPoidCamion,
        getNoBilletTraitement
      );
      setIsSaved(true);
      setSuccesMsg("Le conteneur a été déposé au centre de traitement.");
    } catch (err) {
      setErrorMsg(err.response.data);
    } finally {
      setIsLoading(false);
    }
  }

  async function handleModifierNumCamionClick(numCamion, isChecked) {
    try {
      clearMessage();
      setIsLoading(true);
      await apiModifierNumCamion(getDemande.UID, numCamion, isChecked);
      setIsSaved(true);
      setSuccesMsg("Le numéro du camion a été modifié.");
    } catch (err) {
      setErrorMsg(err.response.data);
    } finally {
      setIsLoading(false);
    }
  }

  async function handleSauvegarderClick() {
    try {
      clearMessage();
      setIsLoading(true);
      await apiModifierDemandeCompletee(
        getDemande.UID,
        getNoCamion,
        getPoidCamion,
        getNoBilletTraitement
      );
      setIsSaved(true);
      setSuccesMsg("Vos modifications ont été sauvegardées.");
    } catch (err) {
      setErrorMsg(err.response.data);
    } finally {
      setIsLoading(false);
    }
  }

  // Fonctions
  function clearMessage() {
    setSuccesMsg();
    setErrorMsg();
  }

  // Numero du camion de transport
  function handleNoCamionChange(e) {
    setNoCamion(e.target.value);
  }

  // Numero du camion de transport
  function handlePoidCamionChange(e) {
    setPoidCamion(e.target.value);
  }

  // Numero du billet de traitement
  function handleNoBilletTraitementChange(e) {
    setNoBilletTraitement(e.target.value);
  }

  async function handleImageDownloadClick(filename) {
    try {
      var res = await apiGetBlobImage(filename);

      // Create link and auto-click to download image
      const type = res.headers["content-type"];
      const blob = new Blob([res.data], { type: type, encoding: "UTF-8" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = filename.substring(39, filename.length);
      link.click();
    } catch (err) {
      console.error(err);
      toast.error(
        "Une erreur est survenue. L'image ne peut pas être téléchargée."
      );
    }
  }

  /*************
   * Component *
   *************/
  return (
    <>
      <div className={styles.FormAdvanced}>
        {/* <PageHeader titles="Nouvelle demande de livraison" active="Form Advanced" items={['Forms']} /> */}

        <Form method="post" className="card">
          <Row>
            <Col xl={6} md={12}>
              <Card.Header>
                <Card.Title as="h3">
                  Gestion de la demande {getDemande.NoDemande}
                </Card.Title>
              </Card.Header>

              <Card.Body>
                <Form.Group>
                  <Form.Label>{`Type de demande : ${getTypeDemande(
                    getDemande.Type
                  )}`}</Form.Label>
                </Form.Group>
                {getDemande.Type === typeDemande.CollecteParLienWeb && (
                  <p style={{ fontWeight: "bold" }}>
                    Lien web:{" "}
                    <a
                      href={getDemande?.ContratTransportFK?.Url}
                      target="_blank"
                    >
                      {getDemande?.ContratTransportFK?.Url}
                    </a>
                  </p>
                )}
                <Form.Group>
                  <Form.Label>{`État de la demande : ${getEtatDemande(
                    getDemande.Etat
                  )}`}</Form.Label>
                </Form.Group>

                <Form.Group>
                  <Form.Label>{`Crée par : ${getDemande.CreePar}`}</Form.Label>
                </Form.Group>

                {getDemande.Etat === etatDemande.Annulee && (
                  <>
                    <Form.Group>
                      <Form.Label>{`Motif d'annulation: ${getDemande?.RaisonAnnulation}`}</Form.Label>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>{`Annulée par : ${getDemande?.AnnuleePar}`}</Form.Label>
                    </Form.Group>
                  </>
                )}
                <Form.Group>
                  <Form.Label>Type de lieu de service</Form.Label>
                  <Form.Control
                    type="text"
                    defaultValue={getDemande?.TypeSiteViewDemande}
                    readOnly
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Lieu de service</Form.Label>
                  <Form.Control
                    type="text"
                    defaultValue={getDemande?.NomSiteViewDemande}
                    readOnly
                  />
                </Form.Group>

                {/*******
                 * Place *
                 *********/}

                <Form.Group>
                  <Form.Label>Place</Form.Label>
                  <Row>
                    <Col>
                      <Form.Control
                        type="text"
                        defaultValue={getDemande?.NomPlaceViewDemande}
                        readOnly
                      />
                    </Col>
                    {getDemande?.PlaceFK?.Latitude &&
                      getDemande?.PlaceFK?.Longitude && (
                        <Col>
                          <a
                            href={`https://www.google.com/maps/search/?api=1&query=${getDemande?.PlaceFK?.Latitude},${getDemande?.PlaceFK?.Longitude}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <Button>Voir sur la map</Button>
                          </a>
                        </Col>
                      )}
                  </Row>
                </Form.Group>

                <Form.Group>
                  <Form.Label>Matière</Form.Label>
                  {getDemande.Etat === etatDemande.EnService && (
                    <Form.Control
                      type="text"
                      defaultValue={getDemande?.Matiere?.Nom}
                      readOnly
                    />
                  )}
                  {getDemande.Etat !== etatDemande.EnService && (
                    <Form.Control
                      type="text"
                      defaultValue={getDemande?.MatiereActuelle?.Nom}
                      readOnly
                    />
                  )}
                </Form.Group>

                <Form.Group>
                  <Form.Label>Volume</Form.Label>
                  <Form.Control
                    type="text"
                    defaultValue={getDemande?.Volume?.Nom}
                    readOnly
                  />
                </Form.Group>

                {getDemande.Type === typeDemande.Deplacement &&
                  getDemande.Etat !== etatDemande.EnService && (
                    <>
                      <Form.Group>
                        <Form.Label>
                          Déplacement vers le lieu de service
                        </Form.Label>
                        <Form.Control
                          type="text"
                          defaultValue={getDemande?.SiteDestinationFK?.Nom}
                          readOnly
                        />
                      </Form.Group>

                      <Form.Group>
                        <Form.Label>Déplacement vers la place</Form.Label>
                        <Row>
                          <Col>
                            <Form.Control
                              type="text"
                              defaultValue={getDemande?.PlaceDestination}
                              readOnly
                            />
                          </Col>
                          {getDemande?.PlaceDestinationFK?.Latitude &&
                            getDemande?.PlaceDestinationFK?.Longitude && (
                              <Col>
                                <a
                                  href={`https://www.google.com/maps/search/?api=1&query=${getDemande?.PlaceDestinationFK?.Latitude},${getDemande?.PlaceDestinationFK?.Longitude}`}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <Button>Voir sur la map</Button>
                                </a>
                              </Col>
                            )}
                        </Row>
                      </Form.Group>
                    </>
                  )}

                {/* Date et heure */}
                <Form.Group className="mb-3">
                  <Form.Label>Date et Heure</Form.Label>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale="fr-ca"
                  >
                    <DateTimePicker
                      value={dayjs(getDemande?.HeurePlannifiee)}
                      readOnly={true}
                    />
                  </LocalizationProvider>
                </Form.Group>
                {/* Information complementaire */}
                <Form.Group>
                  <Form.Label>Information complémentaire</Form.Label>
                  <textarea
                    className="form-control mb-4"
                    placeholder=""
                    defaultValue={getDemande?.InfoComplementaire}
                    rows={4}
                    readOnly={true}
                  ></textarea>
                </Form.Group>
                {/* Numéro du camion de transport */}
                {(((getDemande.Type === typeDemande.Levee ||
                  getDemande.Type === typeDemande.Echange) &&
                  getDemande.Etat === etatDemande.EnAttenteTransportVersSite) ||
                  (getDemande.Etat === etatDemande.Completee &&
                    (getUserRole === userRole.Administrateur ||
                      getUserRole === userRole.Superviseur))) && (
                  <Form.Group>
                    <Form.Label>Numéro du camion *</Form.Label>
                    <Form.Control
                      type="text"
                      value={getNoCamion}
                      onChange={handleNoCamionChange}
                    />
                  </Form.Group>
                )}
                {/* Numéro du camion de transport READ ONLY */}
                {getDemande?.NumUniteVehicule &&
                  !(
                    getDemande.Etat === etatDemande.Completee &&
                    (getUserRole === userRole.Administrateur ||
                      getUserRole === userRole.Superviseur)
                  ) && (
                    <Form.Group>
                      <Form.Label>Numéro du camion</Form.Label>
                      <Form.Control
                        type="text"
                        readOnly={true}
                        value={getDemande?.NumUniteVehicule}
                      />
                    </Form.Group>
                  )}
                {/* Poid du camion de transport au centre de traitement */}
                {/* Peut être modifié sur une demande complétée par le superviseur */}
                {(getDemande.Etat ===
                  etatDemande.EnTransitVersCentreTraitement ||
                  (getDemande.Etat === etatDemande.Completee &&
                    (getUserRole === userRole.Administrateur ||
                      getUserRole === userRole.Superviseur))) && (
                  <Form.Group>
                    <Form.Label>Poids du chargement en Kg *</Form.Label>
                    <Form.Control
                      type="number"
                      value={getPoidCamion}
                      onChange={handlePoidCamionChange}
                    />
                  </Form.Group>
                )}
                {/* Poid du camion de transport au centre de traitement */}
                {getDemande?.PoidsCentreTraitement &&
                  !(
                    getDemande.Etat === etatDemande.Completee &&
                    (getUserRole === userRole.Administrateur ||
                      getUserRole === userRole.Superviseur)
                  ) && (
                    <Form.Group>
                      <Form.Label>Poids du chargement en Kg</Form.Label>
                      <Form.Control
                        type="text"
                        readOnly={true}
                        value={getDemande?.PoidsCentreTraitement}
                      />
                    </Form.Group>
                  )}
                {/* Numéro du billet du centre de traitement */}
                {(getDemande.Etat ===
                  etatDemande.EnTransitVersCentreTraitement ||
                  (getDemande.Etat === etatDemande.Completee &&
                    (getUserRole === userRole.Administrateur ||
                      getUserRole === userRole.Superviseur))) && (
                  <Form.Group>
                    <Form.Label>Numéro du billet de pesée *</Form.Label>
                    <Form.Control
                      type="text"
                      value={getNoBilletTraitement}
                      onChange={handleNoBilletTraitementChange}
                    />
                  </Form.Group>
                )}
                {/* Numéro du billet du centre de traitement READ ONLY */}
                {getDemande?.NumBilletCentreTraitement &&
                  !(
                    getDemande.Etat === etatDemande.Completee &&
                    (getUserRole === userRole.Administrateur ||
                      getUserRole === userRole.Superviseur)
                  ) && (
                    <Form.Group>
                      <Form.Label>Numéro du billet de pesée</Form.Label>
                      <Form.Control
                        type="text"
                        readOnly={true}
                        value={getDemande?.NumBilletCentreTraitement}
                      />
                    </Form.Group>
                  )}
              </Card.Body>
            </Col>

            <Col xl={6} md={12}>
              <Card.Header>
                <Card.Title as="h3">&nbsp;</Card.Title>
              </Card.Header>

              <Card.Body>
                <Form.Group>
                  <Form.Label>Adresse du lieu de service</Form.Label>
                  <textarea
                    readOnly
                    className="form-control mb-4"
                    placeholder=""
                    defaultValue={getDemande?.SiteAdresseViewDemande}
                    rows={2}
                  ></textarea>
                </Form.Group>

                <Form.Group>
                  <Form.Label>Note du lieu de service</Form.Label>
                  <textarea
                    readOnly
                    className="form-control mb-4"
                    placeholder=""
                    defaultValue={getDemande?.ContactPlace}
                    rows={2}
                  ></textarea>
                </Form.Group>

                <Form.Group>
                  <Form.Label>Note de la place</Form.Label>
                  <textarea
                    readOnly
                    className="form-control mb-4"
                    placeholder=""
                    defaultValue={getDemande?.NotePlace}
                    rows={2}
                  ></textarea>
                </Form.Group>

                <Form.Group>
                  <Form.Label>Contrat de transport</Form.Label>
                  <textarea
                    readOnly
                    className="form-control mb-4"
                    placeholder=""
                    defaultValue={getContratTransportInfo}
                    rows={3}
                  ></textarea>
                </Form.Group>

                {getContratTraitement && (
                  <Form.Group>
                    <Form.Label>Contrat de traitement</Form.Label>
                    <textarea
                      readOnly
                      className="form-control mb-4"
                      placeholder=""
                      defaultValue={getContratTraitementInfo}
                      rows={3}
                    ></textarea>
                  </Form.Group>
                )}

                {getDemande?.Attachements?.length > 0 && (
                  <Form.Group>
                    <Form.Label>Images</Form.Label>
                    {getDemande?.Attachements?.map((image) => (
                      <Button
                        key={image.UID}
                        variant="outline-primary"
                        size="sm"
                        onClick={() =>
                          handleImageDownloadClick(image.NomFichier)
                        }
                      >
                        {image.NomFichier.substring(
                          39,
                          image.NomFichier.length
                        )}
                      </Button>
                    ))}
                  </Form.Group>
                )}
              </Card.Body>
            </Col>

            <Col xl={12} md={12}>
              <div align="center">
                <Row>
                  {getErrorMsg && (
                    <Col>
                      <Alert className="mt-4" variant="danger">
                        {getErrorMsg}
                      </Alert>
                    </Col>
                  )}
                  {getSuccesMsg && (
                    <Col>
                      <Alert variant="success">{getSuccesMsg}</Alert>
                    </Col>
                  )}
                </Row>

                <Row className="mt-4">
                  <Col>
                    <Button
                      variant="secondary"
                      className="mt-4 me-1 mb-0"
                      style={{ width: "160px" }}
                      onClick={handleRetourClick}
                    >
                      Retour
                    </Button>
                    {isLoading && <Spinner />}
                    {!getIsSaved &&
                      !isLoading &&
                      (getUserRole === userRole.Administrateur ||
                        getUserRole === userRole.Superviseur ||
                        getUserRole === userRole.Prepose ||
                        getUserRole === userRole.Demandeur) &&
                      (getDemande.Etat === etatDemande.EnAttenteApprobation ||
                        getDemande.Etat === etatDemande.Planifiee) && (
                        <AnnulationModal
                          instruction="Veuillez saisir le motif de l'annulation de la demande :"
                          handleSave={handleAnnulerDemandeClick}
                        >
                          Annuler la demande
                        </AnnulationModal>
                      )}
                    {/* Refuser l'approbation de la demande */}
                    {!getIsSaved &&
                      !isLoading &&
                      (getUserRole === userRole.Administrateur ||
                        getUserRole === userRole.Superviseur) &&
                      getDemande.Etat === etatDemande.EnAttenteApprobation && (
                        <AnnulationModal
                          instruction="Veuillez saisir le motif du refus de la demande :"
                          handleSave={handleRefuserDemandeClick}
                        >
                          Refuser
                        </AnnulationModal>
                      )}
                    {/* Annuler la demande du camion vers le centre de traitement */}
                    {!getIsSaved &&
                      !isLoading &&
                      (getUserRole === userRole.Administrateur ||
                        getUserRole === userRole.Superviseur) &&
                      getDemande.Type === typeDemande.Levee &&
                      getDemande.Etat ===
                        etatDemande.EnTransitVersCentreTraitement && (
                        <AnnulationModal
                          instruction="Veuillez saisir le motif de l'annulation de la demande :"
                          handleSave={handleAnnulerDemandeClick}
                        >
                          Annuler le camion
                        </AnnulationModal>
                      )}

                    {/* Demande de déplacement intra-site lors d'un échange/levé non complété  */}
                    {!getIsSaved &&
                      !isLoading &&
                      (getUserRole === userRole.Administrateur ||
                        getUserRole === userRole.Superviseur ||
                        getUserRole === userRole.Prepose ||
                        getUserRole === userRole.Demandeur) &&
                      (getDemande.Type === typeDemande.Echange ||
                        getDemande.Type === typeDemande.Levee ||
                        getDemande.Type === typeDemande.EchangeLevee) &&
                      (getDemande.Etat === etatDemande.EnAttenteConfirmation ||
                        getDemande.Etat ===
                          etatDemande.EnAttenteTransportVersSite) &&
                      getDemande?.TypeSiteViewDemande === "Écocentre" && (
                        <Button
                          variant="primary"
                          className="mt-4 ms-1 mb-0"
                          style={{ width: "180px" }}
                          onClick={handleDeplacerDemandeNonCompleteeClick}
                        >
                          Déplacer intra-site
                        </Button>
                      )}

                    {/* Modifier le numéro du camion vers le centre de traitement */}
                    {!getIsSaved &&
                      !isLoading &&
                      (getUserRole === userRole.Administrateur ||
                        getUserRole === userRole.Superviseur) &&
                      getDemande.Etat ===
                        etatDemande.EnTransitVersCentreTraitement && (
                        <AnnulationModal
                          showChecked={true}
                          checkText="Envoyer un courriel de notification au centre de traitement"
                          isCheckedByDefault={true}
                          instruction="Veuillez saisir le nouveau numéro du camion :"
                          errorMsg="Veuillez saisir le nouveau numéro du camion"
                          handleSave={handleModifierNumCamionClick}
                        >
                          Modifier num. camion
                        </AnnulationModal>
                      )}
                    {/* Approuver la demande */}
                    {!getIsSaved &&
                      !isLoading &&
                      (getUserRole === userRole.Administrateur ||
                        getUserRole === userRole.Superviseur) &&
                      getDemande.Etat === etatDemande.EnAttenteApprobation && (
                        <Button
                          variant="primary"
                          className="mt-4 ms-1 mb-0"
                          style={{ width: "180px" }}
                          onClick={handleApprouverDemandeClick}
                        >
                          Approuver
                        </Button>
                      )}

                    {/* Bouton apparait seulement si la date-heure de planification est depasee */}
                    {!getIsSaved &&
                      !isLoading &&
                      (getUserRole === userRole.Administrateur ||
                        getUserRole === userRole.Superviseur) &&
                      getDemande.Etat === etatDemande.Planifiee &&
                      dayjs().isAfter(getDemande.HeurePlannifiee) && (
                        <>
                          <Button
                            variant="primary"
                            className="mt-4 ms-1 mb-0"
                            style={{ width: "340px" }}
                            onClick={handlePlanifierClick}
                          >
                            Envoyer la demande au fournisseur maintenant
                          </Button>
                        </>
                      )}

                    {/* Fournisseur refuse la prise en charge */}
                    {!getIsSaved &&
                      !isLoading &&
                      (getUserRole === userRole.Administrateur ||
                        getUserRole === userRole.Superviseur ||
                        getUserRole === userRole.Prepose ||
                        getUserRole === userRole.Fournisseur) &&
                      getDemande.Etat === etatDemande.EnAttenteConfirmation && (
                        <AnnulationModal
                          instruction="Veuillez saisir le motif du refus de la demande :"
                          handleSave={handleRefuserDemandeClick}
                        >
                          Refuser
                        </AnnulationModal>
                      )}
                    {/* Fournisseur confirme la prise en charge */}
                    {!getIsSaved &&
                      !isLoading &&
                      (getUserRole === userRole.Administrateur ||
                        getUserRole === userRole.Superviseur ||
                        getUserRole === userRole.Prepose ||
                        getUserRole === userRole.Fournisseur) &&
                      getDemande.Etat === etatDemande.EnAttenteConfirmation && (
                        <>
                          <ConfirmationModal
                            instruction="Êtes-vous sûr de vouloir confirmer cette demande?"
                            action="Confirmer"
                            handleSave={handleConfirmerDemandeClick}
                          >
                            Confirmer
                          </ConfirmationModal>
                        </>
                      )}
                    {/* Demande de livraison */}
                    {!getIsSaved &&
                      !isLoading &&
                      (getUserRole === userRole.Administrateur ||
                        getUserRole === userRole.Superviseur ||
                        getUserRole === userRole.Prepose ||
                        getUserRole === userRole.Fournisseur) &&
                      (getDemande.Type === typeDemande.Livraison ||
                        getDemande.Type === typeDemande.CollecteParLienWeb ||
                        getDemande.Type ===
                          typeDemande.CollecteParLienCourriel ||
                        getDemande.Type === typeDemande.Deplacement) &&
                      getDemande.Etat ===
                        etatDemande.EnAttenteTransportVersSite && (
                        <>
                          <ConfirmationModal
                            instruction="Êtes-vous sûr de vouloir compléter cette demande?"
                            action="Compléter"
                            handleSave={handleCompleterDemandeClick}
                          >
                            Compléter
                          </ConfirmationModal>
                        </>
                      )}
                    {/* Demande de levée */}
                    {/* Demande d'échange */}
                    {/* Fournisseur refuse car trop lourd */}
                    {!getIsSaved &&
                      !isLoading &&
                      (getUserRole === userRole.Administrateur ||
                        getUserRole === userRole.Superviseur ||
                        getUserRole === userRole.Prepose ||
                        getUserRole === userRole.Fournisseur) &&
                      (getDemande.Type === typeDemande.Echange ||
                        getDemande.Type === typeDemande.Levee) &&
                      getDemande.Etat ===
                        etatDemande.EnAttenteTransportVersSite && (
                        <AnnulationModal
                          instruction="Veuillez saisir le motif du refus du ramassage :"
                          handleSave={handleRefuserRamassageClick}
                        >
                          Refuser le ramassage
                        </AnnulationModal>
                      )}
                    {/* Demande de levée */}
                    {/* Demande d'échange */}
                    {/* Fournisseur ramasse le conteneur */}
                    {!getIsSaved &&
                      !isLoading &&
                      (getUserRole === userRole.Administrateur ||
                        getUserRole === userRole.Superviseur ||
                        getUserRole === userRole.Prepose ||
                        getUserRole === userRole.Fournisseur) &&
                      (getDemande.Type === typeDemande.Echange ||
                        getDemande.Type === typeDemande.Levee) &&
                      getDemande.Etat ===
                        etatDemande.EnAttenteTransportVersSite && (
                        <>
                          <ConfirmationModal
                            instruction="Êtes-vous sûr de vouloir ramasser?"
                            action="Ramasser"
                            handleSave={handleRamasserClick}
                          >
                            Ramasser
                          </ConfirmationModal>
                        </>
                      )}
                    {/* Fournisseur dépose le conteneur au centre de traitement */}
                    {!getIsSaved &&
                      !isLoading &&
                      (getUserRole === userRole.Administrateur ||
                        getUserRole === userRole.Superviseur ||
                        getUserRole === userRole.Prepose ||
                        getUserRole === userRole.Fournisseur) &&
                      getDemande.Etat ===
                        etatDemande.EnTransitVersCentreTraitement && (
                        <>
                          <ConfirmationModal
                            instruction="Êtes-vous sûr de vouloir compléter cette demande?"
                            action="Compléter"
                            handleSave={handleCompleterLeveeClick}
                          >
                            Compléter
                          </ConfirmationModal>
                        </>
                      )}

                    {/* Fournisseur dépose le conteneur au centre de traitement */}
                    {!getIsSaved &&
                      !isLoading &&
                      (getUserRole === userRole.Administrateur ||
                        getUserRole === userRole.Superviseur) &&
                      getDemande.Etat === etatDemande.Completee && (
                        <>
                          <ConfirmationModal
                            instruction="Êtes-vous sûr de vouloir sauvegarder vos modifications?"
                            action="Sauvegarder"
                            handleSave={handleSauvegarderClick}
                          >
                            Sauvegarder
                          </ConfirmationModal>
                        </>
                      )}

                    {/* Boutons Next Demande */}
                    {!isLoading &&
                      (getUserRole === userRole.Administrateur ||
                        getUserRole === userRole.Superviseur ||
                        getUserRole === userRole.Prepose ||
                        getUserRole === userRole.Demandeur) &&
                      getDemande.Etat === etatDemande.EnService &&
                      getDemandeSuivante === null && (
                        <>
                          <Button
                            variant="primary"
                            className="mt-4 ms-1 mb-0"
                            style={{ width: "180px" }}
                            onClick={handleEchangerClick}
                          >
                            Échanger
                          </Button>
                          {getUserRole !== userRole.Demandeur && (
                            <Button
                              variant="primary"
                              className="mt-4 ms-1 mb-0"
                              style={{ width: "180px" }}
                              onClick={handleDeplacerClick}
                            >
                              Déplacer
                            </Button>
                          )}
                          <Button
                            variant="primary"
                            className="mt-4 ms-1 mb-0"
                            style={{ width: "180px" }}
                            onClick={handleLeverClick}
                          >
                            Lever (finale)
                          </Button>
                        </>
                      )}
                  </Col>
                </Row>
              </div>
              <br />
              <br />
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
}
