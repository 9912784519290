import styles from "./Places.module.scss";
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import dayjs from "dayjs";
import DataTable from "../base-components/DataTableBase";
import EditPlace from "./EditPlace";

export default function Places() {
  const [getIsDataLoading, setIsDataLoading] = useState(true);

  const location = useLocation();
  const [dataState, setDataState] = useState();

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_APIM_BASE_URL}/GetPlaces?UID=${location.state.UID}&showInactive=true`
      ) //Returning pledges using a get-query
      .then((response) => {
        // Data retrieval and processing
        //console.log(response.data);
        setDataState(response.data);
        setIsDataLoading(false);
      })
      .catch((error) => {
        // If the query fails, an error will be displayed on the terminal.
        console.error(error);
      });
  }, []);

  const handleSaveItem = async (item, isEdit) => {
    if (isEdit) {
      const res = await axios.put("EditPlace", item);
      const itemInArray = dataState.find((q) => q.UID === res.data.UID);
      dataState[dataState.indexOf(itemInArray)] = res.data;
      setDataState([...dataState]);
      return true;
    } else {
      item.Site_UID = location.state.UID;
      const res = await axios.post("AddPlace", item);
      setDataState(dataState.concat(res.data));
      return true;
    }
  };

  const columns = [
    // {
    //   name: "ID",
    //   selector: row => row.ID,
    //   sortable: true,
    // },
    {
      name: "No. Place",
      wrap: true,
      selector: (row) => row.Code,
      sortable: true,
    },
    {
      name: "Description",
      wrap: true,
      selector: (row) => row.Description,
      sortable: false,
    },
    {
      name: "Date Début",
      wrap: true,
      selector: (row) =>
        row.DateDebut
          ? dayjs(row.DateDebut).format("YYYY-MM-DD")
          : row.DateDebut,
      sortable: false,
    },
    {
      name: "Date Fin",
      wrap: true,
      selector: (row) =>
        row.DateFin ? dayjs(row.DateFin).format("YYYY-MM-DD") : row.DateFin,
      sortable: false,
    },
    {
      name: "Latitude",
      wrap: true,
      selector: (row) => row.Latitude,
      sortable: false,
    },
    {
      name: "Longitude",
      wrap: true,
      selector: (row) => row.Longitude,
      sortable: false,
    },
    {
      name: "Map",
      wrap: true,
      selector: (row) =>
        row.Latitude &&
        row.Longitude && (
          <a
            href={`https://www.google.com/maps/search/?api=1&query=${row.Latitude},${row.Longitude}`}
            target="_blank"
            rel="noreferrer"
          >
            Map
          </a>
        ),
    },
    {
      name: "Note",
      wrap: true,
      selector: (row) => row.Note,
      sortable: false,
    },
    {
      name: "Actif?",
      wrap: true,
      selector: (row) => (row.Actif === 1 ? "Actif" : "Non actif"),
    },
    {
      button: true,
      cell: (record) => (
        <EditPlace
          isEdit={true}
          item={Object.assign({}, record)}
          handleSaveItemParent={handleSaveItem}
        />
      ),
    },
  ];

  return (
    <div className={styles.Matieres}>
      <h1 align="center">Places de {location.state.Nom}</h1>

      <div align="right">
        <EditPlace
          isEdit={false}
          item={{}}
          handleSaveItemParent={handleSaveItem}
        />
      </div>

      <br />
      <div>
        <DataTable
          title=""
          columns={columns}
          data={dataState}
          progressPending={getIsDataLoading}
        />
      </div>
    </div>
  );
}
