import { useState } from "react";
import { Alert, Button, Modal, Row, Col, Form } from "react-bootstrap";

export default function AnnulationModal(props) {
  const [getShowModal, setShowModal] = useState(false);
  const [getRaison, setRaison] = useState();
  const [getErrorMsg, setErrorMsg] = useState();
  const [getIsChecked, setIsChecked] = useState(props.isCheckedByDefault);

  async function handleSaveClick() {
    setErrorMsg();

    if (!getRaison) {
      setErrorMsg(props.errorMsg ?? "Veuillez saisir le motif.");
    } else {
      props.handleSave(getRaison, getIsChecked);
      setShowModal(false);
    }
  }

  function handleCheckedClick(e) {
    if (e.target.checked === true) {
      setIsChecked(true);
    } else {
      setIsChecked(false);
    }
  }

  return (
    <>
      <Button
        variant="primary"
        className="mt-4 ms-1 mb-0"
        style={{ width: props.width ?? "180px" }}
        onClick={() => setShowModal(true)}
      >
        {props.children}
      </Button>

      <Modal
        show={getShowModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <Row>
            <Col>
              <p>{props.instruction}</p>
              <textarea
                className="form-control mb-4"
                onChange={(e) => setRaison(e.target.value)}
                rows={2}
              />
            </Col>
          </Row>
          {props.showChecked && (
            <Row>
              <Col>
                <Form>
                  <Form.Check
                    type="checkbox"
                    label={props.checkText}
                    checked={getIsChecked}
                    onChange={handleCheckedClick}
                  />
                </Form>
              </Col>
            </Row>
          )}

          {getErrorMsg && (
            <Row>
              <Col>
                <Alert className="mt-4" variant="danger">
                  {getErrorMsg}
                </Alert>
              </Col>
            </Row>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Row>
            <Col>
              <Button variant="secondary" onClick={() => setShowModal(false)}>
                Retour
              </Button>
            </Col>
            <Col>
              <Button onClick={handleSaveClick}>Sauvegarder</Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    </>
  );
}
