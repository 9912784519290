import styles from "./FournisseursUtilisateurs.module.scss";
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import DataTable from "../base-components/DataTableBase";
import EditFournisseurUtilisateur from "./EditFournisseurUtilisateur";
import { toast } from "react-toastify";

export default function FournisseursUtilisateurs() {
  const location = useLocation();
  const [dataState, setDataState] = useState();
  const [getIsDataLoading, setIsDataLoading] = useState(true);

  useEffect(() => {
    handleRefreshData();
  }, []);

  async function handleRefreshData() {
    try {
      setIsDataLoading(true);
      //console.debug(location.state.UID);
      const res = await axios.get(
        `${process.env.REACT_APP_APIM_BASE_URL}/GetFournisseursUtilisateurs?UID=${location.state.UID}`
      );
      //console.debug(res.data);
      setDataState(res.data);
    } catch (err) {
      toast.error("Probleme dans le chargement de la liste des utilisateurs");
    } finally {
      setIsDataLoading(false);
    }
  }

  // const handleSaveItem = async (item) => {
  //   if (item?.UID) {
  //     const res = await axios.put("EditFournisseurUtilisateur", item);
  //     const itemInArray = dataState.find((q) => q.UID === res.data.UID);
  //     dataState[dataState.indexOf(itemInArray)] = res.data;
  //     setDataState([...dataState]);
  //     return true;
  //   } else {
  //     item.Fournisseur_UID = location.state.UID;
  //     const res = await axios.post("AddFournisseurUtilisateur", item);
  //     setDataState(dataState.concat(res.data));
  //     return true;
  //   }
  // };

  const columns = [
    {
      name: "ID dans AD",
      selector: (row) => row.ADID,
      sortable: true,
    },
    {
      name: "Note",
      selector: (row) => row.Note,
      sortable: false,
    },
    {
      button: true,
      cell: (record) => (
        <EditFournisseurUtilisateur
          isEdit={true}
          item={Object.assign({}, record)}
          handleRefreshData={handleRefreshData}
        />
      ),
    },
  ];

  return (
    <div className={styles.Matieres}>
      <h1 align="center">Utilisateurs de {location.state.Nom}</h1>

      <div align="right">
        <EditFournisseurUtilisateur
          isEdit={false}
          item={{}}
          fournisseurUID={location.state.UID}
          handleRefreshData={handleRefreshData}
        />
      </div>

      <br />
      <div>
        <DataTable
          title=""
          columns={columns}
          data={dataState}
          progressPending={getIsDataLoading}
        />
      </div>
    </div>
  );
}
