import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import useApi from "../../hooks/useApi";
//import {Link} from "react-router-dom";
import axios from "axios";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
import SiteMatiereComponent from "./SiteMatiereComponent";

export default function SiteMatieres() {
  const location = useLocation();
  const siteUID = location.state.UID;

  const { apiGetVolumesConteneurs } = useApi();

  const [matieresState, setMatieresState] = useState([]);
  const [volumesConteneursState, setVolumesConteneursState] = useState([]);

  // const [dataState, setDataState] = useState();
  const [showToastState, setShowToastState] = useState(false);
  const [toastMsgState, setToastMsgState] = useState();

  const tableStyle = {
    overflow: "scroll",
  };

  useEffect(() => {
    axios
      .get("GetMatieres", {
        params: {
          showInactive: false,
        },
      }) //Returning pledges using a get-query
      .then((response) => {
        // Data retrieval and processing
        setMatieresState(response.data);
      })
      .catch((error) => {
        // If the query fails, an error will be displayed on the terminal.
        console.error(error);
      });

    apiGetVolumesConteneurs(false)
      .then((res) => {
        setVolumesConteneursState(res);
      })
      .catch((err) => {
        console.error(err);
      });

    // axios.get(`GetSiteMatiereVolumes?UID=${location.state.UID}`) //Returning pledges using a get-query
    //   .then((response) => { // Data retrieval and processing
    //     setDataState(response.data);
    //   })
    //   .catch((error) => { // If the query fails, an error will be displayed on the terminal.
    //     console.error(error);
    //   });

    //   axios.get(`GetSiteMatieres?UID=${location.state.UID}`) //Returning pledges using a get-query
    //     .then((response) => { // Data retrieval and processing
    //       setDataState(response.data);
    //     })
    //     .catch((error) => { // If the query fails, an error will be displayed on the terminal.
    //       console.error(error);
    //     });
  }, []);

  // const handleClickMatiere = async (e) => {
  //   //console.log(e.target.id);
  //   //console.log(e.target.checked);
  //   if (e.target.checked) {
  //     itemState.SelectedMatieres.push(e.target.id);
  //   }
  //   else {
  //     const index = itemState.SelectedMatieres.indexOf(e.target.id);
  //     if (index > -1) { // only splice array when item is found
  //       itemState.SelectedMatieres.splice(index, 1); // 2nd parameter means remove one item only
  //     }
  //   }
  //   //console.log(itemState.SelectedMatieres);
  // };

  const isVolumeSelected = () => {
    return true;
  };

  // const handleSaveItem = async (item) => {
  //   if (item?.UID) {
  //     try {
  //       //console.log(item);
  //       await axios.put("EditSiteMatiere", item);
  //       const itemInArray = dataState.find((q) => q.UID === item.UID);
  //       dataState[dataState.indexOf(itemInArray)] = item;
  //       setDataState([...dataState]);
  //       return true;
  //     } catch (err) {
  //       console.error(err);
  //       setToastMsgState(err.message);
  //       setShowToastState(true);
  //       return false;
  //     }
  //   } else {
  //     try {
  //       await axios.post("AddSiteMatiere", item);
  //       setDataState(dataState.concat(item));
  //       return true;
  //     } catch (err) {
  //       console.error(err);
  //       setToastMsgState(err.message);
  //       setShowToastState(true);
  //       return false;
  //     }
  //   }
  // };

  return (
    <>
      <h1 align="center">Lieu de service - Matières</h1>

      <br />
      <div style={tableStyle}>
        <table>
          <tbody>
            <tr>
              <td>Matières</td>
              <td width="50"></td>
              <td>Volumes (verges)</td>
            </tr>
            {matieresState.map((matiere) => (
              <SiteMatiereComponent
                key={matiere.UID}
                siteUID={siteUID}
                matiere={matiere}
                volumes={volumesConteneursState}
              />
            ))}
          </tbody>
        </table>
      </div>

      <ToastContainer
        className="p-3"
        position="middle-end"
        style={{ zIndex: 1 }}
      >
        <Toast
          bg="danger"
          onClose={() => setShowToastState(false)}
          show={showToastState}
          delay={4000}
          autohide
        >
          <Toast.Header>
            <strong className="me-auto">Erreur</strong>
          </Toast.Header>
          <Toast.Body className="text-white">{toastMsgState}</Toast.Body>
        </Toast>
      </ToastContainer>
    </>
  );
}
