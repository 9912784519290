import React, { Fragment } from "react"; //lazy
import ReactDOM from "react-dom/client";
import UnauthenticatedPageLayout from "./components/msal/UnauthenticatedPageLayout";
import AppRoutes from "./AppRoutes";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import "./index.scss";
import axios from "axios";
//import reportWebVitals from "./reportWebVitals";

// Import MSAL
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig, loginRequest } from "./components/msal/authConfig";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";

// React
const root = ReactDOM.createRoot(document.getElementById("root"));

// MSAL
const msalInstance = new PublicClientApplication(msalConfig);
await msalInstance.initialize();

// Axios
axios.defaults.baseURL = `${process.env.REACT_APP_APIM_BASE_URL}/`;
axios.interceptors.request.use(
  async (response) => {
    const account = msalInstance.getAllAccounts()[0];
    const msalResponse = await msalInstance.acquireTokenSilent({
      ...loginRequest,
      account: account,
    });

    response.headers["Authorization"] = `Bearer ${msalResponse.accessToken}`;
    response.headers["Ocp-Apim-Subscription-Key"] =
      process.env.REACT_APP_APIM_SUBSCRIPTION_KEY;
    return response;
  },
  (err) => {
    return Promise.reject(err);
  }
);

root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <Fragment>
        <UnauthenticatedTemplate>
          <UnauthenticatedPageLayout />
        </UnauthenticatedTemplate>
        <AuthenticatedTemplate>
          <AppRoutes />
        </AuthenticatedTemplate>
      </Fragment>
    </MsalProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals(console.log);
