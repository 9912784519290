import { useState, useEffect } from "react";
import { useSearchParams, useLocation, useNavigate } from "react-router-dom";
import useApi from "../../hooks/useApi";
import useConst from "../../hooks/useConst";
import { Alert, Form, Row, Col, Card, Button, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import BaseSelect from "../base-components/BaseSelect";
import AnnulationModal from "../base-components/AnnulationModal";

// DateTimePicker
import dayjs from "dayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
// DateTimePicker

// FilePond
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
// FilePond

/********************
 * Default Function *
 ********************/
export default function DemandeNext() {
  // useNavigate
  const navigate = useNavigate();
  // useNavigate

  // FilePond
  registerPlugin(
    FilePondPluginImageExifOrientation,
    FilePondPluginImagePreview
  );
  let pond;
  // FilePond

  // useApi
  const {
    apiGetDemande,
    apiGetMatieresForEchangeLevee,
    apiGetVolumesForEchangeLevee,
    apiValidateDemandeLivraison,
    apiAddDemandeNext,
    apiGetSitesBySiteType,
    apiGetPlacesBySite,
    apiGetProchaineHeureOuvertureSite,
  } = useApi();
  // useApi

  // useConst
  const { typeDemande, typesSites, getTypeSite, timeToGMT } = useConst();
  // useConst

  // useSearchParams
  const [searchParams, setSearchParams] = useSearchParams();
  const demandeType = searchParams.get("t");
  let demandeTypeCaption;
  let demandeTypeId;
  switch (demandeType) {
    case "echange":
      demandeTypeCaption = "d'échange";
      demandeTypeId = typeDemande.Echange;
      break;
    case "levee":
      demandeTypeCaption = "de levée finale";
      demandeTypeId = typeDemande.Levee;
      break;
    case "deplacement":
      demandeTypeCaption = "de déplacement";
      demandeTypeId = typeDemande.Deplacement;
      break;
    case "deplacementDemandeNonCompletee":
      demandeTypeCaption =
        "de déplacement intra-site sur demande existant non complétée";
      demandeTypeId = typeDemande.Deplacement;
      break;
    default:
      demandeTypeCaption = "non défini";
      demandeTypeId = -1;
  }
  // useSearchParams;

  // useLocation
  const location = useLocation();
  const demandeUID = location.state.params.demande.UID;
  // useLocation;

  // useState
  let nouvelleDemande = {};
  const [getIsLoading, setIsLoading] = useState(false);
  const [getIsSaving, setIsSaving] = useState();
  const [getErrorMsg, setErrorMsg] = useState();

  const [getIsLoadingMatiere, setIsLoadingMatiere] = useState(false);
  const [getIsLoadingVolume, setIsLoadingVolume] = useState(false);
  const [getIsLoadingSites, setIsLoadingSites] = useState(false);
  const [getIsLoadingPlaces, setIsLoadingPlaces] = useState(false);

  const [getDemande, setDemande] = useState();
  const [getMatieres, setMatieres] = useState();
  const [getVolumes, setVolumes] = useState();
  const [getSites, setSites] = useState();
  const [getPlaces, setPlaces] = useState();

  const [getSelectedMatiere, setSelectedMatiere] = useState();
  const [getSelectedVolume, setSelectedVolume] = useState();
  const [getSelectedSite, setSelectedSite] = useState();
  const [getSelectedPlace, setSelectedPlace] = useState();

  const [getIsMaintenant, setIsMaintenant] = useState(true);
  const [getDateHeurePlanifiee, setDateHeurePlanifiee] = useState(dayjs());
  const [getInfoComplementaire, setInfoComplementaire] = useState();
  const [getFiles, setFiles] = useState([]);
  const [getShowConfirmationLevee, setShowConfirmationLevee] = useState(false);

  const faireDemandeLeveOptions = [];
  const faireDemandeLeveOui = { label: "Oui", value: true };
  const faireDemandeLeveNon = { label: "Non", value: false };
  faireDemandeLeveOptions.push(faireDemandeLeveOui);
  faireDemandeLeveOptions.push(faireDemandeLeveNon);
  const [getFaireDemandeLeve, setFaireDemandeLeve] =
    useState(faireDemandeLeveNon);
  // useState

  // useEffect
  useEffect(() => {
    loadDemandePrecedante(demandeUID);

    // Note: Pour sélectionner Oui par défaut lorsque c'est une demande de déplacement. (JF a décidé de mettre non par défaut maintenant - 2024-05-01)
    // if (demandeType === "deplacement") {
    //   setFaireDemandeLeve(faireDemandeLeveOui);
    // } else {
    //   setFaireDemandeLeve(faireDemandeLeveNon);
    // }
  }, []);
  // useEffect

  async function loadDemandePrecedante() {
    try {
      setIsLoading(true);
      const res = await apiGetDemande(demandeUID);

      setDemande(res.data);
      await loadMatieres(res.data.MatiereOriginal_UID);
      await loadVolumes(res.data.ConteneurVolume_UID);

      if (res.data?.SiteActuelFK.NecessiteConfirmation) {
        setShowConfirmationLevee(true);
      } else {
        setShowConfirmationLevee(false);
      }

      // Note : Ne pas utiliser le triple egales car les 2 objets ne sont pas pareilles
      if (demandeTypeId == typeDemande.Deplacement) {
        loadSites(res.data?.SiteActuel_UID);
      }
    } catch (err) {
      toast.error(
        "Une erreur est survenue pendant le chargement de la demande."
      );
    } finally {
      setIsLoading(false);
    }
  }

  async function loadMatieres(matiereUID) {
    try {
      setIsLoadingMatiere(true);

      const matieres = await apiGetMatieresForEchangeLevee(matiereUID);

      matieres.forEach((i) => {
        i.label = i.Nom;
        i.value = i.UID;
      });

      setMatieres(matieres);
      setSelectedMatiere(matieres.find((q) => q.value === matiereUID));
    } catch (err) {
      console.error(err);
      toast.error(
        "Le système n'est pas en mesure de charger la liste des matières."
      );
    } finally {
      setIsLoadingMatiere(false);
    }
  }

  async function loadVolumes(volumeUID) {
    try {
      setIsLoadingVolume(true);

      const volumes = await apiGetVolumesForEchangeLevee(volumeUID);

      volumes.forEach((i) => {
        i.label = i.Nom;
        i.value = i.UID;
      });

      setVolumes(volumes);
      setSelectedVolume(volumes.find((q) => q.value === volumeUID));
    } catch (err) {
      console.error(err);
      toast.error(
        "Le système n'est pas en mesure de charger la liste des volumes."
      );
    } finally {
      setIsLoadingVolume(false);
    }
  }

  async function loadSites(siteActuelUID) {
    try {
      setIsLoadingSites(true);
      const sites = await apiGetSitesBySiteType(typesSites.Ecocentre);

      sites.forEach((i) => {
        i.label = i.Nom;
        i.value = i.UID;
      });

      setSites(sites);
      setSelectedSite(sites.find((q) => q.value === siteActuelUID));
      if (siteActuelUID) {
        await loadPlaces(siteActuelUID);
      }
    } catch (err) {
      toast.error(
        "Le système n'est pas en mesure de charger la liste des lieux de service."
      );
    } finally {
      setIsLoadingSites(false);
    }
  }

  async function loadPlaces(siteUID) {
    try {
      setIsLoadingPlaces(true);
      const data = await apiGetPlacesBySite(siteUID);

      data.forEach((i) => {
        i.label = i.Code;
        i.value = i.UID;
      });

      setPlaces(data);
    } catch (err) {
      console.error(err);
      toast.error(
        "Le système n'est pas en mesure de charger la liste des places."
      );
    } finally {
      setIsLoadingPlaces(false);
    }
  }

  function handleMaintenantClick(e) {
    if (e.target.checked === true) {
      setIsMaintenant(true);
      setDateHeurePlanifiee(dayjs());
    } else {
      setIsMaintenant(false);
      setDateHeurePlanifiee();
    }
  }

  function handleDateHeurePlanifieeChange(e) {
    setDateHeurePlanifiee(e);
  }

  const handleInfoComplementairesChange = (e) => {
    setInfoComplementaire(e.target.value);
  };

  function handleCancelClick() {
    navigate(-1);
  }

  async function handleSaveClick(pourcentageRemplissage) {
    setErrorMsg();

    const newDemande = {}; //Object.assign({}, getDemande);

    newDemande.TypeDemande = demandeTypeId;
    newDemande.DemandeParenteUID = getDemande.UID;

    if (demandeTypeId === typeDemande.Deplacement) {
      newDemande.SiteDestination_UID = getSelectedSite?.value;
      newDemande.PlaceDestination_UID = getSelectedPlace?.value;
      newDemande.FaireDemandeLeveeFinale =
        demandeType === "deplacement" &&
        getSelectedSite?.value === getDemande?.SiteActuelFK?.UID
          ? getFaireDemandeLeve?.value
          : false;
    } else {
      newDemande.SiteDestination_UID = getDemande.SiteActuel_UID;
      newDemande.PlaceDestination_UID = getDemande.PlaceActuelle_UID;
    }

    newDemande.Matiere_UID = getSelectedMatiere?.value;
    newDemande.ConteneurVolume_UID = getSelectedVolume?.value;
    newDemande.IsMaintenant = getIsMaintenant;
    newDemande.HeurePlannifiee =
      getDateHeurePlanifiee !== undefined
        ? timeToGMT(getDateHeurePlanifiee)
        : null;

    newDemande.NotePlace = getDemande.NotePlace;
    newDemande.ContactPlace = getDemande.ContactPlace;

    if (pourcentageRemplissage?.type !== "click") {
      newDemande.InfoComplementaire =
        (getInfoComplementaire ?? "") + " " + (pourcentageRemplissage ?? "");
    } else {
      newDemande.InfoComplementaire = getInfoComplementaire;
    }

    // Si demande de déplacement intra-site pour une demande échange/levée en cours
    if (demandeType === "deplacementDemandeNonCompletee") {
      newDemande.IsDeplacementIntraSiteSurEchangeLevee = true;
    } else {
      newDemande.IsDeplacementIntraSiteSurEchangeLevee = false;
    }

    try {
      setIsSaving(true);
      await apiValidateDemandeLivraison(newDemande);
    } catch (err) {
      console.error(err);
      setErrorMsg(err.response.data);
    } finally {
      setIsSaving(false);
    }

    try {
      setIsSaving(true);
      let res = await apiAddDemandeNext(demandeType, newDemande, getFiles); //

      navigate("/");
      toast.success(`La demande #${res.data.NoDemande} a été créée.`);
    } catch (err) {
      console.error(err);
      setErrorMsg(err.response.data);
    } finally {
      setIsSaving(false);
    }
  }

  async function handleSetCorrectDateTime() {
    const oldDateHeure = timeToGMT(getDateHeurePlanifiee);

    await handleMaintenantClick({ target: { checked: false } });

    const params = {
      params: {
        siteUID:
          demandeTypeId === typeDemande.Deplacement
            ? getSelectedSite?.value
            : getDemande?.SiteActuelFK?.UID,
        dateHeurePlanifiee: oldDateHeure,
      },
    };

    const prochaineHeureOuvertureSite = await apiGetProchaineHeureOuvertureSite(
      params
    );

    setDateHeurePlanifiee(dayjs(prochaineHeureOuvertureSite.ProchaineHeure));

    setErrorMsg();
  }

  /*************
   * COMPONENT *
   *************/
  return (
    <>
      <Form method="post" className="card">
        <Row>
          <Col xl={6} md={12}>
            <Card.Header>
              <Card.Title as="h3">
                Nouvelle demande {demandeTypeCaption}
              </Card.Title>
            </Card.Header>

            <Card.Body>
              <Form.Group>
                <Form.Label>Type de lieu de service</Form.Label>
                <Form.Control
                  type="text"
                  value={getTypeSite(getDemande?.SiteActuelFK?.TypeID)}
                  readOnly
                />
              </Form.Group>

              <Form.Group>
                <Form.Label>Lieu de service</Form.Label>
                <Form.Control
                  type="text"
                  defaultValue={getDemande?.SiteActuelFK?.Nom}
                  readOnly
                />
              </Form.Group>

              <Form.Group>
                <Form.Label>Place</Form.Label>
                <Form.Control
                  type="text"
                  defaultValue={getDemande?.PlaceActuelleFK?.Code}
                  readOnly
                />
              </Form.Group>

              {(demandeTypeId === typeDemande.Echange ||
                demandeTypeId === typeDemande.Levee) && (
                <>
                  {/**********
                   * Matière *
                   ***********/}
                  <Form.Group>
                    <Form.Label>Matière</Form.Label>
                    <BaseSelect
                      isLoading={getIsLoadingMatiere}
                      options={getMatieres}
                      value={getSelectedMatiere}
                      onChange={(e) => setSelectedMatiere(e)}
                    />
                  </Form.Group>

                  {/*********
                   * Volume *
                   **********/}
                  <Form.Group>
                    <Form.Label>Volume</Form.Label>
                    <BaseSelect
                      isLoading={getIsLoadingVolume}
                      options={getVolumes}
                      value={getSelectedVolume}
                      onChange={(e) => setSelectedVolume(e)}
                    />
                  </Form.Group>
                </>
              )}

              {/*************************
               * Demande de deplacement *
               **************************/}
              {demandeTypeId === typeDemande.Deplacement && (
                <>
                  <Form.Group>
                    <Form.Label>Matière</Form.Label>
                    <Form.Control
                      type="text"
                      defaultValue={getDemande?.Matiere?.Nom}
                      readOnly
                    />
                  </Form.Group>

                  <Form.Group>
                    <Form.Label>Volume</Form.Label>
                    <Form.Control
                      type="text"
                      defaultValue={getDemande?.Volume?.Nom}
                      readOnly
                    />
                  </Form.Group>

                  {demandeType !== "deplacementDemandeNonCompletee" && (
                    <Form.Group>
                      <Form.Label>
                        Déplacement vers le lieu de service
                      </Form.Label>
                      <BaseSelect
                        disabled={true}
                        isLoading={getIsLoadingSites}
                        options={getSites}
                        value={getSelectedSite}
                        onChange={async (e) => {
                          setSelectedSite(e);
                          setSelectedPlace(null);
                          await loadPlaces(e.value);
                        }}
                      />
                    </Form.Group>
                  )}

                  <Form.Group>
                    <Form.Label>Déplacement vers la Place</Form.Label>
                    <BaseSelect
                      isLoading={getIsLoadingPlaces}
                      options={getPlaces}
                      value={getSelectedPlace}
                      onChange={(e) => setSelectedPlace(e)}
                    />
                  </Form.Group>

                  {demandeTypeId === typeDemande.Deplacement &&
                    demandeType !== "deplacementDemandeNonCompletee" &&
                    getSelectedSite?.value ===
                      getDemande?.SiteActuelFK?.UID && (
                      <Form.Group>
                        <Form.Label>Faire la demande de levé</Form.Label>
                        <BaseSelect
                          options={faireDemandeLeveOptions}
                          value={getFaireDemandeLeve}
                          onChange={(e) => setFaireDemandeLeve(e)}
                        />
                      </Form.Group>
                    )}
                </>
              )}

              {/*** Heure de livraison ***/}

              <Form.Group className="mb-3">
                <Form.Label>Heure de livraison *</Form.Label>

                <Row className="mb-3">
                  <Col>
                    <Form.Check
                      type="checkbox"
                      label="Maintenant"
                      checked={getIsMaintenant}
                      onChange={handleMaintenantClick}
                    />
                  </Col>
                  <Col>
                    {!getIsMaintenant && (
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale="fr-ca"
                      >
                        <DateTimePicker
                          value={getDateHeurePlanifiee}
                          onChange={handleDateHeurePlanifieeChange}
                        />
                      </LocalizationProvider>
                    )}
                  </Col>
                </Row>
              </Form.Group>

              <Form.Group>
                <Form.Label>Information complémentaire</Form.Label>
                <textarea
                  className="form-control mb-4"
                  placeholder=""
                  rows={4}
                  onChange={handleInfoComplementairesChange}
                ></textarea>
              </Form.Group>

              {/*** Fichiers attachements ***/}

              <Form.Group className="mb-3">
                <Form.Label className="form-label mt-0">
                  Attachements
                </Form.Label>
                <FilePond
                  ref={(ref) => (pond = ref)}
                  files={getFiles}
                  onupdatefiles={setFiles}
                  allowMultiple={true}
                  maxFiles={3}
                  // server="/api"
                  name="files"
                  credits={false}
                  labelIdle='Glissez-déposez vos fichiers ou <span class="filepond--label-action">Sélectionnez</span>'
                />
              </Form.Group>
            </Card.Body>
          </Col>

          <Col xl={6} md={12}>
            <Card.Header>
              <Card.Title as="h3">&nbsp;</Card.Title>
            </Card.Header>

            <Card.Body>
              <Form.Group>
                <Form.Label>Adresse du lieu de service</Form.Label>
                <textarea
                  readOnly
                  className="form-control mb-4"
                  placeholder=""
                  defaultValue={getDemande?.AdresseActuelle}
                  rows={2}
                ></textarea>
              </Form.Group>

              <Form.Group>
                <Form.Label>Note du lieu de service</Form.Label>
                <textarea
                  readOnly
                  className="form-control mb-4"
                  placeholder=""
                  defaultValue={getDemande?.ContactPlace}
                  rows={2}
                ></textarea>
              </Form.Group>

              <Form.Group>
                <Form.Label>Note de la place</Form.Label>
                <textarea
                  readOnly
                  className="form-control mb-4"
                  placeholder=""
                  defaultValue={getDemande?.NotePlace}
                  rows={2}
                ></textarea>
              </Form.Group>
            </Card.Body>
          </Col>

          <Col xl={12} md={12}>
            <div align="center">
              {getErrorMsg && (
                <Alert className="mt-4" variant="danger">
                  {getErrorMsg}
                  {"   "}
                  {getErrorMsg.startsWith(
                    "La date et l'heure de livraison sélectionnée n'est pas valide"
                  ) && (
                    <Button size="sm" onClick={handleSetCorrectDateTime}>
                      Sélectionner cette date
                    </Button>
                  )}
                </Alert>
              )}
              <br />
              <Button
                variant="secondary"
                className="mt-4 mb-0"
                onClick={handleCancelClick}
              >
                Retour
              </Button>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {/* Pas besoin de demander de vérifier le conteneur avant la levée */}
              {!getShowConfirmationLevee && (
                <Button
                  variant="primary"
                  className="mt-4 mb-0"
                  disabled={getIsSaving}
                  onClick={handleSaveClick}
                >
                  Envoyer&nbsp;
                  {getIsSaving && <Spinner size="sm" />}
                </Button>
              )}
              {/* Besoin de demander de vérifier le conteneur avant la levée (On sauvegarde pas la raison dans info supp.) */}
              {getShowConfirmationLevee && (
                <AnnulationModal
                  instruction="Veuillez confirmer que le conteneur est bien remplis en entrant le pourcentage de remplissage du conteneur :"
                  width={"100px"}
                  handleSave={handleSaveClick}
                >
                  Envoyer
                </AnnulationModal>
              )}
            </div>
            <br />
            <br />
          </Col>
        </Row>
      </Form>
    </>
  );
}
