import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import "./VolumeConteneur.css";
import { Toast, ToastContainer } from "react-bootstrap/";
import { toast } from "react-toastify";
import Alert from "react-bootstrap/Alert";
import Spinner from "react-bootstrap/Spinner";
import useApi from "../../hooks/useApi";
// import { valueContainerCSS } from "react-select/dist/declarations/src/components/containers";

export default function EditVolumeConteneur({
  isEdit,
  item,
  handleRefreshData,
}) {
  const { apiGetContratsTransport } = useApi();

  const [showState, setShowState] = useState(false);
  const [itemState, setItemState] = useState(item);
  const [formError, setFormError] = useState({});
  const [showStateSave, setShowStateSave] = useState(true);
  const [showStateLoading, setShowStateLoading] = useState(false);
  const [showErrorMsg, setErrorMsg] = useState();
  const [showSuccessMsg, setSuccessMsg] = useState();
  const [initialItemState, setinitialItemState] = useState();

  useEffect(() => {
    setinitialItemState(item);
  }, []);

  const submitThis = () => {
    setFormError(validateSubmit(item));
    console.log(formError);
  };

  const validateSubmit = (item) => {
    let errors = {};
    let volumeValid = false;
    let actifValid = false;
    const regex = /^[0-9]*$/;
    if (item.Volume === "" || item.Volume == null) {
      errors.Volume = "Le volume ne doit pas etre vide.";
      volumeValid = false;
    } else if (item.Volume < 1 && !item.IsSystem) {
      errors.Volume =
        "Le volume doit être supérieur que 0 et doit contenir un numéro.";
      volumeValid = false;
    } else if (
      !item.Volume < 1 &&
      regex.test(item.Volume) &&
      item.Volume <= 1000
    ) {
      volumeValid = true;
    } else if (item.Volume > 1000) {
      errors.Volume = "Le nom ne doit pas être plus grand que 1000.";
      volumeValid = false;
    } else if (!regex.test(item.Volume) && !item.IsSystem) {
      errors.Volume = "Le nom doit posséder des chiffres.";
      volumeValid = false;
    }
    if (item.Actif == null) {
      errors.Actif = "Veuillez sélectionner une option.";
      actifValid = false;
    }
    if (item.Actif == -1) {
      errors.Actif = "Veuillez sélectionner une option.";
      actifValid = false;
    } else {
      actifValid = true;
    }
    if (item.IsSystem) {
      volumeValid = true;
    }

    if (actifValid == true && volumeValid == true) {
      handleSaveItem();
      return errors;
    }
    return errors;
  };

  const handleCloseModal = async () => {
    setErrorMsg("");
    setSuccessMsg("");
    setShowState(false);
    setFormError({});
    if (isEdit) {
      item.Actif = Number(initialItemState.Actif);
    } else {
      item.Actif = Number(-1);
    }
  };
  const handleSaveItem = async () => {
    //let answer = await handleSaveItemParent(itemState,isEdit)
    setShowStateSave(false);
    setShowStateLoading(true);
    try {
      if (isEdit) {
        await axios.put("EditVolumeConteneur", item);
        //const itemInArray = dataState.find((a) => a.UID === item.UID);
        //dataState[dataState.indexOf(itemInArray)] = item;
        //setDataState([...dataState]);
      } else {
        const response = await axios.post("AddVolumeConteneur", item);
        //setDataState(dataState.concat(response.data));
      }

      /////debut

      setErrorMsg("");
      setSuccessMsg("");
      //console.log(answer)
      if (true) {
        toast.success("Sauvegardé");
        await handleRefreshData();
        setShowState(false);
        if (!isEdit) {
        }
      }

      return true;
    } catch (err) {
      setErrorMsg(err.response.data);
      /*       console.error(err);
      setToastMsgState(err.response.data);
      setShowToastState(true);
      return false;
 */
    } finally {
      setShowStateLoading(false);
      setShowStateSave(true);
    }
  };

  return (
    <>
      {!isEdit && (
        <div align="right">
          <Button
            variant="primary"
            onClick={() => {
              setShowState(true);
            }}
          >
            Ajouter
          </Button>
        </div>
      )}
      {isEdit && (
        <div align="right">
          <Button
            variant="primary"
            size="sm"
            onClick={() => {
              setShowState(true);
            }}
          >
            Modifier
          </Button>
        </div>
      )}

      <Modal show={showState} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Volume de conteneur</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form onSubmit={submitThis}>
            <Form.Group className="mb-3" controlId="Volume">
              <Form.Label>Nom *</Form.Label>
              <Form.Control
                defaultValue={item?.Nom}
                onChange={(e) => (item.Volume = Number(e.target.value))}
                readOnly={isEdit && item.Volume < 0}
              />
              {formError.Volume && (
                <span className="error">{formError.Volume}</span>
              )}
            </Form.Group>

            {/* ACTIF */}
            <Form.Group className="mb-3" controlId="Actif">
              <Form.Label>Actif *</Form.Label>
              <Form.Select
                as="select"
                defaultValue={item?.Actif ?? -1}
                onChange={(e) => (item.Actif = Number(e.target.value))}
              >
                <option value="-1" disabled>
                  Veuillez sélectionner une option
                </option>
                <option value="1">Actif</option>
                <option value="0">Non-actif</option>
              </Form.Select>
              {formError.Actif && (
                <span className="error">{formError.Actif}</span>
              )}
            </Form.Group>

            {/* ALERT ERROR MESSAGE */}
            {showErrorMsg && (
              <Alert className="mt-4" variant="danger">
                {showErrorMsg}
              </Alert>
            )}
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Retour
          </Button>
          {showStateSave && (
            <Button variant="primary" onClick={submitThis}>
              Sauvegarder
            </Button>
          )}
          {showStateLoading && (
            <Button variant="primary" disabled>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              Chargement...
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}
