import React, { useState, useEffect } from "react";
import { Modal, Form, Button, Alert, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
export default function EditFournisseur({
  item,
  isEdit,
  handleSaveItemParent,
}) {
  const [itemState, setItemState] = useState(item);
  const [showState, setShowState] = useState(false);
  const [errormsg, setErrorMsg] = useState();
  const [showStateSave, setShowStateSave] = useState(true);
  const [showStateLoading, setShowStateLoading] = useState(false);
  const [formError, setFormError] = useState({});
  const [initialItemState, setinitialItemState] = useState();

  useEffect(() => {
    setinitialItemState(item);
  }, []);

  const submitThis = async () => {
    setFormError(validateSubmit(item));
    console.log(formError);
  };

  const validateSubmit = (itemState) => {
    let errors = {};
    let codeValid = false;

    let courrielValid = false;
    let typeValid = false;
    let nomValid = false;
    let adresseValid = false;
    let actifValid = false;

    const regex = /^[0-9]*$/;
    const endTime = "3000-01-01";
    const regexmail =
      /^(([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)(\s*;\s*|\s*$))+/i;
    //Type Id validation

    if (itemState.Type == -1 || itemState.Type == null) {
      console.log(itemState.TypeID);
      errors.TypeID = "La sélection d'un type est obligatoire.";
      typeValid = false;
    } else {
      typeValid = true;
    }

    //Code validation

    if (itemState.Code == "" || itemState.Code == null) {
      errors.Code = "Le code est obligatoire";
      codeValid = false;
    } else {
      codeValid = true;
    }
    // Nom validation

    if (itemState.Nom == "" || itemState.Nom == null) {
      errors.Nom = "Le nom est obligatoire.";
      nomValid = false;
    } else {
      nomValid = true;
    }

    //Adresse validation

    if (itemState.Adresse == "" || itemState.Adresse == null) {
      errors.Adresse = "L'adresse est obligatoire.";
      adresseValid = false;
    } else {
      adresseValid = true;
    }
    //Courriel validation

    if (itemState.Courriel == "" || itemState.Courriel == null) {
      errors.Courriel = "Le courriel est obligatoire.";
      courrielValid = false;
    }
    if (!regexmail.test(itemState.Courriel)) {
      errors.Courriel = "Le courriel est invalide.";
      courrielValid = false;
    }
    if (
      regexmail.test(itemState.Courriel) &&
      itemState.Courriel != "" &&
      itemState.Courriel != null
    ) {
      courrielValid = true;
    }

    //Actif validation

    if (itemState.Actif == -1 || itemState.Actif == null) {
      errors.Actif = "La sélection d'un actif est obligatoire.";
      actifValid = false;
    } else {
      actifValid = true;
    }

    console.debug(actifValid);
    console.debug(typeValid);
    console.debug(codeValid);
    console.debug(courrielValid);
    console.debug(nomValid);
    console.debug(adresseValid);

    if (
      actifValid &&
      typeValid &&
      codeValid &&
      nomValid &&
      adresseValid &&
      courrielValid
    ) {
      // console.log("holle");
      handleSaveItem();
      return errors;
    }
    return errors;
  };

  const handleSaveItem = async () => {
    setShowStateSave(false);
    setShowStateLoading(true);
    try {
      await handleSaveItemParent(item, isEdit);
      setShowState(false);
      toast.success("Sauvegardé");
    } catch (err) {
      setErrorMsg(err.response.data);
      console.log(err.response.data);
    } finally {
      setShowStateSave(true);
      setShowStateLoading(false);
    }
  };

  const handleCloseModal = () => {
    setShowState(false);
    setFormError({});
    setErrorMsg("");
    resetValues(initialItemState);
  };

  const resetValues = (initialItemState) => {
    item.Code = initialItemState.Code;
    item.Type = initialItemState.Type;
    item.Nom = initialItemState.Nom;
    item.Adresse = initialItemState.Adresse;
    item.Courriel = initialItemState.Courriel;
    item.Tel = initialItemState.Tel;
    item.Cell = initialItemState.Cell;
    item.DateDebut = initialItemState.DateDebut;
    item.DateFin = initialItemState.DateFin;
    item.Note = initialItemState.Note;
    item.Actif = initialItemState.Actif;
  };

  return (
    <>
      {isEdit ? (
        <div align="right">
          <Button
            variant="primary"
            size="sm"
            onClick={() => {
              setShowState(true);
              setErrorMsg("");
            }}
          >
            Modifier
          </Button>
        </div>
      ) : (
        <div align="right">
          <Button
            variant="primary"
            onClick={() => {
              setShowState(true);
              setErrorMsg("");
            }}
          >
            Ajouter
          </Button>
        </div>
      )}
      <Modal show={showState} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Fournisseur</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={submitThis}>
            <Form.Group className="mb-3">
              <Form.Label>Code *</Form.Label>
              <Form.Control
                //name="ID"
                defaultValue={item?.Code}
                placeholder=""
                autoFocus
                onChange={(e) => (item.Code = e.target.value)}
                readOnly={isEdit}
              />
              {formError.Code && (
                <span className="error">{formError.Code}</span>
              )}
            </Form.Group>

            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Type *</Form.Label>
              <Form.Select
                as="select"
                //name="itemState.Actif"
                defaultValue={item?.Type ?? -1}
                onChange={(e) => (item.Type = Number(e.target.value))}
              >
                <option value={-1} disabled>
                  Veuillez faire une sélection
                </option>
                <option value={1}>Transporteur</option>
                <option value={2}>Centre de traitement</option>
              </Form.Select>
              {formError.TypeID && (
                <span className="error">{formError.TypeID}</span>
              )}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Nom du fournisseur*</Form.Label>
              <Form.Control
                //name="itemState.Volume"
                defaultValue={item?.Nom}
                onChange={(e) => (item.Nom = e.target.value)}
              />
              {formError.Nom && <span className="error">{formError.Nom}</span>}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Adresse *</Form.Label>
              <Form.Control
                //name="itemState.Volume"
                defaultValue={item?.Adresse}
                onChange={(e) => (item.Adresse = e.target.value)}
              />
              {formError.Adresse && (
                <span className="error">{formError.Adresse}</span>
              )}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Courriel *</Form.Label>
              <Form.Control
                //name="itemState.Volume"
                defaultValue={item?.Courriel}
                onChange={(e) => (item.Courriel = e.target.value)}
              />
              {formError.Courriel && (
                <span className="error">{formError.Courriel}</span>
              )}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Tél. Travail</Form.Label>
              <Form.Control
                //name="itemState.Volume"
                defaultValue={item?.Tel}
                onChange={(e) => (item.Tel = e.target.value)}
              />
              {formError.Tel && <span className="error">{formError.Tel}</span>}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Tél. Mobile</Form.Label>
              <Form.Control
                //name="itemState.Volume"
                defaultValue={item?.Cell}
                onChange={(e) => (item.Cell = e.target.value)}
              />
              {formError.Cell && (
                <span className="error">{formError.Cell}</span>
              )}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Note</Form.Label>
              <Form.Control
                //name="itemState.Volume"
                defaultValue={item?.Note}
                onChange={(e) => (item.Note = e.target.value)}
              />
            </Form.Group>

            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Actif *</Form.Label>
              <Form.Select
                as="select"
                //name="itemState.Actif"
                defaultValue={item?.Actif ?? -1}
                onChange={(e) => (item.Actif = Number(e.target.value))}
              >
                <option value="-1" disabled>
                  Veuillez faire une sélection
                </option>
                <option value="1">Actif</option>
                <option value="0">Non-actif</option>
              </Form.Select>
              {formError.Actif && (
                <span className="error">{formError.Actif}</span>
              )}
              {errormsg && (
                <Alert className="mt-4" variant="danger">
                  {errormsg}
                </Alert>
              )}
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Retour
          </Button>
          {showStateSave && (
            <Button variant="primary" onClick={submitThis}>
              Sauvegarder
            </Button>
          )}
          {showStateLoading && (
            <Button variant="primary" disabled>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              Chargement...
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}
