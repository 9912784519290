import React, { Fragment, useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import axios from "axios";
import styles from "./Footer.module.scss";

export const spaVersion = "1.4.2";

const Footer = () => {
  const [getApiVersion, setApiVersion] = useState();

  useEffect(() => {
    // Get apiVersion
    console.debug(`SPA ${spaVersion}`);
    axios
      .get("GetApiVersion")
      .then((response) => {
        setApiVersion(response.data);
        console.debug(`API ${response.data}`);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  return (
    <Fragment>
      <footer className="footer mt-3" style={{ textAlign: "center" }}>
        <div className="container">
          <Row className="align-items-center flex-row-reverse">
            <Col md={12} sm={12}>
              <span className={styles.footer}>
                SPA {spaVersion} - API {getApiVersion}
              </span>
            </Col>
          </Row>
        </div>
      </footer>
      {/* <!-- FOOTER CLOSED --> */}
    </Fragment>
  );
};

export default Footer;
