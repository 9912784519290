import styles from "./ContratsCentresTraitement.module.scss";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import dayjs from "dayjs";

import DataTable from "../base-components/DataTableBase";
import EditContratCentreTraitement from "./EditContratCentreTraitement";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";

import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";

export default function ContratsTransporteurs() {
  const [dataState, setDataState] = useState();
  const [getShowToast, setShowToast] = useState(false);
  const [getToastMsg, setToastMsg] = useState();
  const [getIsDataLoading, setIsDataLoading] = useState(true);

  useEffect(() => {
    loadContrats();
  }, []);

  const loadContrats = () => {
    axios
      .get(
        `${process.env.REACT_APP_APIM_BASE_URL}/GetContratsCentresTraitement`
      ) //Returning pledges using a get-query
      .then((response) => {
        // Data retrieval and processing
        setDataState(response.data);
        setIsDataLoading(false);
        //console.log(response.data);
      })
      .catch((err) => {
        // If the query fails, an error will be displayed on the terminal.
        setToastMsg(err.message);
        setShowToast(true);
      });
  };

  const handleSaveItem = async (item, isEdit) => {
    // if (isEdit) {
    //   try {
    //     const res = await axios.put("EditContratCentreTraitement", item);
    //     //console.log(res.data);
    //     const itemInArray = dataState.find((q) => q.UID === res.data.UID);
    //     dataState[dataState.indexOf(itemInArray)] = res.data;
    //     setDataState([...dataState]);
    //     return true;
    //   } catch (err) {
    //     setToastMsg(err.message);
    //     setShowToast(true);
    //     return false;
    //   }
    // } else {
    //   try {
    //     const res = await axios.post("AddContratCentreTraitement", item);
    //     setDataState(dataState.concat(res.data));
    //     return true;
    //   } catch (err) {
    //     setToastMsg(err.message);
    //     setShowToast(true);
    //     return false;
    //   }
    // }
    loadContrats();
  };

  const columns = [
    // {
    //   name: "UID",
    //   selector: row => row.UID,
    //   sortable: true,
    // },
    {
      name: "No. Contrat",
      selector: (row) => row.NumContrat,
      sortable: true,
    },
    {
      name: "Fournisseur",
      cell: (row) => row?.Fournisseur?.Nom,
      sortable: true,
    },
    {
      name: "Matiere",
      selector: (row) => row.Matiere?.Nom,
      sortable: true,
    },
    {
      name: "Date Début",
      selector: (row) =>
        row.DateDebut
          ? dayjs(row.DateDebut).format("YYYY-MM-DD")
          : row.DateDebut,
      sortable: false,
    },
    {
      name: "Date Fin",
      selector: (row) =>
        row.DateFin ? dayjs(row.DateFin).format("YYYY-MM-DD") : row.DateFin,
      sortable: false,
    },
    {
      name: "Actif",
      selector: (row) => (row.Actif === 1 ? "Actif" : "Non-actif"),
    },
    {
      button: true,
      cell: (record) => (
        <Link to={"/admin/centre-traitement-horaire"} state={record}>
          Horaire
        </Link>
      ),
    },
    {
      button: true,
      cell: (record) => (
        <EditContratCentreTraitement
          isEdit={true}
          item={Object.assign({}, record)}
          handleSaveItemParent={handleSaveItem}
        />
      ),
    },
  ];

  return (
    <div className={styles.Matieres}>
      <h1 align="center">Contrats - Centres de traitement</h1>

      <div align="right">
        <EditContratCentreTraitement
          isEdit={false}
          item={{}}
          handleSaveItemParent={handleSaveItem}
        />
      </div>

      <br />
      <div>
        <DataTable
          title=""
          columns={columns}
          data={dataState}
          progressPending={getIsDataLoading}
        />
      </div>

      <ToastContainer
        className="p-3"
        position="middle-end"
        style={{ zIndex: 1000000 }}
      >
        <Toast
          bg="danger"
          onClose={() => setShowToast(false)}
          show={getShowToast}
          delay={4000}
          autohide
        >
          <Toast.Header>
            <strong className="me-auto">Erreur</strong>
          </Toast.Header>
          <Toast.Body className="text-white">{getToastMsg}</Toast.Body>
        </Toast>
      </ToastContainer>
    </div>
  );
}
