import { useState, useEffect } from "react";
import useApi from "../../hooks/useApi";
import useNouvelleDemande from "./useNouvelleDemande";
import {
  Alert,
  Col,
  Form,
  Row,
  Card,
  Button,
  Spinner,
  Modal,
} from "react-bootstrap";
import BaseSelect from "../base-components/BaseSelect";
import SelectSiteType from "../base-components/SelectSiteType";

// DateTimePicker
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
// DateTimePicker

// FilePond
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
// `npm i filepond-plugin-image-preview filepond-plugin-image-exif-orientation --save`
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
// FilePond

/********************
 * Default Function *
 ********************/
export default function NouvelleDemande() {
  // FilePond
  registerPlugin(
    FilePondPluginImageExifOrientation,
    FilePondPluginImagePreview
  );

  // useNouvelleDemande
  const {
    getErrorMsg,
    getSuccessMsg,
    getInfoMsg,
    getUserRole,
    getTitle,
    getIsLoadingSitesTypes,
    handleOnChangeSiteType,
    getIsLoadingSites,
    getSites,
    handleSiteChange,
    getIsLoadingPlaces,
    getPlaces,
    getSelectedPlace,
    getPlaceNote,
    getSelectedSite,
    getSiteAdresse,
    getSiteNote,
    getIsLoadingMatieres,
    getMatieres,
    handlePlaceChange,
    handleMatiereChange,
    handleVolumeChange,
    getIsLoadingVolumes,
    getVolumes,
    getSelectedVolume,
    getContratTransport,
    handleInfoCompChange,
    getIsMaintenant,
    handleMaintenantClick,
    getDateHeurePlanifiee,
    handleDateHeurePlanifieeChange,
    getFiles,
    setFiles,
    handleRetourClick,
    handleSaveClick,
    getIsSaving,
    getSelectedMatiere,
    getShowModalNouveauLieuSpecial,
    setShowModalNouveauLieuSpecial,
    handleSetCorrectDateTime,
  } = useNouvelleDemande();

  // useApi
  const { apiGetTest2 } = useApi();

  // useState UI
  const [getCourrielSuperviseurs, setCourrielSuperviseurs] = useState();

  // useEffect UI
  useEffect(() => {
    loadCourrielSuperviseurs();
  }, [getShowModalNouveauLieuSpecial]);

  // functions UI
  async function loadCourrielSuperviseurs() {
    const params = {
      params: {
        p: "getCourrielSuperviseurs",
      },
    };

    const courrielSuperviseurs = await apiGetTest2(params);

    if (courrielSuperviseurs) {
      setCourrielSuperviseurs(courrielSuperviseurs);
    }
  }

  /*************
   * COMPONENT *
   *************/
  return (
    <div>
      <Form method="post" className="card">
        <Row>
          <Col xl={6} md={12}>
            <Card.Header>
              <Card.Title as="h3">{getTitle}</Card.Title>
            </Card.Header>

            <Card.Body>
              <SelectSiteType
                role={getUserRole}
                isLoading={getIsLoadingSitesTypes}
                handleSiteTypeChange={handleOnChangeSiteType}
              />

              <Form.Group>
                <Form.Label>lieu de service *</Form.Label>
                <BaseSelect
                  isLoading={getIsLoadingSites}
                  options={getSites}
                  value={getSelectedSite}
                  onChange={handleSiteChange}
                />
              </Form.Group>

              <Form.Group>
                <Form.Label>Place *</Form.Label>
                <BaseSelect
                  isLoading={getIsLoadingPlaces}
                  options={getPlaces}
                  value={getSelectedPlace}
                  onChange={handlePlaceChange}
                />
              </Form.Group>

              <Form.Group>
                <Form.Label>Matière *</Form.Label>
                <BaseSelect
                  isLoading={getIsLoadingMatieres}
                  options={getMatieres}
                  value={getSelectedMatiere}
                  onChange={handleMatiereChange}
                />
              </Form.Group>

              <Form.Group>
                <Form.Label>Volume *</Form.Label>
                <BaseSelect
                  isLoading={getIsLoadingVolumes}
                  options={getVolumes}
                  value={getSelectedVolume}
                  onChange={handleVolumeChange}
                />
              </Form.Group>

              {/*** Heure de livraison ***/}

              <Form.Group className="mb-3">
                <Form.Label>Heure de livraison *</Form.Label>

                <Row className="mb-3">
                  <Col>
                    <Form.Check
                      type="checkbox"
                      label="Maintenant"
                      checked={getIsMaintenant}
                      onChange={handleMaintenantClick}
                    />
                  </Col>
                  <Col>
                    {!getIsMaintenant && (
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale="fr-ca"
                      >
                        <DateTimePicker
                          value={getDateHeurePlanifiee}
                          onChange={handleDateHeurePlanifieeChange}
                        />
                      </LocalizationProvider>
                    )}
                  </Col>
                </Row>
              </Form.Group>

              <Form.Group>
                <Form.Label>Information complémentaire</Form.Label>
                <textarea
                  className="form-control mb-4"
                  placeholder=""
                  //defaultValue={getDemande?.InfoComplementaire}
                  rows={4}
                  onChange={handleInfoCompChange}
                ></textarea>
              </Form.Group>

              {/*** Fichiers attachements ***/}

              <Form.Group className="mb-3">
                <Form.Label className="form-label mt-0">
                  Attachements
                </Form.Label>
                <FilePond
                  files={getFiles}
                  onupdatefiles={setFiles}
                  allowMultiple={true}
                  maxFiles={3}
                  // server="/api"
                  name="files"
                  credits={false}
                  labelIdle='Glissez-déposez vos fichiers ou <span class="filepond--label-action">Sélectionnez</span>'
                />
              </Form.Group>
            </Card.Body>
          </Col>

          <Col xl={6} md={12}>
            <Card.Header>
              <Card.Title as="h3">&nbsp;</Card.Title>
            </Card.Header>
            <Card.Body>
              <Form.Group>
                <Form.Label>Adresse du lieu de service</Form.Label>
                <textarea
                  readOnly
                  className="form-control mb-4"
                  placeholder=""
                  value={getSiteAdresse}
                  rows={2}
                ></textarea>
              </Form.Group>

              <Form.Group>
                <Form.Label>Note du lieu de service</Form.Label>
                <textarea
                  readOnly
                  className="form-control mb-4"
                  placeholder=""
                  value={getSiteNote}
                  rows={2}
                ></textarea>
              </Form.Group>

              <Form.Group>
                <Form.Label>Note de la place</Form.Label>
                <textarea
                  readOnly
                  className="form-control mb-4"
                  placeholder=""
                  value={getPlaceNote}
                  rows={2}
                ></textarea>
              </Form.Group>

              <Form.Group>
                <Form.Label>Contrat de transport</Form.Label>
                <textarea
                  readOnly
                  className="form-control mb-4"
                  placeholder=""
                  value={getContratTransport}
                  rows={3}
                ></textarea>
              </Form.Group>
            </Card.Body>
          </Col>

          <Col xl={12} md={12}>
            <Row style={{ margin: 4 }}>
              <Col>
                {getInfoMsg && (
                  <Alert className="mt-4 ml-4" variant="info">
                    {getInfoMsg}
                  </Alert>
                )}
                {getSuccessMsg && (
                  <Alert className="mt-4" variant="success">
                    {getSuccessMsg}
                  </Alert>
                )}
                {getErrorMsg && (
                  <Alert className="mt-4" variant="danger">
                    {getErrorMsg}
                    {"   "}
                    {getErrorMsg.startsWith(
                      "La date et l'heure de livraison sélectionnée n'est pas valide"
                    ) && (
                      <Button size="sm" onClick={handleSetCorrectDateTime}>
                        Sélectionner cette date
                      </Button>
                    )}
                  </Alert>
                )}
              </Col>
            </Row>
            <Row align="center" className="mb-4">
              <Col>
                <Button
                  variant="secondary"
                  className="mt-4 mb-0"
                  onClick={handleRetourClick}
                >
                  Retour
                </Button>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <Button
                  variant="primary"
                  className="mt-4 mb-0"
                  disabled={getIsSaving}
                  onClick={handleSaveClick}
                >
                  Envoyer&nbsp;
                  {getIsSaving && <Spinner size="sm" />}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
      <Modal
        show={getShowModalNouveauLieuSpecial}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <Row>
            <Col>
              <p>
                Pour une demande de création d'un nouveau lieu de service
                spécial, veuillez envoyer votre demande par courriel à "
                {getCourrielSuperviseurs}".
              </p>
              <p>
                Veuillez indiquer les informations suivantes dans votre demande
                : Nom du lieu de service, l'adresse, les dates effectives, la
                matière, l'horaire et la description des places dans le lieu
                spécial.
              </p>
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <Row>
            <Col>
              <Button
                variant="secondary"
                onClick={() => setShowModalNouveauLieuSpecial(false)}
              >
                Retour
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
