import { useState } from "react";
import { Alert, Button, Modal, Row, Col } from "react-bootstrap";

export default function ConfirmationModal(props) {
  const [getShowModal, setShowModal] = useState(false);

  async function handleSaveClick() {
    await props.handleSave();
    setShowModal(false);
  }

  return (
    <>
      <Button
        variant="primary"
        className="mt-4 ms-1 mb-0"
        style={{ width: props.width ?? "180px" }}
        onClick={() => setShowModal(true)}
      >
        {props.children}
      </Button>

      <Modal
        show={getShowModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <Row>
            <Col>
              <h3>{props.instruction}</h3>
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <Row>
            <Col>
              <Button variant="secondary" onClick={() => setShowModal(false)}>
                Annuler
              </Button>
            </Col>
            <Col>
              <Button onClick={handleSaveClick}>{props.action}</Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    </>
  );
}
