import axios from "axios";
import useConst from "./useConst";

export default function useApi() {
  const multipartHeaders = { "Content-Type": "multipart/form-data" };

  const { userRole } = useConst();

  async function apiGetTest2(params) {
    // const params = {
    //   params: {
    //     p,
    //   },
    // };

    const res = await axios.get("GetTest2", params);
    return res.data;
  }

  async function apiGetUserRole() {
    const res = await axios.get("GetUserRole");
    switch (res.data) {
      case "fournisseur":
        return userRole.Fournisseur;
      case "demandeur":
        return userRole.Demandeur;
      case "prepose":
        return userRole.Prepose;
      case "superviseur":
        return userRole.Superviseur;
      case "administrateur":
        return userRole.Administrateur;
      default:
        return userRole.NoAccess;
    }
  }

  async function apiEditSessionUtilisateurSite(SiteUID) {
    const res = await axios.put("EditSessionUtilisateurSite", { SiteUID });
    return res;
  }

  async function apiGetUserSite() {
    const params = {
      params: {
        p: "usersite",
      },
    };

    const res = await axios.get("GetID", params);
    return res.data;
  }

  async function apiSearchDemandes(searchFilters) {
    return await axios.post("GetDemandesBySearch", searchFilters);
  }

  async function apiGetDemande(demandeUID) {
    const params = {
      params: {
        DemandeUID: demandeUID,
      },
    };
    return await axios.get("GetDemandeById", params);
  }

  async function apiGetDemandeSuivante(demandeUID) {
    const params = {
      params: {
        DemandeUID: demandeUID,
      },
    };
    return await axios.get("GetNextDemande", params);
  }

  async function apiValidateDemandeLivraison(demande) {
    let formData = new FormData();

    formData.append("demande", JSON.stringify(demande));

    return await axios.post("ValidateDemandeLivraison", formData, {
      multipartHeaders,
    });
  }

  async function apiAddDemandeLivraison(demande, files) {
    let formData = new FormData();

    formData.append("demande", JSON.stringify(demande));

    if (files != null) {
      files.forEach((eachFile) => {
        formData.append(eachFile.name, eachFile.file);
      });
    }

    return await axios.post("AddDemandeLivraison", formData, {
      multipartHeaders,
    });
  }

  async function apiAddDemandeCollecte(demande, files) {
    let formData = new FormData();

    formData.append("demande", JSON.stringify(demande));

    if (files != null) {
      files.forEach((eachFile) => {
        formData.append(eachFile.name, eachFile.file);
      });
    }

    return await axios.post("AddDemandeCollecteParLien", formData, {
      multipartHeaders,
    });
  }

  async function apiAnnulerDemande(demandeUID, raisonAnnulation) {
    const demande = {
      DemandeUID: demandeUID,
      RaisonAnnulation: raisonAnnulation,
    };
    return await axios.put("AnnulerLaDemande", demande);
  }

  async function apiRefuserDemande(demandeUID, raisonRefus) {
    const demande = {
      DemandeUID: demandeUID,
      RaisonAnnulation: raisonRefus,
    };
    return await axios.put("RefuserLaDemande", demande);
  }

  async function apiApprouverDemande(demandeUID) {
    const demande = {
      DemandeUID: demandeUID,
    };
    return await axios.put("ApprouverLaDemande", demande);
  }

  async function apiPlanifierDemande(demandeUID) {
    const demande = {
      DemandeUID: demandeUID,
    };
    return await axios.put("PlanifierDemande", demande);
  }

  async function apiConfirmerDemande(demandeUID) {
    const demande = {
      DemandeUID: demandeUID,
    };
    return await axios.put("ConfirmerLaDemande", demande);
  }

  async function apiCompleterDemande(demandeUID) {
    const demande = {
      DemandeUID: demandeUID,
    };
    return await axios.put("CompleterLaDemande", demande);
  }

  async function apiGetMatieresForEchangeLevee(matiereUID) {
    const payload = {
      params: {
        matiereUID,
      },
    };

    const res = await axios.get("GetMatieresForEchangeLevee", payload);
    return res.data;
  }

  async function apiGetVolumesForEchangeLevee(volumeUID) {
    const payload = {
      params: {
        volumeUID,
      },
    };

    const res = await axios.get("GetVolumesForEchangeLevee", payload);
    return res.data;
  }

  async function apiAddDemandeNext(demandeType, demande, files) {
    let formData = new FormData();

    formData.append("demande", JSON.stringify(demande));

    if (files != null) {
      files.forEach((eachFile) => {
        formData.append(eachFile.name, eachFile.file);
      });
    }

    let postTo;
    switch (demandeType) {
      case "echange":
        postTo = "AddDemandeEchange";
        break;
      case "levee":
        postTo = "AddDemandeLevee";
        break;
      case "deplacement":
        postTo = "AddDemandeDeplacement";
        break;
      case "deplacementDemandeNonCompletee":
        postTo = "AddDemandeDeplacement";
        break;
      default:
        throw new Error("Le type de demande est non défini");
    }

    return await axios.post(postTo, formData, {
      multipartHeaders,
    });
  }

  async function apiRefuserRamassage(demandeUID, raisonRefus) {
    const demande = {
      DemandeUID: demandeUID,
      RaisonAnnulation: raisonRefus,
    };
    return await axios.put("RefuserRamassage", demande);
  }

  async function apiRamasserDemande(demandeUID, noCamion) {
    const demande = {
      DemandeUID: demandeUID,
      NoCamion: noCamion,
    };
    return await axios.put("RamasserDemande", demande);
  }

  async function apiCompleteDemandeLevee(
    demandeUID,
    poidCamion,
    noBilletTraitement
  ) {
    const demande = {
      DemandeUID: demandeUID,
      PoidCamion: poidCamion,
      NoBilletTraitement: noBilletTraitement,
    };

    return await axios.put("CompleterDemandeLevee", demande);
  }

  async function apiGetSites(type, showInactive) {
    const payload = {
      params: {
        type,
        showInactive,
      },
    };
    var res = await axios.get("GetSites", payload);
    return res.data;
  }

  async function apiGetMatieres(showInactive) {
    const payload = {
      params: {
        showInactive,
      },
    };
    var res = await axios.get("GetMatieres", payload);
    return res.data;
  }

  async function apiGetVolumesConteneurs(isShowAll) {
    const params = {
      params: {
        isShowAll: isShowAll,
      },
    };

    try {
      const res = await axios.get("GetVolumesConteneurs", params);
      return res.data;
    } catch (err) {
      console.error(err);
    }
  }

  async function apiGetContratsTransport(isReadCache, isShowAll) {
    const params = {
      params: {
        isShowAll: isShowAll,
      },
    };

    try {
      const res = await axios.get("GetContratsTransport", params);
      return res.data;
    } catch (err) {
      console.error(err);
    }
  }

  async function apiGetBlobImage(filename) {
    const payload = {
      responseType: "blob",
      params: {
        filename,
      },
    };
    return await axios.get("GetBlob", payload);
  }

  async function apiGetFournisseurs(type, showInactive) {
    const payload = {
      params: {
        type,
        showInactive,
      },
    };

    const res = await axios.get("GetFournisseurs", payload);
    return res.data;
  }

  async function apiGetSitesBySiteType(typeSite) {
    const params = {
      params: {
        TypeSiteID: typeSite,
      },
    };

    const res = await axios.get("GetSitesBySiteType", params);
    return res.data;
  }

  async function apiGetProchaineHeureOuvertureSite(params) {
    const res = await axios.get("GetTestProchaineHeureOuvertureSite", params);
    return res.data;
  }

  async function apiGetPlacesBySite(SiteUID) {
    const params = {
      params: {
        SiteUID,
      },
    };

    const res = await axios.get("GetPlacesBySite", params);
    return res.data;
  }

  async function apiGetMatieresBySite(site) {
    const params = {
      params: {
        SiteUID: site.UID,
      },
    };

    const res = await axios.get("GetMatieresBySite", params);
    return res.data;
  }

  async function apiGetVolumesBySiteMatiere(siteUID, matiereUID) {
    const params = {
      params: {
        siteUID,
        matiereUID,
      },
    };

    const res = await axios.get("GetVolumesBySiteMatiere", params);
    return res.data;
  }

  async function apiGetContratTransportForDemande(matiere, planificationDate) {
    const params = {
      params: {
        MatiereUID: matiere.UID,
        PlanificationDate: planificationDate,
      },
    };

    const res = await axios.get("GetContratTransportForDemande", params);
    return res.data;
  }

  async function apiEditMatiere(matiere) {
    const res = await axios.put("EditMatiere", matiere);
    return res.data;
  }

  async function apiAddMatiere(matiere) {
    const res = await axios.post("AddMatiere", matiere);
    return res.data;
  }

  async function apiModifierNumCamion(demandeUID, numCamion, isSentEmail) {
    const demande = {
      DemandeUID: demandeUID,
      NoCamion: numCamion,
      IsSentEmail: isSentEmail,
    };

    const params = {
      params: {
        fonction: "ModifierNumCamion",
      },
    };

    return await axios.put("ModifierDemande", demande, params);
  }

  async function apiModifierDemandeCompletee(
    demandeUID,
    noCamion,
    poidsCamion,
    noBillet
  ) {
    const demande = {
      DemandeUID: demandeUID,
      NoCamion: noCamion,
      PoidCamion: poidsCamion.toString(),
      NoBilletTraitement: noBillet,
    };

    const params = {
      params: {
        fonction: "ModifierDemandeCompletee",
      },
    };

    return await axios.put("ModifierDemande", demande, params);
  }

  return {
    apiGetUserRole,
    apiGetTest2,
    apiSearchDemandes,
    apiGetDemande,
    apiGetDemandeSuivante,
    apiValidateDemandeLivraison,
    apiAddDemandeLivraison,
    apiAddDemandeCollecte,
    apiAnnulerDemande,
    apiRefuserDemande,
    apiApprouverDemande,
    apiPlanifierDemande,
    apiConfirmerDemande,
    apiCompleterDemande,
    apiGetMatieresForEchangeLevee,
    apiGetVolumesForEchangeLevee,
    apiAddDemandeNext,
    apiRefuserRamassage,
    apiRamasserDemande,
    apiCompleteDemandeLevee,
    apiGetBlobImage,
    apiGetProchaineHeureOuvertureSite,
    apiGetPlacesBySite,
    apiGetMatieresBySite,
    apiGetVolumesBySiteMatiere,
    apiGetContratTransportForDemande,
    apiGetVolumesConteneurs,
    apiGetContratsTransport,
    apiGetSites,
    apiGetMatieres,
    apiEditSessionUtilisateurSite,
    apiGetUserSite,
    apiGetFournisseurs,
    apiGetSitesBySiteType,
    apiAddMatiere,
    apiEditMatiere,
    apiModifierNumCamion,
    apiModifierDemandeCompletee,
  };
}
