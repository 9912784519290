import useConst from "../../hooks/useConst";

import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
//import {Link} from "react-router-dom";
import axios from "axios";
import DataTable from "../base-components/DataTableBase";
import EditHoraire from "./EditHoraire";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";

export default function CentreTraitementHoraire() {
  const location = useLocation();

  const { timeToGMT } = useConst();

  const [dataState, setDataState] = useState();
  const [showToastState, setShowToastState] = useState(false);
  const [toastMsgState, setToastMsgState] = useState();
  const [getIsDataLoading, setIsDataLoading] = useState(true);

  useEffect(() => {
    axios
      .get(`GetCentreTraitementHoraire?UID=${location.state.UID}`) //Returning pledges using a get-query
      .then((response) => {
        // Data retrieval and processing
        setDataState(response.data);
        setIsDataLoading(false);
      })
      .catch((error) => {
        // If the query fails, an error will be displayed on the terminal.
        console.error(error);
      });
  }, []);

  const handleSaveItem = async (item) => {
    try {
      let apiItem = {};
      Object.assign(apiItem, item);

      apiItem.HeureDebut = timeToGMT(item.dayJsHeureDebut);
      apiItem.HeureFin = timeToGMT(item.dayJsHeureFin);

      await axios.put("EditCentreTraitementHoraire", apiItem);
      const itemInArray = dataState.find((q) => q.Jour === item.Jour);
      dataState[dataState.indexOf(itemInArray)] = item;
      setDataState([...dataState]);
      return true;
    } catch (err) {
      console.error(err);
      setToastMsgState(err.message);
      setShowToastState(true);
      // return false;
    }
  };

  const columns = [
    {
      name: "Jour",
      selector: (row) => {
        switch (row.Jour) {
          case 1:
            return "Dimanche";
          case 2:
            return "Lundi";
          case 3:
            return "Mardi";
          case 4:
            return "Mercredi";
          case 5:
            return "Jeudi";
          case 6:
            return "Vendredi";
          case 7:
            return "Samedi";
          default:
            return "s/o";
        }
      },
      sortable: true,
    },
    {
      name: "Ouverture",
      selector: (row) =>
        new Date(row.HeureDebut)
          .toLocaleTimeString("fr-CA", {
            hour: "2-digit",
            minute: "2-digit",
          })
          .split("m", 1),
      sortable: true,
    },
    {
      name: "Fermeture",
      selector: (row) =>
        new Date(row.HeureFin)
          .toLocaleTimeString("fr-CA", {
            hour: "2-digit",
            minute: "2-digit",
          })
          .split("m", 1),
      sortable: true,
    },
    {
      button: true,
      cell: (record) => (
        <EditHoraire
          isEdit={true}
          item={record}
          handleSaveItemParent={handleSaveItem}
        />
      ),
    },
  ];

  return (
    <>
      <h1 align="center">Horaire du Centre de traitement</h1>

      <br />
      <div>
        <DataTable
          title=""
          columns={columns}
          data={dataState}
          progressPending={getIsDataLoading}
        />
      </div>

      <ToastContainer
        className="p-3"
        position="middle-end"
        style={{ zIndex: 1 }}
      >
        <Toast
          bg="danger"
          onClose={() => setShowToastState(false)}
          show={showToastState}
          delay={4000}
          autohide
        >
          <Toast.Header>
            <strong className="me-auto">Erreur</strong>
          </Toast.Header>
          <Toast.Body className="text-white">{toastMsgState}</Toast.Body>
        </Toast>
      </ToastContainer>
    </>
  );
}
