import Spinner from "react-bootstrap/Spinner";

export default function CustomProgress() {
  return (
    <>
      <h3>Chargement des données...&nbsp;&nbsp;&nbsp;&nbsp;</h3>
      <Spinner size="md" />
    </>
  );
}
