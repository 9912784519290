import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import useConst from "../../hooks/useConst";
import useApi from "../../hooks/useApi";
import DataTable from "../base-components/DataTableBase";
import { Button, Form, Row, Col } from "react-bootstrap";
import BaseSelect from "../base-components/BaseSelect";
import { toast } from "react-toastify";
import dayjs from "dayjs";
// Tippy
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
//import { GlobalContext } from "../../contexts/GlobalContext";

//==================//
// DEFAULT FUNCTION //
//==================//
export default function Dashboard() {
  //const { test } = useContext(GlobalContext);

  // useLocation
  //const { state } = useLocation();

  // useNavigate
  const navigate = useNavigate();

  // useConst
  const {
    userRole,
    etatDemande,
    getEtatDemande,
    getFlagDemande,
    typeFournisseur,
    typeFlag,
  } = useConst();

  // useApi
  const {
    apiGetUserRole,
    apiSearchDemandes,
    apiGetSites,
    apiGetMatieres,
    apiEditSessionUtilisateurSite,
    apiGetUserSite,
    apiGetFournisseurs,
  } = useApi();

  // useState
  const [getIsLoading, setIsLoading] = useState();
  const [getUserRole, setUserRole] = useState();
  const [getNbErrors, setNbErrors] = useState(0);
  const [getLastRefresh, setLastRefresh] = useState();
  const [getNbAutoRefresh, setNbAutoRefresh] = useState(0);

  // listes
  const [getSites, setSites] = useState();
  const [getMatieres, setMatieres] = useState();
  const [getTransporteurs, setTransporteurs] = useState();
  const [getStatuts, setStatuts] = useState();
  const [getDemandes, setDemandes] = useState();

  // filtres
  const [getSelectedSite, setSelectedSite] = useState();
  const [getSelectedMatiere, setSelectedMatiere] = useState();
  const [getSelectedTransporteur, setSelectedTransporteur] = useState();
  const [getSelectedStatut, setSelectedStatut] = useState();
  const [getSelectedDemandeInitiale, setSelectedDemandeInitiale] =
    useState(null);
  const [getNoDemande, setNoDemande] = useState("");
  const [getNoDemandeSearch, setNoDemandeSearch] = useState(null);

  // useEffect
  useEffect(() => {
    initPage();
    loadMatieres();
    loadTransporteurs();
    loadStatuts();

    const intervalId = setInterval(() => {
      setNbAutoRefresh((prev) => prev + 1);
      console.debug("timer raised at " + dayjs().toDate());
    }, 60000);
  }, []);

  useEffect(() => {
    //console.debug("auto-refresh at " + dayjs().toDate());
    //console.log(getNbAutoRefresh);
    refreshDemandes(false);
  }, [getNbAutoRefresh]);

  // useEffect loadDemandes
  useEffect(() => {
    if (getSelectedDemandeInitiale !== null) {
      // Note: ceci va appeller le useEffect du getSelectedDemandeInitiale et rafraichir le tableau
      setSelectedDemandeInitiale(null);
    } else {
      refreshDemandes(true);
    }
  }, [
    getSelectedSite,
    getSelectedMatiere,
    getSelectedTransporteur,
    getSelectedStatut,
    getNoDemandeSearch,
  ]);

  // useEffect load demandes historiques
  useEffect(() => {
    refreshDemandes(true);
  }, [getSelectedDemandeInitiale]);

  //===========//
  // FUNCTIONS //
  //===========//
  async function initPage() {
    const role = await loadUserRole();
    await loadSites(role);
  }

  async function loadUserRole() {
    try {
      setIsLoading(true);
      const role = await apiGetUserRole();
      setUserRole(role);
      return role;
    } catch (err) {
      console.error(err);
      toast.error("Le système ne peut vous identifier.");
    } finally {
      setIsLoading(false);
    }
  }

  async function loadSites(role) {
    let listSites = [];

    try {
      setIsLoading(true);
      const sites = await apiGetSites(null, false);

      listSites.push({
        label: "Tous les lieux de service",
        value: { UID: null },
      });

      if (sites != null) {
        sites.forEach((eachSite) => {
          listSites.push({ label: eachSite.Nom, value: eachSite });
        });
      }

      setSites(listSites);
      await setDefaultSiteForUser(listSites, role);
    } catch (err) {
      console.error(err);
      toast.error(
        "Une erreur est survenue lors du chargement des lieux de service."
      );
    } finally {
      setIsLoading(false);
    }
  }

  async function setDefaultSiteForUser(listSites, role) {
    if (
      role !== userRole.Fournisseur &&
      role !== userRole.Administrateur &&
      role !== userRole.Superviseur
    ) {
      const userSite = await apiGetUserSite();

      if (userSite && userSite !== "") {
        setSelectedSite(listSites.find((q) => q.value?.UID == userSite));
      } else {
        // On ne sélectionne rien
      }
    } else {
      setSelectedSite(listSites.find((q) => q.value?.UID == null));
    }
  }

  async function loadMatieres() {
    try {
      setIsLoading(true);
      const matieres = await apiGetMatieres(false);

      let listMatieres = [];
      const defaultMatiere = { label: "Toutes les matières", value: null };
      listMatieres.push(defaultMatiere);

      if (matieres != null) {
        matieres.forEach((eachMatiere) => {
          listMatieres.push({
            label: eachMatiere.Nom,
            value: eachMatiere.UID,
          });
        });
      }

      setMatieres(listMatieres);
      setSelectedMatiere(defaultMatiere);
    } catch (err) {
      console.error(err);
      toast.error("Une erreur est survenue lors du chargement des matières.");
    } finally {
      setIsLoading(false);
    }
  }

  async function loadTransporteurs() {
    try {
      setIsLoading(true);
      const transporteurs = await apiGetFournisseurs(
        typeFournisseur.Transporteur,
        false
      );

      let listTransporteur = [];
      const defaultTransporteur = {
        label: "Tous les transporteurs",
        value: null,
      };
      listTransporteur.push(defaultTransporteur);

      if (etatDemande != null) {
        transporteurs.forEach((eachTransporteur) => {
          listTransporteur.push({
            label: eachTransporteur.Nom,
            value: eachTransporteur.UID,
          });
        });
      }

      setTransporteurs(listTransporteur);
      setSelectedTransporteur(defaultTransporteur);
    } catch (err) {
      console.error(err);
      toast.error("Une erreur est survenue lors du chargement des statuts.");
    } finally {
      setIsLoading(false);
    }
  }

  async function loadStatuts() {
    try {
      setIsLoading(true);

      let listStatuts = [];
      const defaultStatut = { label: "Tous les statuts", value: -1 };
      listStatuts.push(defaultStatut);

      if (etatDemande != null) {
        //Object.keys(etatDemande).forEach((eachEtat) => {
        Object.values(etatDemande).forEach((eachEtat) => {
          // console.debug(eachEtat);
          // console.debug(etatDemande[eachEtat]);
          listStatuts.push({
            label: getEtatDemande(eachEtat),
            value: eachEtat,
          });
        });
      }

      setStatuts(listStatuts);
      setSelectedStatut(defaultStatut);
    } catch (err) {
      console.error(err);
      toast.error("Une erreur est survenue lors du chargement des statuts.");
    } finally {
      setIsLoading(false);
    }
  }

  async function refreshDemandes(showLoading) {
    //console.debug(getSelectedSite);
    if (getSelectedSite !== undefined) {
      //console.debug(getNbErrors);
      if (getNbErrors <= 10) {
        const searchFilters = {
          SiteUID: getSelectedSite?.value?.UID,
          MatiereUID: getSelectedMatiere?.value,
          TransporteurUID: getSelectedTransporteur?.value,
          StatutID: getSelectedStatut ? getSelectedStatut.value : -1,
          DemandeInitialeUID: getSelectedDemandeInitiale,
          NoDemande: getNoDemandeSearch,
        };

        //console.debug(searchFilters);

        try {
          if (showLoading) {
            setIsLoading(true);
          }

          const res = await apiSearchDemandes(searchFilters);
          setDemandes(res.data);

          //console.debug("refreshed at " + dayjs().toDate());
          setLastRefresh(
            "Dernière mise à jour : " + dayjs().format("YYYY-MM-DD - HH:mm")
          );

          setNbErrors(0);
        } catch (err) {
          console.error(err);
          setNbErrors(++getNbErrors);
          toast.error(
            "Une erreur est survenue lors du chargement des demandes."
          );
        } finally {
          setIsLoading(false);
        }
      } else {
        toast.error(
          "Un problème est survenu. Veuillez rafraîchir la page Web."
        );
      }
    }
  }

  function handleNouvelleDemandeClick() {
    const params = {
      action: "livraison",
      demande: null,
      site: getSelectedSite,
    };

    navigate("/nouvelle-demande", { state: { params } });
  }

  function handleBackFromHistoryClick() {
    setSelectedDemandeInitiale(null);
  }

  function searchByNoDemande(event) {
    if (event.keyCode === 13) {
      setNoDemandeSearch(getNoDemande);
      //console.log("enter");
    }
  }

  function resetSearchByNoDemande(event) {
    setNoDemande("");
    //setSelectedSite(getSites.find((q) => q.value?.UID == null));
    setNoDemandeSearch("");
  }

  //=========//
  // COLUMNS //
  //=========//
  const columns = [
    {
      name: "No. Demande",
      cell: (row) => (
        <Tippy
          content={
            row.NoDemandeEtTypeLivraisonEtCamion +
            " - " +
            row.ContratTransportFK?.Fournisseur?.Nom
          }
        >
          <Row>
            <Col>
              {/* {!getSelectedDemandeInitiale ? ( */}
              <Link
                to={`/demande-view?demande=${row.UID}`}
                state={row}
                style={
                  row.Flag || row.Etat === etatDemande.Annulee
                    ? { color: "#000075" }
                    : {}
                }
              >
                <span>{row.NoDemandeEtTypeLivraisonEtCamion}</span>
              </Link>
              {/* ) : (
                <span>{row.NoDemandeEtTypeLivraisonEtCamion}</span>
              )} */}
              <br />

              <span>{row.ContratTransportFK?.Fournisseur?.Nom}</span>
            </Col>
          </Row>
        </Tippy>
      ),
      sortable: true,
    },
    {
      name: "Lieu de service actuel",
      cell: (row) => (
        <Tippy content={row.SiteEtPlaceActuel}>
          <span>{row.SiteEtPlaceActuel}</span>
        </Tippy>
      ),
      sortable: true,
    },
    {
      name: "Lieu de service",
      grow: 1,
      cell: (row) => (
        <Tippy
          content={
            `Orig: ` +
            row.SiteEtPlaceOrigine +
            ` - Dest: ` +
            row.SiteEtPlaceDestination
          }
        >
          <Row>
            <Col>
              {row.SiteEtPlaceOrigine ? (
                <span>Orig: {row.SiteEtPlaceOrigine}</span>
              ) : (
                ""
              )}
              {row.SiteEtPlaceDestination ? (
                <>
                  <br />
                  <span>Dest: {row.SiteEtPlaceDestination}</span>
                </>
              ) : (
                ""
              )}
            </Col>
          </Row>
        </Tippy>
      ),
      sortable: true,
    },
    {
      name: "Matière",
      cell: (row) => (
        <Tippy
          content={
            (row.Etat === etatDemande.EnService
              ? row.Matiere?.Nom
              : row.MatiereActuelle?.Nom) +
            " (" +
            row.Volume?.Nom +
            ")"
          }
        >
          <Row>
            <Col>
              <span>
                {row.Etat === etatDemande.EnService
                  ? row.Matiere?.Nom
                  : row.MatiereActuelle?.Nom}
              </span>
              <br />
              <span>
                {row.Volume?.Nom}{" "}
                {row.Volume?.Nom == row.Volume?.Volume ? "verges" : ""}
              </span>
            </Col>
          </Row>
        </Tippy>
      ),
      sortable: true,
    },
    {
      name: "Heure",
      cell: (row) => (
        <Tippy
          content={
            row.HeurePlannifiee
              ? dayjs(row.HeurePlannifiee).format("YYYY-MM-DD à HH:mm")
              : row.HeurePlannifiee
          }
        >
          <Row>
            <Col>
              <span>
                {row.HeurePlannifiee
                  ? dayjs(row.HeurePlannifiee).format("YYYY-MM-DD")
                  : row.HeurePlannifiee}
              </span>
              <br />
              <span>
                {row.HeurePlannifiee
                  ? dayjs(row.HeurePlannifiee).format("HH:mm")
                  : row.HeurePlannifiee}
              </span>
            </Col>
          </Row>
        </Tippy>
      ),
      sortable: true,
    },
    {
      name: "État",
      cell: (row) => (
        <Tippy
          content={
            getEtatDemande(row.Etat) +
            (row.Flag ? " - " + getFlagDemande(row.Flag) : "")
          }
        >
          <Row>
            <Col>
              {!getSelectedDemandeInitiale && (
                <Link
                  onClick={() =>
                    setSelectedDemandeInitiale(row.DemandeInitialle_UID)
                  }
                  //to={`/?origine=${row.UID}`}
                  //state={row}
                  style={
                    row.Flag || row.Etat === etatDemande.Annulee
                      ? { color: "#000075" }
                      : {}
                  }
                >
                  <span>{getEtatDemande(row.Etat)}</span>
                </Link>
              )}
              {getSelectedDemandeInitiale && (
                <span>{getEtatDemande(row.Etat)}</span>
              )}
              {row.Flag && <br />}
              {row.Flag && getFlagDemande(row.Flag)}
            </Col>
          </Row>
        </Tippy>
      ),
      sortable: true,
    },
  ];

  // Row styles
  const conditionalRowStyles = [
    {
      when: (row) =>
        row.Flag === typeFlag.EnRetardConfirmation ||
        row.Flag === typeFlag.EnRetardExecution,
      style: {
        backgroundColor: "#d66c65",
        link: "white",
        color: "white",
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
    {
      when: (row) => row.Etat === etatDemande.Annulee,
      style: {
        backgroundColor: "#db7f79",
        link: "white",
        color: "white",
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
    {
      when: (row) =>
        (row.Etat === etatDemande.Planifiee &&
          Date.now() >
            new Date(row.HeurePlannifiee).setMinutes(
              new Date(row.HeurePlannifiee).getMinutes() + 5
            )) ||
        row.Etat === etatDemande.EnAttenteApprobation,
      style: {
        backgroundColor: "#ebe546",
        link: "black",
        color: "black",
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
  ];

  //===========//
  // COMPONENT //
  //===========//
  return (
    <>
      <h1 align="center">Tableau de bord</h1>

      {/***********************
       * Filtres de recherche *
       ************************/}
      <Row>
        {/*******
         * Site *
         ********/}
        <Col>
          <Form.Group>
            <Form.Label style={{ color: "black" }}>Lieu de service</Form.Label>
            <BaseSelect
              isLoading={getIsLoading}
              options={getSites}
              value={getSelectedSite}
              onChange={(e) => {
                resetSearchByNoDemande();
                setSelectedSite(e);
                apiEditSessionUtilisateurSite(e.value?.UID);
              }}
            />
          </Form.Group>
        </Col>

        {/**********
         * Matière *
         ***********/}
        <Col>
          <Form.Group>
            <Form.Label style={{ color: "black" }}>Matière</Form.Label>
            <BaseSelect
              isLoading={getIsLoading}
              options={getMatieres}
              value={getSelectedMatiere}
              onChange={(e) => {
                resetSearchByNoDemande();
                setSelectedMatiere(e);
              }}
            />
          </Form.Group>
        </Col>

        {/************************
         * Fournisseur Transport *
         *************************/}
        {(getUserRole === userRole.Administrateur ||
          getUserRole === userRole.Superviseur ||
          getUserRole === userRole.Prepose ||
          getUserRole === userRole.Demandeur) && (
          <Col>
            <Form.Group>
              <Form.Label style={{ color: "black" }}>
                Fournisseur Transport
              </Form.Label>
              <BaseSelect
                isLoading={getIsLoading}
                options={getTransporteurs}
                value={getSelectedTransporteur}
                onChange={(e) => {
                  resetSearchByNoDemande();
                  setSelectedTransporteur(e);
                }}
              />
            </Form.Group>
          </Col>
        )}

        {/*********
         * Statut *
         **********/}
        <Col>
          <Form.Group>
            <Form.Label style={{ color: "black" }}>Statut</Form.Label>
            <BaseSelect
              isLoading={getIsLoading}
              options={getStatuts}
              value={getSelectedStatut}
              onChange={(e) => {
                resetSearchByNoDemande();
                setSelectedStatut(e);
              }}
            />
          </Form.Group>
        </Col>

        {/**************
         * No. Demande *
         ***************/}
        <Col>
          <Form.Group>
            <Form.Label style={{ color: "black" }}>No. Demande</Form.Label>
            <Form.Control
              type="text"
              value={getNoDemande}
              onChange={(e) => setNoDemande(e.target.value)}
              onKeyDown={(e) => searchByNoDemande(e)}
            />
          </Form.Group>
        </Col>
      </Row>

      {/*************************
       * Bouton Nouvelle demande *
       ***************************/}
      {(getUserRole === userRole.Administrateur ||
        getUserRole === userRole.Superviseur ||
        getUserRole === userRole.Prepose ||
        getUserRole === userRole.Demandeur) && (
        <Row className="mt-4">
          <Col align="right">
            <Button
              variant="primary"
              size="sm"
              onClick={handleNouvelleDemandeClick}
            >
              Nouvelle demande
            </Button>
          </Col>
        </Row>
      )}

      {getSelectedDemandeInitiale && (
        <Row>
          <Col>
            <h2>
              <Button
                variant="primary"
                size="sm"
                onClick={handleBackFromHistoryClick}
              >
                {`<=`}
              </Button>
              &nbsp;Historique d'une demande
            </h2>
          </Col>
        </Row>
      )}

      {/**********************
       * Tableau de demandes *
       ***********************/}
      <Row className={!getSelectedDemandeInitiale && "mt-4"}>
        <Col>
          <DataTable
            title=""
            dense={true}
            columns={columns}
            data={getDemandes}
            progressPending={getIsLoading}
            conditionalRowStyles={conditionalRowStyles}
          />
        </Col>
      </Row>
      <Row>
        <Col style={{ textAlign: "right" }}>
          <p>{getLastRefresh}</p>
        </Col>
      </Row>
    </>
  );
}
