import React, { useState, useEffect } from "react";
import useApi from "../../hooks/useApi";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import Alert from "react-bootstrap/Alert";
import { toast } from "react-toastify";

/********************
 * DEFAULT FUNCTION *
 ********************/
export default function EditMatiere({ isEdit, matiere, handleRefreshData }) {
  // useApi
  const { apiAddMatiere, apiEditMatiere } = useApi();

  // useState
  const [errorMsg, setErrorMsg] = useState();
  const [show, setShow] = useState(false);
  const [showStateSave, setShowStateSave] = useState(true);
  const [showStateLoading, setShowStateLoading] = useState(false);
  const [getInitialItemState] = useState(Object.assign({}, matiere));

  // Functions
  const handleShow = () => setShow(true);

  const handleClose = () => {
    setErrorMsg("");
    matiere.ID = getInitialItemState.ID;
    matiere.Nom = getInitialItemState.Nom;
    matiere.Actif = Number(getInitialItemState.Actif);
    matiere.Systeme = getInitialItemState.IsSystem;
    setShow(false);
  };

  const handleSubmit = async () => {
    try {
      setShowStateSave(false);
      setShowStateLoading(true);

      if (isEdit) {
        await apiEditMatiere(matiere);
      } else {
        await apiAddMatiere(matiere);
      }

      toast.success("Sauvegardé");
      setErrorMsg("");
      setShow(false);

      await handleRefreshData();
    } catch (err) {
      console.error(err);
      setErrorMsg(err?.response?.data);
    } finally {
      setShowStateLoading(false);
      setShowStateSave(true);
    }
  };

  /*************
   * COMPONENT *
   *************/
  return (
    <>
      {/*********
       * BUTTON *
       **********/}
      <div align="right">
        <Button
          variant="primary"
          size={isEdit ? "sm" : ""}
          onClick={handleShow}
        >
          {isEdit ? "Modifier" : "Ajouter"}
        </Button>
      </div>

      {/********
       * MODAL *
       *********/}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Matière</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>ID *</Form.Label>
              <Form.Control
                name="ID"
                defaultValue={matiere.ID}
                placeholder=""
                autoFocus
                onChange={(e) => (matiere.ID = e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Nom de la matière *</Form.Label>
              <Form.Control
                name="Nom"
                defaultValue={matiere.Nom}
                onChange={(e) => (matiere.Nom = e.target.value)}
              />
            </Form.Group>

            {/* ACTIF */}
            <Form.Group className="mb-3">
              <Form.Label>Actif *</Form.Label>
              <Form.Select
                as="select"
                name="Actif"
                defaultValue={matiere?.Actif ?? -1}
                onChange={(e) => (matiere.Actif = Number(e.target.value))}
              >
                <option value="-1" disabled>
                  Veuillez sélectionner une option
                </option>
                <option value="1">Actif</option>
                <option value="0">Non-actif</option>
              </Form.Select>
            </Form.Group>

            {/* ALERT ERROR MESSAGE */}
            {errorMsg && (
              <Alert className="mt-4" variant="danger">
                {errorMsg}
              </Alert>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Retour
          </Button>
          {showStateSave && (
            <Button variant="primary" onClick={handleSubmit}>
              Sauvegarder
            </Button>
          )}
          {showStateLoading && (
            <Button variant="primary" disabled>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              &nbsp; Chargement...
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}
