import styles from "./ContratsTransporteurs.module.scss";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import dayjs from "dayjs";
import DataTable from "../base-components/DataTableBase";
import EditContratTransporteur from "./EditContratTransporteur";
import ContratTransporteurPrix from "./ContratTransporteurPrix";
import useApi from "../../hooks/useApi";

export default function ContratsTransporteurs() {
  const { apiGetContratsTransport } = useApi();

  const [dataState, setDataState] = useState();
  const [getIsDataLoading, setIsDataLoading] = useState(true);

  useEffect(() => {
    loadContrats();
  }, []);

  const loadContrats = () => {
    apiGetContratsTransport(false, true)
      .then((res) => {
        setDataState(res);
        setIsDataLoading(false);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleSaveItem = async (item, isEdit) => {
    loadContrats();
  };

  const columns = [
    {
      name: "No. Contrat",
      selector: (row) => row.NumContrat,
      sortable: true,
    },
    {
      name: "Fournisseur",
      selector: (row) => row?.Fournisseur?.Nom,
      sortable: true,
    },
    {
      name: "Matière",
      selector: (row) => row.Matiere?.Nom,
      sortable: true,
    },
    {
      name: "Date Début",
      selector: (row) =>
        row.DateDebut
          ? dayjs(row.DateDebut).format("YYYY-MM-DD")
          : row.DateDebut,
      sortable: false,
    },
    {
      name: "Date Fin",
      selector: (row) =>
        row.DateFin ? dayjs(row.DateFin).format("YYYY-MM-DD") : row.DateFin,
      sortable: false,
    },
    {
      name: "Actif",
      selector: (row) => (row.Actif === 1 ? "Actif" : "Non-actif"),
    },
    {
      button: true,
      cell: (record) => (
        <Link to={"/admin/contrat-transporteur-prix"} state={record}>
          Prix
        </Link>
      ),
    },
    {
      button: true,
      cell: (record) => (
        <EditContratTransporteur
          isEdit={true}
          item={Object.assign({}, record)}
          handleSaveItemParent={handleSaveItem}
        />
      ),
    },
  ];

  return (
    <div className={styles.Matieres}>
      <h1 align="center">Contrats de transporteurs</h1>

      <div align="right">
        <EditContratTransporteur
          isEdit={false}
          item={{}}
          handleSaveItemParent={handleSaveItem}
        />
      </div>

      <br />
      <div>
        <DataTable
          title=""
          columns={columns}
          data={dataState}
          progressPending={getIsDataLoading}
        />
      </div>
    </div>
  );
}
