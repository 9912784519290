import DataTable from "react-data-table-component";
import CustomProgress from "./CustomProgress";

export default function DataTableBase(props) {
  return (
    <DataTable
      noDataComponent="Il n'y a pas de données à afficher"
      progressComponent={<CustomProgress />}
      dense
      {...props}
    />
  );
}
